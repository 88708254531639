<div class="section section-buttons section-gray">
    <div class="container">
        <div class="tim-title">
            <h2>Basic Components</h2>
        </div>
        <div id="buttons">
            <div class="title">
                <h3>Buttons <br/><small>Pick your style</small>
                </h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-info">Default</button>
                    <button type="button" class="btn btn-info btn-round">Round</button>
                    <button type="button" class="btn btn-info btn-round"><i class="fa fa-heart"></i> With Icon</button>
                    <button type="button" class="btn btn-info btn-just-icon"><i class="fa fa-heart"></i></button>
                    <button type="button" class="btn btn-info btn-link">Simple</button>
                </div>
            </div>
            <div class="title">
                <h3><small>Pick your size</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-outline-danger btn-sm">Small</button>
                    <button type="button" class="btn btn-outline-danger">Regular</button>
                    <button type="button" class="btn btn-outline-danger btn-lg">Large</button>
                </div>
            </div>
            <div class="title">
                <h3><small>Pick your color</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-outline-default btn-round">Default</button>
                    <button type="button" class="btn btn-outline-primary btn-round">Primary</button>
                    <button type="button" class="btn btn-outline-info btn-round">Info</button>
                    <button type="button" class="btn btn-outline-success btn-round">Success</button>
                    <button type="button" class="btn btn-outline-warning btn-round">Warning</button>
                    <button type="button" class="btn btn-outline-danger btn-round">Danger</button>
                    <button type="button" class="btn btn-outline-neutral btn-round">Neutral</button>
                </div>
            </div>
            <br/>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-default btn-round">Default</button>
                    <button type="button" class="btn btn-primary btn-round">Primary</button>
                    <button type="button" class="btn btn-info btn-round">Info</button>
                    <button type="button" class="btn btn-success btn-round">Success</button>
                    <button type="button" class="btn btn-warning btn-round">Warning</button>
                    <button type="button" class="btn btn-danger btn-round">Danger</button>
                    <button type="button" class="btn btn-neutral btn-round">Neutral</button>
                </div>
            </div>
        </div>
        <br/>
        <div class="title">
            <h3>Links</h3>
        </div>
        <div class="row">
            <div class="col-md-8">
                <a href="#0" class="btn btn-link btn-default">Default</a>
                <a href="#0" class="btn btn-link btn-primary">Primary</a>
                <a href="#0" class="btn btn-link btn-success">Success</a>
                <a href="#0" class="btn btn-link btn-info">Info</a>
                <a href="#0" class="btn btn-link btn-warning">Warning</a>
                <a href="#0" class="btn btn-link btn-danger">Danger</a>
                <a href="#0" class="btn btn-link btn-neutral">Neutral</a>
            </div>
        </div>
        <div id="icon-buttons">
            <div class="title">
                <h3>Buttons with icons</h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button type="button" class="btn btn-default btn-just-icon"><i class="fa fa-twitter" aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-info btn-outline-default btn-just-icon"><i class="fa fa-twitter" aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-default btn-link btn-just-icon"><i class="fa fa-twitter" aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-round"><i class="nc-icon nc-settings-gear-65"></i> Settings</button>
                    <button type="button" class="btn btn-outline-default btn-border btn-round"><i class="nc-icon nc-settings-gear-65"></i> Settings</button>
                    <button type="button" class="btn btn-link btn-round"><i class="nc-icon nc-settings-gear-65"></i> Settings</button>
                    <br /><br />
                    <button type="button" class="btn btn-warning btn-just-icon btn-sm"><i class="nc-icon nc-sound-wave"></i></button>
                    <button type="button" class="btn btn-danger btn-just-icon"><i class="nc-icon nc-simple-remove" aria-hidden="true"></i></button>
                    <button type="button" class="btn btn-default btn-just-icon btn-lg"><i class="nc-icon nc-chat-33"></i></button>
                </div>
            </div>
        </div>
        <br/>
        <div id="icon-buttons">
            <div class="title">
                <h3>Social buttons</h3>
                <h3><small>Default</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button class="btn btn-just-icon btn-twitter">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-facebook">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-google">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-linkedin">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-pinterest">
                        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-youtube">
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-tumblr">
                        <i class="fa fa-tumblr" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-github">
                        <i class="fa fa-github-alt" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-dribbble">
                        <i class="fa fa-dribbble" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-reddit">
                        <i class="fa fa-reddit-alien" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-instagram">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="title">
                <h3><small>Bordered</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button class="btn btn-just-icon btn-border btn-twitter">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-facebook">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-google">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-linkedin">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-pinterest">
                        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-youtube">
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-tumblr">
                        <i class="fa fa-tumblr" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-github">
                        <i class="fa fa-github-alt" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-dribbble">
                        <i class="fa fa-dribbble" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-reddit">
                        <i class="fa fa-reddit-alien" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-border btn-instagram">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="title">
                <h3><small>Links</small></h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button class="btn btn-just-icon btn-link btn-twitter">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-facebook">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-google">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-linkedin">
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-pinterest">
                        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-youtube">
                        <i class="fa fa-youtube" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-tumblr">
                        <i class="fa fa-tumblr" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-github">
                        <i class="fa fa-github-alt" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-dribbble">
                        <i class="fa fa-dribbble" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-reddit">
                        <i class="fa fa-reddit-alien" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-just-icon btn-link btn-instagram">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
        <div id="animated-buttons">
            <div class="title">
                <h3>Animated Buttons</h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <button class="btn btn-rotate btn-round"><i class="nc-icon nc-settings-gear-65"></i> Settings</button>
                    <button class="btn btn-magnify btn-round"><i class="nc-icon nc-zoom-split"></i> Search</button>
                    <button class="btn btn-move-left btn-round"><i class="nc-icon nc-minimal-left"></i> Back</button>
                    <button class="btn btn-move-right btn-round">Next <i class="nc-icon nc-minimal-right"></i> </button>
                </div>
            </div>
        </div>
        <br/>
        <div id="inputs">
            <div class="title">
                <h3>Inputs</h3>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <input type="text" value="" placeholder="Simple" class="form-control" />
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <input type="text" value="" placeholder="No Border" class="form-control no-border" />
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <input type="text" value="" placeholder="Disabled" class="form-control" disabled />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="input-group">
                    <!-- 	   Since the css properties cannot check the previous sibling of an element and for the design consistency we recommend to use the "input-group-addon" after the "form-control" like in this example -->
                      <input type="text" placeholder="Group Addon" class="form-control">
                      <span class="input-group-addon"><i class="fa fa-group"></i></span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="input-group no-border" >
                      <input type="text" placeholder="No Border" class="form-control no-border">
                      <span class="input-group-addon no-border">%</span>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="input-group" disabled>
                      <input type="text" placeholder="Group Disabled" class="form-control" disabled>
                      <span class="input-group-addon"><i class="fa fa-group"></i></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                  <div class="form-group has-success">
                    <input type="text" value="Success" id="inputSuccess1" class="form-control form-control-success" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group has-success">
                    <input type="text" value="Success" id="inputSuccess2" class="form-control form-control-success no-border" />
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group has-danger">
                        <input type="text" class="form-control form-control-danger" id="inputDanger1" value="Error">
                        <div class="form-control-feedback">Sorry, that username's taken. Try another?</div>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group has-danger">
                        <input type="text" class="form-control form-control-danger no-border" id="inputDanger2" value="Error">
                        <div class="form-control-feedback">Sorry, that username's taken. Try another?</div>
                    </div>
                </div>
            </div> <!-- end row -->
        </div>
        <br/>
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="title">
                    <h3>Checkboxes</h3>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="">
                        Unchecked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" checked>
                        Checked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" disabled>
                        Disabled unchecked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="checkbox" value="" disabled checked>
                        Disabled checked
                        <span class="form-check-sign"></span>
                    </label>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="title">
                    <h3>Radio Buttons</h3>
                </div>

                <div class="form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" >
                        Radio is off
                        <span class="form-check-sign"></span>
                    </label>
                </div>
                <div class="form-check-radio">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" checked>
                        Radio is on
                        <span class="form-check-sign"></span>
                    </label>
                </div>

                <div class="form-check-radio disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" disabled>
                        Disabled radio is off
                        <span class="form-check-sign"></span>
                    </label>
                </div>

                <div class="form-check-radio disabled">
                    <label class="form-check-label">
                        <input class="form-check-input" type="radio" name="exampleRadioz" id="exampleRadios4" value="option4" checked disabled>
                        Disabled radio is on
                        <span class="form-check-sign"></span>
                    </label>
                </div>
            </div>
        </div>
        <div id="textarea">
            <div class="title">
                <h3>Textarea</h3>
            </div>
            <div class="row">
                <div class="col-md-5 col-sm-6">
                    <textarea class="form-control border-input" placeholder="This is a textarea with border. Here can be your nice text" rows="3"></textarea>
                </div>
                <div class="col-md-5 col-sm-6">
                    <textarea class="form-control textarea-limited" placeholder="This is a textarea limited to 150 characters." rows="3" maxlength="150" ></textarea>
                    <h5><small><span id="textarea-limited-message" class="pull-right">150 characters left</span></small></h5>
                </div>
            </div>
        </div>
        <div id="switches">
            <div class="title">
                <h3>Switches</h3>
            </div>
            <bSwitch
                [switch-on-color]="'primary'"
                [switch-off-color]="'primary'"
                [(ngModel)]="state_primary">
            </bSwitch>
            <bSwitch
                [switch-on-color]="'primary'"
                [switch-off-color]="'primary'"> Toggle is off
            </bSwitch>
            <bSwitch
                [switch-on-color]="'success'"
                [switch-off-color]="'success'"
                [(ngModel)]="state_succes">
            </bSwitch>
            <bSwitch
                [switch-on-color]="'success'"
                [switch-off-color]="'success'"> Toggle is off
            </bSwitch>
            <bSwitch
                [switch-on-color]="'warning'"
                [switch-off-color]="'warning'"
                [(ngModel)]="state_warning">
            </bSwitch>
            <bSwitch
                [switch-on-color]="'warning'"
                [switch-off-color]="'warning'"> Toggle is off
            </bSwitch>
            <div class="title">
                <h3>Switches with icons</h3>
            </div>
            <bSwitch
                [switch-on-color]="'primary'"
                [switch-off-color]="'primary'"
                [(ngModel)]="state_icon_primary"
                switch-on-text="<i class='nc-icon nc-check-2'></i>"
                switch-off-text="<i class='nc-icon nc-simple-remove'></i>">
            </bSwitch>
            <bSwitch
                [switch-on-color]="'primary'"
                [switch-off-color]="'primary'"
                switch-on-text="<i class='nc-icon nc-check-2'></i>"
                switch-off-text="<i class='nc-icon nc-simple-remove'></i>"> Toggle is off
            </bSwitch>
            <bSwitch
                [switch-on-color]="'success'"
                [switch-off-color]="'success'"
                [(ngModel)]="state_icon_succes"
                switch-on-text="<i class='nc-icon nc-check-2'></i>"
                switch-off-text="<i class='nc-icon nc-simple-remove'></i>">
            </bSwitch>
            <bSwitch
                [switch-on-color]="'success'"
                [switch-off-color]="'success'"
                switch-on-text="<i class='nc-icon nc-check-2'></i>"
                switch-off-text="<i class='nc-icon nc-simple-remove'></i>"> Toggle is off
            </bSwitch>
            <bSwitch
                [switch-on-color]="'warning'"
                [switch-off-color]="'warning'"
                [(ngModel)]="state_icon_warning"
                switch-on-text="<i class='nc-icon nc-check-2'></i>"
                switch-off-text="<i class='nc-icon nc-simple-remove'></i>">
            </bSwitch>
            <bSwitch
                [switch-on-color]="'warning'"
                [switch-off-color]="'warning'"
                switch-on-text="<i class='nc-icon nc-check-2'></i>"
                switch-off-text="<i class='nc-icon nc-simple-remove'></i>"> Toggle is off
            </bSwitch>
        </div>

        <div id="labels">
            <div class="title">
                <h3>Labels</h3>
                <h3><small>Simple</small></h3>
            </div>
            <span class="label label-default">Default</span>
            <span class="label label-primary">Primary</span>
            <span class="label label-info">Info</span>
            <span class="label label-success">Success</span>
            <span class="label label-warning">Warning</span>
            <span class="label label-danger">Danger</span>
            <br/>
            <div class="title">
                <h3><small>Icons</small></h3>
            </div>
            <span class="label label-default"><i class="fa fa-unlock-alt" aria-hidden="true"></i></span>
            <span class="label label-primary"><i class="fa fa-id-card-o" aria-hidden="true"></i></span>
            <span class="label label-info"><i class="fa fa-info" aria-hidden="true"></i></span>
            <span class="label label-success"><i class="fa fa-comments" aria-hidden="true"></i></span>
            <span class="label label-warning"><i class="fa fa-cog" aria-hidden="true"></i></span>
            <span class="label label-danger"><i class="fa fa-battery-half" aria-hidden="true"></i></span>
        </div>
        <div id="tags">
            <div class="title">
                <h3>Tags</h3>
            </div>
            <tag-input [(ngModel)]='tagItems' theme='regular-theme'></tag-input>
        </div>
        <div id="select">
            <div class="title">
                <h3>Select</h3>
            </div>
            <div class="row">
               <div class="col-md-3 col-sm-3">
                    <div class="form-group">
                        <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                            [settings]="dropdownSettings"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
                        </angular2-multiselect>
                    </div>
                </div>
                <div class="col-md-3 col-sm-3">
                    <div class="form-group">
                        <angular2-multiselect [data]="dropdownList1" [(ngModel)]="selectedItems1"
                            [settings]="dropdownSettings1"
                            (onSelect)="onItemSelect($event)"
                            (onDeSelect)="OnItemDeSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            (onDeSelectAll)="onDeSelectAll($event)">
                        </angular2-multiselect>
                    </div>
                </div>
            </div>
            <div id="preloaders">
                <div class="title">
                    <h3>Preloaders</h3>
                </div>
                <div class='uil-reload-css' style=''><div></div></div>
                <div class='uil-reload-css reload-small' style=''><div></div></div>

                <div class='uil-reload-css reload-background' style=''><div></div></div>
                <div class='uil-reload-css reload-background reload-small' style=''><div></div></div>

                <a  class="btn btn-round"><div class='uil-reload-css reload-small' style=''><div></div></div> Something</a>
            </div>
            <div id="menu-dropdown">
                <div class="title">
                    <h3>Dropdown</h3>
                    <br />
                    <div id="navbar-dropdown">
                        <nav class="navbar navbar-expand-md navbar-default">
                            <div class="container">
                                <a class="navbar-brand" >Dropdown colors</a>
                                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbar-menu">
                                    <ul class="navbar-nav ml-auto">
                                        <!-- default color -->
                                        <li class="nav-item">
                                            <div ngbDropdown class="dropdown">
                                                <div class="dropdown-toggle btn btn-round" ngbDropdownToggle>Default<b class="caret"></b></div>
                                                <div ngbDropdownMenu class="dropdown-menu-right">
                                                    <div class="dropdown-header">Dropdown header</div>
                                                    <a class="dropdown-item" >Action</a>
                                                    <a class="dropdown-item" >Another action</a>
                                                    <a class="dropdown-item" >Something else here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Separated link</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Another separated link</a>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- primary color -->
                                        <li class="nav-item">
                                            <div ngbDropdown class="dropdown">
                                                <div class="dropdown-toggle btn btn-round btn-primary" ngbDropdownToggle>Primary<b class="caret"></b></div>
                                                <div ngbDropdownMenu class="dropdown-primary dropdown-menu-right">
                                                    <div class="dropdown-header">Dropdown header</div>
                                                    <a class="dropdown-item" >Action</a>
                                                    <a class="dropdown-item" >Another action</a>
                                                    <a class="dropdown-item" >Something else here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Separated link</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Another separated link</a>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- info color -->
                                        <li class="nav-item">
                                            <div ngbDropdown class="dropdown">
                                                <div class="dropdown-toggle btn btn-round btn-info" ngbDropdownToggle>Info<b class="caret"></b></div>
                                                <div ngbDropdownMenu class="dropdown-info dropdown-menu-right">
                                                    <div class="dropdown-header">Dropdown header</div>
                                                    <a class="dropdown-item" >Action</a>
                                                    <a class="dropdown-item" >Another action</a>
                                                    <a class="dropdown-item" >Something else here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Separated link</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Another separated link</a>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- success color -->
                                        <li class="nav-item">
                                            <div ngbDropdown class="dropdown">
                                                <div class="dropdown-toggle btn btn-round btn-success" ngbDropdownToggle>Success<b class="caret"></b></div>
                                                <div ngbDropdownMenu class="dropdown-success dropdown-menu-right">
                                                    <div class="dropdown-header">Dropdown header</div>
                                                    <a class="dropdown-item" >Action</a>
                                                    <a class="dropdown-item" >Another action</a>
                                                    <a class="dropdown-item" >Something else here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Separated link</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Another separated link</a>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- warning color -->
                                        <li class="nav-item">
                                            <div ngbDropdown class="dropdown">
                                                <div class="dropdown-toggle btn btn-round btn-warning" ngbDropdownToggle>Warning<b class="caret"></b></div>
                                                <div ngbDropdownMenu class="dropdown-warning dropdown-menu-right">
                                                    <div class="dropdown-header">Dropdown header</div>
                                                    <a class="dropdown-item" >Action</a>
                                                    <a class="dropdown-item" >Another action</a>
                                                    <a class="dropdown-item" >Something else here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Separated link</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Another separated link</a>
                                                </div>
                                            </div>
                                        </li>
                                        <!-- danger color -->
                                        <li class="nav-item">
                                            <div ngbDropdown class="dropdown">
                                                <div class="dropdown-toggle btn btn-round btn-danger" ngbDropdownToggle>Danger<b class="caret"></b></div>
                                                <div ngbDropdownMenu class="dropdown-danger dropdown-menu-right">
                                                    <div class="dropdown-header">Dropdown header</div>
                                                    <a class="dropdown-item" >Action</a>
                                                    <a class="dropdown-item" >Another action</a>
                                                    <a class="dropdown-item" >Something else here</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Separated link</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" >Another separated link</a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
