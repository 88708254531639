<div class="team-2 section-image" style="background-image: url('assets/img/staticImages/percorsi_bg-color.jpg')">
    <div class="container">
        <div class="row">
            <div class="col-md-10 ml-auto mr-auto text-center mt-5"><h2 class="title">I PERCORSI</h2><h5
                    class="description"><span style="font-weight: 700;">La grande storia di Iren è l’insieme di tante storie particolari.</span>
                <br>
                Attraverso le <em>storymap</em> - un nuovo modo per navigare tra i documenti attraverso uno <em>storytelling</em>
                digitale – scopri le storie che hanno reso grande Iren e portato servizi ai territori.</h5></div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/lago-agnel']"><img class="img"
                                                                                              src="assets/img/staticImages/percorsi-cover-1.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">Un’energia più giusta</h4><h6 class="card-category">
                        La costruzione della diga al lago Agnel e l’energia a basso costo per tutti</h6>
                        <div class="card-footer pt-2">
                            <a class="btn btn-outline-neutral btn-round"
                               [routerLink]="['/percorsi/lago-agnel']">VAI ALLA STORYMAP</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/avventura-del-metano']"><img class="img"
                                                                                                        src="assets/img/staticImages/percorsi-cover-2.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">L’avventura del metano</h4><h6
                            class="card-category">La campagna di “metanizzazione” di Reggio Emilia</h6>
                        <div class="card-footer">
                            <div class="card-footer pt-2">
                                <a class="btn btn-outline-neutral btn-round"
                                   [routerLink]="['/percorsi/avventura-del-metano']">VAI ALLA STORYMAP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/sopravvivere-alla-guerra']"><img class="img"
                                                                                                            src="assets/img/staticImages/percorsi-cover-3.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">Sopravvivere alla guerra</h4><h6
                            class="card-category">
                        Il bombardamento e la rinascita dell’AMGA</h6>
                        <div class="card-footer">
                            <div class="card-footer pt-2">
                                <a class="btn btn-outline-neutral btn-round"
                                   [routerLink]="['/percorsi/sopravvivere-alla-guerra']">VAI ALLA STORYMAP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/nascita-di-un-acquedotto']">
                        <img class="img" src="assets/img/staticImages/percorsi-cover-4.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">La nascita di un acquedotto</h4><h6
                            class="card-category">
                        L’impresa Garrè e l'acqua pubblica a Piacenza</h6>
                        <div class="card-footer">
                            <div class="card-footer pt-2">
                                <a class="btn btn-outline-neutral btn-round"
                                   [routerLink]="['/percorsi/nascita-di-un-acquedotto']">VAI ALLA STORYMAP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/e-luce-fu']">
                        <img class="img" src="assets/img/staticImages/percorsi-cover-5.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">E luce fu</h4><h6
                            class="card-category">
                        Gas e illuminazione a Genova dalle origini all’AMGA</h6>
                        <div class="card-footer">
                            <div class="card-footer pt-2">
                                <a class="btn btn-outline-neutral btn-round"
                                   [routerLink]="['/percorsi/e-luce-fu']">VAI ALLA STORYMAP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/storie-dei-presidenti']">
                        <img class="img" src="assets/img/staticImages/percorsi-cover-6.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">Cronologia dei presidenti</h4><h6
                            class="card-category">
                        I presidenti dal 1907 ad oggi <br>&nbsp;</h6>
                        <div class="card-footer">
                            <div class="card-footer pt-2">
                                <a class="btn btn-outline-neutral btn-round"
                                   [routerLink]="['/percorsi/storie-dei-presidenti']">VAI ALLA STORYMAP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card card-profile card-plain">
                    <div class="card-img-top"><a [routerLink]="['/percorsi/storia-di-una-vertenza']">
                        <img class="img" src="assets/img/staticImages/percorsi-cover-7.jpg"></a>
                    </div>
                    <div class="card-body"><h4 class="card-title">Storia di una vertenza</h4><h6
                            class="card-category">
                        La battaglia sindacale per unificare i servizi <br>&nbsp;</h6>
                        <div class="card-footer">
                            <div class="card-footer pt-2">
                                <a class="btn btn-outline-neutral btn-round"
                                   [routerLink]="['/percorsi/storia-di-una-vertenza']">VAI ALLA STORYMAP</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>