<div id="headers" class="header-4 cd-section">
    <div class="header-wrapper">

        <div class="page-header header-video">
            <div class="filter"></div>
            <!-- We show the video image placeholder instead of the video for small devices  -->
            <div class="video-image visible-xs visible-sm"
                 style="background-image: url('assets/img/storymaps/agnel-copertina-video.jpg')"></div>
            <div class="video-container">
                <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
                    <source src="assets/video/agnel.mp4" type="video/mp4">
                    Video not supported
                </video>
            </div>
            <div class="content-center">
                <div class="container upper-container text-center">
                    <div class="video-text">
                        <h2>Storymap</h2>
                        <h1 class="title-uppercase title-no-upper-margin">La diga del Lago Agnel</h1>
                        <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                                class="btn btn-outline-neutral btn-round mt-5">
                            <i class="fa fa-chevron-down"></i> Inizia il percorso
                        </button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="cd-section section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url('assets/img/storymaps/agnel-1-1.jpg');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3>CAPITOLO 1</h3>
                            <h2 class="title">L'idea di costruire</h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>
                                    <strong>All’inizio è un’opportunità: le derivazioni dal torrente Orco allo
                                        studio, gli assaggi per la costruzione dell’impianto di Bardonetto. Perché non
                                        approfittarne per studiare una «piccola diga a gravità» che d’inverno aiuti la
                                        produzione della «derivazione tra Cereale e Rosone»? </strong></p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 23 ottobre 1935 -</em>
                                    </small>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Con un costo contenuto, <strong>la possibilità di offrire «un apprezzabile
                                    quantitativo di
                                    energia invernale</strong> il cui prezzo di costo risulterebbe assai basso»
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 23 ottobre 1935</em>
                                    </small>
                                </p>
                                <p class="mt-4">Al progetto, serve accompagnare una «dettagliata relazione geologica». Il «professor
                                    Grande Ufficiale <strong>Federico Sacco del Politecnico</strong>»<strong> sembra a
                                        tutti la persona giusta</strong> per produrla
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 23 ottobre 1935</em>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14216?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14216?.AssignedTitle}}</h5>
                                    <p>{{s14216?.Content}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14214?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14214?.AssignedTitle}}</h5>
                                    <p>{{s14214?.Content}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14222?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14222?.AssignedTitle}}</h5>
                                    <p>{{s14222?.Content}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14247?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14247?.AssignedTitle}}</h5>
                                    <p>{{s14247?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Il progetto piace e prende quota. <strong>Un milione e seicentomila lire di
                                    investimento</strong> per
                                    un’opera che «si inquadra nel progetto generale di sfruttamento idraulico dell’Alta
                                    Valle dell’Orco»
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 13 dicembre 1935</em>
                                    </small>
                                </p>
                                <p class="mt-4">Il podestà è d’accordo. Si comincia a parlare coi ministeri interessati. <strong>La
                                    diga
                                    dell’Agnel si farà</strong>
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 13 dicembre 1935</em>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-md-center">
                         <div class="col-sm-3 ">
                             <a [routerLink]="['/scheda/' + s14116?.Id]">
                                 <div class="border">

                                     <h5 class="mt-2 font-weight-bold">{{s14116?.AssignedTitle}}</h5>
                                     <p>{{s14116?.Content}}</p>
                                 </div>
                             </a>
                         </div>

                         <div class="col-sm-3 ">
                             <a [routerLink]="['/scheda/' + s14210?.Id]">
                                 <div class="border">

                                     <h5 class="mt-2 font-weight-bold">{{s14210?.AssignedTitle}}</h5>
                                     <p>{{s14210?.Content}}</p>
                                 </div>
                             </a>
                         </div>

                         <div class="col-sm-3 ">
                             <a [routerLink]="['/scheda/' + s14215?.Id]">
                                 <div class="border">

                                     <h5 class="mt-2 font-weight-bold">{{s14215?.AssignedTitle}}</h5>
                                     <p>{{s14215?.Content}}</p>
                                 </div>
                             </a>
                         </div>
                     </div>-->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="cd-section section-white" id="capitolo2">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url(assets/img/storymaps/agnel-2.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3>CAPITOLO 2</h3>
                            <h2 class="title">La progettazione e il territorio</h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>
                                    <strong>Il tempo è poco, l’alta montagna permette una breve stagione di lavoro.
                                        Mentre si aspettano le ultime autorizzazioni, il Genio Civile concede l’inizio
                                        dei lavori preliminari. La Commissione Amministratrice, intanto, approva
                                        definitivamente il progetto e «dà mandato alla Direzione di redigere il
                                        capitolato d’appalto» </strong></p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 12 marzo 1936</em>
                                    </small>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong>Il capitolato è pronto.</strong> Si delibera di invitare le ditte private a
                                    partecipare all’assegnazione dei lavori per la diga e per i lavori accessori sulla
                                    strada del Colle del Nivolet
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 30 marzo 1936</em>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-md-center">
                        <!--<div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14272?.Id]">
                                <div class="border">
                                    <h5 class="mt-2 font-weight-bold">{{s14272?.AssignedTitle}}</h5>
                                    <p>{{s14272?.Content}}</p>

                                </div>
                            </a>
                        </div>-->
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14218?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14218?.AssignedTitle}}</h5>
                                    <p>{{s14218?.Content}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14127?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14127?.AssignedTitle}}</h5>
                                    <p>{{s14127?.Content}}</p>

                                </div>
                            </a>
                        </div>
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14128?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14128?.AssignedTitle}}</h5>
                                    <p>{{s14128?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/foto-agnel-1.jpg);"></div>
    <div class="section-white">
        <div class="wrapper">
            <div class="main">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Se la terra non ha padroni, ha però dei proprietari. I terreni su cui s’alzerà la
                                    diga sono in mano alla Società Idroelettrica Piemontese. La trattativa è tra
                                    gentiluomini: <strong>quasi un milione di metri quadri di terreno passano di mano ed
                                    entrano
                                    in possesso dell’AEM</strong>
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 25 aprile 1936</em>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Sulla linea di costruzione è necessaria una linea elettrica e telefonica dedicata.
                                    <strong>Una volta di più, perché l’opera sorga l’accordo con il territorio è
                                        fondamentale.</strong>
                                    La Commissione «autorizza […] il Direttore a stipulare le convenzioni con i
                                    proprietari dei terreni che saranno attraversati dalla linea elettrica Ceresole-Lago
                                    Agnel»
                                </p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 22 luglio 1936</em>
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14119?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14119?.AssignedTitle}}</h5>
                                    <p>{{s14119?.Content}}</p>

                                </div>
                            </a>
                        </div>
                        <div class="col-sm-3 ">
                            <a [routerLink]="['/scheda/' + s14130?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14130?.AssignedTitle}}</h5>
                                    <p>{{s14130?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--<img src="assets/img/storymaps/sforatore.jpg" class="img-fluid mb-5 mt-5" alt="">-->

<div class="cd-section section-white mt-5" id="capitolo3">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url(assets/img/storymaps/agnel-3.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3>CAPITOLO 3</h3>
                            <h2 class="title">La costruzione</h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>
                                    <strong>Finalmente si scava! Dopo l’autorizzazione ai lavori preliminari, il Genio
                                        Civile «autorizza l’Azienda Elettrica Municipale di Torino, in via affatto
                                        provvisoria, all’impianto dei cantieri e l’inizio dei lavori di scavo per la
                                        costruzione della diga al Lago Agnel nell’Alta Valle dell’Orco»</strong></p>
                                <p>
                                    <small><em>- Riunione della Commissione Amministratrice del 22 settembre 1936 -</em>
                                    </small>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="col-md-6 ml-auto mr-auto">
                        <div class="article-content">
                            <p class="mb-2">
                                Vince l’appalto e <strong>si aggiudica i lavori l’Impresa Giovanni Battista
                                Giachetti e Compagni</strong>
                            </p>
                            <p>
                                <small><em>- Riunione della Commissione Amministratrice del 22 settembre 1936</em>
                                </small>
                            </p>
                        </div>
                        <div class="article-content">
                            <p class="mb-2 mt-4">
                                Chiari i patti, sicuro il cammino. La Commissione approva il disciplinare esecutivo
                                dell’opera: <strong>a firmare il progetto, l’ingegnere Clemente Bornati e il
                                direttore Mario Brunetti</strong>
                            </p>
                            <p>
                                <small><em>- Riunione della Commissione Amministratrice del 30 luglio 1937</em>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white bg-image-storymap"
     style="background-image:url(assets/img/storymaps/foto-agnel-3.jpg);"></div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="container">
                <div class="row pt-5 pb-5">
                    <div class="col-md-6 ml-auto mr-auto">
                        <div class="article-content">
                            <p class="mt-4">
                                Settembre 1938: l’acqua entra nell’invaso. <strong>La diga è in funzione</strong>
                            </p>
                            <p class="mt-4">
                                Ma non sempre si può prevedere ogni cosa. A consuntivo, <strong>i costi di
                                costruzione si rivelano più alti del previsto</strong> e l’Azienda contratta con la
                                ditta costruttrice la dovuta integrazione
                            </p>
                            <p>
                                <small><em>- Riunione della Commissione Amministratrice del 27 giugno 1939</em>
                                </small>
                            </p>
                        </div>
                        <div class="article-content">
                            <p class="mt-4">
                                E con il collaudo, anche gli ultimi conti con la ditta appaltatrice sono saldati. La
                                valle ha il suo nuovo impianto e <strong>l’energia, meno cara, adesso può
                                raggiungere molte più persone</strong>
                            </p>
                            <p>
                                <small><em>- Riunione della Commissione Amministratrice del 30 ottobre 1939</em>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="cd-section section-white" id="capitolo4">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white section-background"
                 style="background: url(assets/img/storymaps/agnel-4.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3>CAPITOLO 4</h3>
                            <h2 class="title"> Le opere accessorie: ricevere dal territorio per rendere al
                                territorio</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section section-white">
                <div class="container">
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>
                                    Non basta l’ingegno per fare un’opera come una diga: ci vuole un territorio che
                                    l’accolga e la sostenga.<strong> E se il territorio dà molto, concedendo i terreni,
                                    molto è
                                    quello che riceve.</strong> L’impatto della diga sul territorio che la ospita è di
                                    sistema:
                                    c’è <strong>la linea elettrica e telefonica dedicata da Ceresole al Lago
                                    Agnel...</strong>
                                </p>
                                <p>
                                    <small><em>Riunioni della Commissione Amministratrice del 7 maggio e 22 luglio
                                        1936</em></small>
                                </p>
                            </div>

                            <div class="article-content">
                                <p class="mt-4">
                                    ...<strong>o la cura della strada militare da Ceresole al Lago del Nivolet</strong>,
                                    di cui l’AEM si fa carico in cambio della concessione di utilizzo durante i
                                    lavori...
                                </p>
                                <p>
                                    <small><em>Riunioni della Commissione Amministratrice del 30 marzo 1936 e del 13
                                        maggio 1938</em></small>
                                </p>
                            </div>

                            <div class="article-content">
                                <p class="mt-4">
                                    ...<strong>o il contributo economico alla manutenzione della strada tra Noasca e
                                    Ceresole Reale</strong>
                                </p>
                                <p>
                                    <small><em>Riunione della Commissione Amministratrice del 12 luglio 1938</em>
                                    </small>
                                </p>
                            </div>

                            <div class="article-content">
                                <p class="mt-4">
                                    Ma, sopra tutto,<strong> la percezione più chiara di non essere isole
                                    sparse</strong>, ma parte di una comunità. Di avere contribuito, ciascuno per la sua
                                    parte, a entrare <strong>un passo in più nella modernità</strong>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white section-background"
                 style="background: url(assets/img/storymaps/foto-agnel-5-1.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">

                            <h2 class="title font-weight-bold text-white"> Scopri tutti i documenti relativi alla costruzione della diga del Lago Agnel</h2>
                            <a class="btn btn-neutral btn-round videoBtn mt-4"
                               href="ricerca/risultati?searchType=Keyword&type=Storymap&value=Agnel">Visualizza tutte le
                                schede</a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="container">
                <div class="row pb-5">
                    <div class="col-sm-12 text-center">
                        <p></p>
                        <br>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<nav id="cd-vertical-nav">
    <ul>
        <li>
            <a data-number="1" (click)="smoothScroll('headers')">
                <span class="cd-dot"></span>
                <span class="cd-label">Introduzione</span>
            </a>
        </li>
        <li>
            <a data-number="2" (click)="smoothScroll('capitolo1')">
                <span class="cd-dot"></span>
                <span class="cd-label">L'idea di costruire</span>
            </a>
        </li>
        <li>
            <a data-number="3" (click)="smoothScroll('capitolo2')">
                <span class="cd-dot"></span>
                <span class="cd-label">La progettazione e il territorio</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo3')">
                <span class="cd-dot"></span>
                <span class="cd-label">La costruzione</span>
            </a>
        </li>
        <li>
            <a data-number="5" (click)="smoothScroll('capitolo4')">
                <span class="cd-dot"></span>
                <span class="cd-label">Le opere accessorie</span>
            </a>
        </li>
    </ul>
</nav>