import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partecipa',
  templateUrl: './partecipa.component.html',
  styleUrls: ['./partecipa.component.scss']
})
export class PartecipaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
