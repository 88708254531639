<div class="section">
    <div class="container tim-container">
        <div class="title">
            <h3>Typography</h3>
        </div>
        <div id="typography">
            <div class="row">
                <div class="typography-line">
                    <h1>
                        <span class="note">Header 1</span> Thinking in textures
                    </h1>
                </div>
                <div class="typography-line">
                    <h2>
                        <span class="note">Header 2</span> Thinking in textures
                    </h2>
                </div>
                <div class="typography-line">
                    <h3>
                        <span class="note">Header 3</span> Thinking in textures
                    </h3>
                </div>
                <div class="typography-line">
                    <h4>
                        <span class="note">Header 4</span> Thinking in textures
                    </h4>
                </div>
                <div class="typography-line">
                    <h5>
                        <span class="note">Header 5</span> Thinking in textures
                    </h5>
                </div>
                <div class="typography-line">
                    <h6>
                        <span class="note">Header 6</span> Thinking in textures
                    </h6>
                </div>
                <div class="typography-line">
                    <p>
                        <span class="note">Paragraph</span> Thinking in textures
                    </p>
                </div>
                <div class="typography-line">
                    <span class="note">Quote</span>
                    <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.</p><br/>
                        <footer class="blockquote-footer">Someone famous in <cite title="source Title">Source Title</cite></footer>
                    </blockquote>
                </div>
                <div class="typography-line">
                    <span class="note">Muted text</span>
                    <p class="text-muted">Fusce dapibus, tellus ac cursus commodo, tortor mauris nibh.</p>
                </div>
                <div class="typography-line">
                    <span class="note">Primary text</span>
                    <p class="text-primary">Nullam id dolor id nibh ultricies vehicula ut id elit.</p>
                </div>
                <div class="typography-line">
                    <span class="note">Success text</span>
                    <p class="text-success">Duis mollis, est non commodo luctus, nisi erat porttitor ligula.</p>
                </div>
                <div class="typography-line">
                    <span class="note">Info text</span>
                    <p class="text-info">Maecenas sed diam eget risus varius blandit sit amet non magna.</p>
                </div>
                <div class="typography-line">
                    <span class="note">Warning text</span>
                    <p class="text-warning">Etiam porta sem malesuada magna mollis euismod.</p>
                </div>
                <div class="typography-line">
                    <span class="note">Danger text</span>
                    <p class="text-danger">Donec ullamcorper nulla non metus auctor fringilla.</p>
                </div>
                <div class="typography-line">
                    <h2><span class="note">Small tag</span>Header with small subtitle
                        <br/>
                        <small>".small" is a tag for the headers</small>
                    </h2>
                </div>
            </div>
        </div>
        <br/>
        <div id="images">
            <div class="container">
                <div class="title">
                    <h3>Images</h3>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-3">
                        <h4 class="images-title">Rounded Image</h4>
                        <img src="assets/img/sections/por7o.jpg" class="img-rounded img-responsive" alt="Rounded Image">
                        <div class="img-details">
                            <div class="author">
                                <img src="assets/img/faces/joe-gardner-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                            </div>
                            <p>Sonia Green </p>
                        </div>
                    </div>
                    <div class="col-sm-3 col-md-2 mr-auto ml-auto">
                        <h4 class="images-title">Circle Image</h4>
                        <img src="assets/img/faces/kaci-baum-2.jpg" class="img-circle img-no-padding img-responsive" alt="Rounded Image">
                        <p class="text-center">Brigitte Bardot</p>
                    </div>
                    <div class="col-sm-3 col-md-2 mr-auto">
                        <h4 class="images-title">Thumbnail</h4>
                        <img src="assets/img/faces/erik-lucatero-2.jpg" class="img-thumbnail img-responsive" alt="Rounded Image">
                        <p class="text-center">John Keynes</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
