import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler
} from '@angular/common/http';
import {Injectable} from '@angular/core';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authRequest = req.clone({
            headers: req.headers.set('key', 'ws_Mosaiko_0000')
        });
        return next.handle(authRequest);
    }
}
