import {Component, OnInit, OnDestroy, HostListener, AfterViewInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {map} from 'rxjs/operators';


let easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};


@Component({
    selector: 'app-lago-agnel',
    templateUrl: './lago-agnel.component.html',
    styleUrls: ['./lago-agnel.component.scss']
})
export class LagoAgnelComponent implements OnInit, AfterViewInit {
    icon_video_button_class: string = 'fa-play';
    text_video_button: string = 'Play Video';

    returnedTree;

    s14125; // 27
    s14216; // 118
    s14116; // 18
    s14219; //
    s14247; // 2

    s14210; // 115
    s14215; // 117
    s14214; // 116
    s14222; // 124


    s14272; // 24
    s14218; // 120

    s14127; // 29
    s14128; // 30

    s14130; // 32
    s14119; // 21

    constructor(router: Router, public _api: ApiService) {
        router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = router.parseUrl(router.url);
                if (tree.fragment) {
                    const element = document.querySelector('#' + tree.fragment);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
            }
        });
    }


    @HostListener('window:scroll', ['$event'])
    updateNavigation() {
        let contentSections = document.getElementsByClassName('cd-section') as HTMLCollectionOf<any>;
        let navigationItems = document.getElementById('cd-vertical-nav').getElementsByTagName('a');

        for (let i = 0; i < contentSections.length; i++) {
            let activeSection: any = parseInt(navigationItems[i].getAttribute('data-number')) - 1;

            if ((contentSections[i].offsetTop - window.innerHeight / 2 < window.pageYOffset)
                && (contentSections[i].offsetTop + contentSections[i].scrollHeight - window.innerHeight / 2 > window.pageYOffset)) {
                navigationItems[activeSection].classList.add('is-selected');
            } else {
                navigationItems[activeSection].classList.remove('is-selected');
            }
        }
    }

    ngOnInit() {
        let id_video = document.getElementById('video-source') as HTMLVideoElement;
        id_video.play();
        this.text_video_button = 'Pause Video';
        this.icon_video_button_class = 'fa-pause';

        this._api.ReturnKeyWordsSearchResults('Storymap', 'Agnel', '', 0, 100)
            .pipe(map((res: any) => res.ReturnedTree)).subscribe(data => {
            this.returnedTree = data;
            console.log(this.returnedTree);
            this.s14216 = this.returnedTree[11];
            this.s14247 = this.returnedTree[32];
            this.s14214 = this.returnedTree[20];
            this.s14222 = this.returnedTree[28];
/*            this.s14272 = this.returnedTree[11];*/
            this.s14218 = this.returnedTree[24];
            this.s14127 = this.returnedTree[13];
            this.s14128 = this.returnedTree[14];
            this.s14130 = this.returnedTree[16];
            this.s14119 = this.returnedTree[5];

        });


        /* this._api.returnScheda('UA', '14125')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14125 = data;
             console.log('scheda:', this.s14125);
         });

         this._api.returnScheda('UA', '14116')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14116 = data;
             console.log('scheda:', this.s14116);
         });


         this._api.returnScheda('UA', '14216')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14216 = data;
             console.log('scheda:', this.s14216);
         });

         this._api.returnScheda('UA', '14219')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14219 = data;
             console.log('scheda:', this.s14219);
         });

         this._api.returnScheda('UA', '14247')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14247 = data;
             console.log('scheda:', this.s14247);
         });


         this._api.returnScheda('UA', '14210')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14210 = data;
             console.log('scheda:', this.s14210);
         });

         this._api.returnScheda('UA', '14215')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14215 = data;
             console.log('scheda:', this.s14215);
         });

         this._api.returnScheda('UA', '14214')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14214 = data;
             console.log('scheda:', this.s14214);
         });

         this._api.returnScheda('UA', '14222')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14222 = data;
             console.log('scheda:', this.s14222);
         });


         //Cap 2
         this._api.returnScheda('UA', '14272')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14272 = data;
             console.log('scheda:', this.s14272);
         });

         this._api.returnScheda('UA', '14218')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14218 = data;
             console.log('scheda:', this.s14218);
         });

         this._api.returnScheda('UA', '14127')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14127 = data;
             console.log('scheda:', this.s14127);
         });
         this._api.returnScheda('UA', '14128')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14128 = data;
             console.log('scheda:', this.s14128);
         });
         this._api.returnScheda('UA', '14119')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14119 = data;
             console.log('scheda:', this.s14119);
         });
         this._api.returnScheda('UA', '14130')
             .pipe(map((res: any) => res.ReturnedScheda)).subscribe(data => {
             this.s14130 = data;
             console.log('scheda:', this.s14130);
         });*/


        /* this._api.ReturnKeyWordsSearchResults('Tag', 'Impianti', '', 0, 4)
             .pipe(map((res: any) => res.ReturnedTree)).subscribe(data => {
             this.returnedTree = data;
             console.log(this.returnedTree);
         });*/


    }


    /* ngOnDestroy() {
         let navbar = document.getElementsByTagName('app-navbar')[0];
         navbar.children[0].classList.add('navbar-transparent');
     }*/

    smoothScroll(target) {
        let targetScroll = document.getElementById(target);
        this.scrollTo(document.documentElement, targetScroll.offsetTop, 1250);
    }

    scrollTo(element, to, duration) {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            let val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    ngAfterViewInit() {
        this.updateNavigation();
    }


}
