<div id="headers" class="header-4 cd-section">
    <div class="header-wrapper">

        <div class="page-header header-video">
            <div class="filter"></div>
            <!-- We show the video image placeholder instead of the video for small devices  -->
            <div class="video-image visible-xs visible-sm"
                 style="background-image: url('assets/img/storymaps/agnel-copertina-video.jpg')"></div>
            <div class="video-container">
                <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
                    <source src="assets/video/piacenza.mp4" type="video/mp4">
                    Video not supported
                </video>
            </div>
            <div class="content-center">
                <div class="container upper-container text-center">
                    <div class="video-text">
                        <h2>Storymap</h2>
                        <h1 class="title-uppercase title-no-upper-margin">La nascita di un acquedotto</h1>
                        <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                                class="btn btn-outline-neutral btn-round mt-5">
                            <i class="fa fa-chevron-down"></i> Inizia il percorso
                        </button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CAPITOLO 1 -->
<div class="section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 1</h3>
                            <h2 class="title ">Quando l’acqua veniva dai pozzi </h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong> 1893:</strong> L’Europa vive la sua “Epoca Bella” e l’Italia si accoda:
                                    elettricità, radio, la primissima automobile del nostro paese, immatricolata proprio
                                    quell’anno. In quest’epoca bella, in cui ancora le donne andavano a lavare i panni
                                    ai pozzi,<strong> il comune di Piacenza decide di costruire un acquedotto che porti
                                    l’acqua
                                    nelle case</strong>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/piacenza/storymap_piacenza_1.jpg);"></div>
</div>
<!--CAPITOLO 2-->
<div class="section-white" id="capitolo2">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 2</h3>
                            <h2 class="title ">Tra pubblico e privato</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong>Non è detto che la gestione pubblica sia più efficiente, né che il privato
                                        non possa fare del bene pubblico.</strong> <br> Ecco allora che il consiglio
                                    comunale delibera di non occuparsi direttamente della costruzione dell’acquedotto,
                                    ma di affidarne l’esecuzione a una ditta che in quegli anni opera molto e bene nella
                                    bassa padana: <strong>l’impresa di Francesco Garrè e dei suoi figli</strong>. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/piacenza/storymap_piacenza_2.jpg);"></div>
</div>

<!--CAPITOLO 3-->
<div class="section-white" id="capitolo3">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 3</h3>
                            <h2 class="title ">Patti chiari, bene comune</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    La concessione del comune, firmata il 17 aprile 1893, prevede la costruzione di
                                    "<strong>un acquedotto forzato</strong> per condurre un volume di acqua potabile non
                                    inferiore ai 50 litri al minuto secondo, <strong>per gli usi potabili e domestici
                                    degli abitanti della città e Comune di Piacenza</strong>, e per gli usi domestici e
                                    potabili (…) dell’Amministrazione Comunale". <br>
                                    In cambio, l’impresa Garrè si impegna ad acquistare e far crescere a bosco la zona
                                    della Fornace Vecchia, piantando alberi e assicurandone la crescita non coltivata.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/piacenza/storymap_piacenza_3.jpg);"></div>
</div>

<!--CAPITOLO 3-->
<div class="section-white" id="capitolo4">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 4</h3>
                            <h2 class="title ">Una lunga collaborazione</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong>Nel 1894 l’acquedotto è in funzione</strong>. Sarà l’inizio di una lunga collaborazione per
                                    il servizio pubblico tra impresa privata e istituzione pubblica, che <strong>solo a metà
                                    degli anni Settanta</strong>, mutate le epoche e le circostanze, vedrà il <strong>passaggio di
                                    consegne tra l’impresa Garrè e la municipalizzata AMNU</strong> (Azienda municipale nettezza
                                    urbana).
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6" *ngFor="let scheda of returnedTree">
                            <a [routerLink]="['/scheda/' + scheda?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{scheda?.AssignedTitle}}</h5>
                                    <p>{{scheda?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<nav id="cd-vertical-nav">
    <ul>
        <li>
            <a data-number="1" (click)="smoothScroll('headers')">
                <span class="cd-dot"></span>
                <span class="cd-label">Introduzione</span>
            </a>
        </li>
        <li>
            <a data-number="2" (click)="smoothScroll('capitolo1')">
                <span class="cd-dot"></span>
                <span class="cd-label">Quando l’acqua veniva dai pozzi</span>
            </a>
        </li>
        <li>
            <a data-number="3" (click)="smoothScroll('capitolo2')">
                <span class="cd-dot"></span>
                <span class="cd-label">Tra pubblico e privato</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo3')">
                <span class="cd-dot"></span>
                <span class="cd-label">Patti chiari, bene comune</span>
            </a>
        </li>
        <li>
            <a data-number="5" (click)="smoothScroll('capitolo4')">
                <span class="cd-dot"></span>
                <span class="cd-label">Una lunga collaborazione</span>
            </a>
        </li>
    </ul>
</nav>