import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'repeat'})
export class MonthPipe implements PipeTransform {
    transform(value: any) {

        if (value === 1) {
            return 'Gennaio'
        } else if (value === 2) {
            return 'Febbraio'
        } else if (value === 3) {
            return 'Marzo'
        } else if (value === 4) {
            return 'Aprile'
        } else if (value === 5) {
            return 'Maggio'
        } else if (value === 6) {
            return 'Giugno'
        } else if (value === 7) {
            return 'Luglio'
        } else if (value === 8) {
            return 'Agosto'
        } else if (value === 9) {
            return 'Settembre'
        } else if (value === 10) {
            return 'Ottobre'
        } else if (value === 11) {
            return 'Novembre'
        } else if (value === 12) {
            return 'Dicembre'
        } else {
            return '';
        }

    }
}