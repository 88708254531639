<div class="section section-gray" id="cards">
        <div class="container tim-container">
            <div class="title">
                <h2>Cards</h2>
            </div>
            <h4 class="title">Blog cards</h4>

            <div class="row">
                <div class="col-md-4 col-sm-6">
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="#pablo">
                                <img class="img" src="assets/img/sections/neill-kumar.jpg">
                            </a>
                        </div>
                        <div class="card-body text-center">
                            <h4 class="card-title">
                                We will create a great experience
                            </h4>
                            <div class="card-description">
                                Cards are an interaction model that are spreading pretty widely, in fact. What all of these have in common is that they're pulling...
                            </div>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-danger btn-round">View Article</a>
                            </div>
                        </div>
                    </div>

                    <div class="card" data-color="purple" data-background="color">
                        <div class="card-body text-center">
                            <h6 class="card-category">
                                <i class="fa fa-dribbble" aria-hidden="true"></i> Dribbble
                            </h6>
                            <h5 class="card-title">
                                <a href="#pablo">"Good Design Is as Little Design as Possible"</a>
                            </h5>
                            <p class="card-description">
                                Design makes an important contribution to the preservation of the environment. It conserves resources and minimises physical and visual pollution throughout the lifecycle of the product....
                            </p>
                            <div class="card-footer text-center">
                                <a href="#pablo" ngbTooltip="Bookmark" class="btn btn-outline-neutral btn-round btn-just-icon"><i class="fa fa-bookmark-o"></i></a>
                                <a href="#pablo" class="btn btn-neutral btn-round"><i class="fa fa-newspaper-o"></i> Read</a>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h6 class="author pull-left">Ronna Johnson</h6>
                            <span class="category-social text-info pull-right">
                                <i class="fa fa-twitter"></i>
                            </span>
                            <div class="clearfix"></div>
                            <p class="card-description">
                                "It clarifies the product’s structure. Better still, it can make the product clearly express its function by making use of the <a href="#twitter" class="text-danger">@mike</a>'s intuition. At best, it is self-explanatory."
                            </p>
                        </div>
                    </div>

                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="card card-blog">
                        <div class="card-image">
                            <a href="#pablo">
                                <img class="img" src="assets/img/sections/anders-jilden.jpg">
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">
                                Good Design Is Aesthetic
                            </h4>
                            <div class="card-description">
                                Cards are an interaction model that are spreading pretty widely, in fact. What all of these have in common is that they're pulling...
                            </div>
                            <hr />
                            <div class="card-footer">
                                <div class="author">
                                    <a href="#pablo">
                                       <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="..." class="avatar img-raised">
                                       <span>Mike John</span>
                                    </a>
                                </div>
                                <div class="stats">
                                    <i class="fa fa-clock-o" aria-hidden="true"></i> 5 min read
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/rawpixel-com.jpg')">
                        <div class="card-body">
                            <a href="#pablo">
                                <h3 class="card-title">Ten Principles of “Good Design”</h3>
                            </a>
                            <p class="card-description">
                                Don't be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-success btn-round">
                                    <i class="fa fa-file-pdf-o" aria-hidden="true"></i> Download PDF
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="card" data-background="color" data-color="orange">
                        <div class="card-body">
                            <div class="author">
                                <a href="#pablo">
                                   <img src="assets/img/faces/erik-lucatero-2.jpg" alt="..." class="avatar img-raised">
                                   <span>Erik Johnson</span>
                                </a>
                            </div>
                            <span class="category-social pull-right">
                                <i class="fa fa-quote-right"></i>
                            </span>
                            <div class="clearfix"></div>
                            <p class="card-description">
                                "Less, but better – because it concentrates on the essential aspects, and the products are not burdened with non-essentials. Back to purity, back to simplicity. At best, it is self-explanatory."
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body text-center">
                            <span class="category-social text-info pull-right">
                                <i class="fa fa-twitter"></i>
                            </span>
                            <div class="clearfix"></div>
                            <div class="author">
                                <a href="#pablo">
                                   <img src="assets/img/faces/kaci-baum-2.jpg" alt="..." class="avatar-big img-raised border-gray">
                                </a>
                                <h5 class="card-title">Kaci Baum</h5>
                                <p class="category"><a href="#twitter" class="text-danger">@kacibaum</a></p>
                            </div>
                            <p class="card-description">
                                "Less, but better – because it concentrates on the essential aspects, and the products are not burdened with non-essentials."
                            </p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-image">
                            <a href="#pablo">
                                <img class="img" src="assets/img/sections/david-marcu.jpg">
                            </a>
                        </div>
                        <div class="card-body">
                            <label class="label label-warning">Travel</label>
                            <h5 class="card-title">
                                Currently at the San Francisco Museum of Modern Art
                            </h5>
                            <hr />
                            <div class="card-footer">
                                <div class="author">
                                    <a href="#pablo">
                                       <img src="assets/img/rihanna.jpg" alt="..." class="avatar img-raised">
                                       <span>Lord Alex</span>
                                    </a>
                                </div>
                               <div class="stats">
                                    <i class="fa fa-heart" aria-hidden="true"></i> 5.3k
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="title">Text Coloured Cards</h4>
            <div class="row coloured-cards">
                <div class="col-md-4 col-sm-6">
                    <div class="card-big-shadow">
                        <div class="card card-just-text" data-background="color" data-color="blue" data-radius="none">
                            <div class="card-body">
                                <h6 class="card-category">Best cards</h6>
                                <h4 class="card-title"><a href="#paper-kit">Blue Card</a></h4>
                                <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                            </div>
                        </div> <!-- end card -->
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="card-big-shadow">
                        <div class="card card-just-text" data-background="color" data-color="green" data-radius="none">
                            <div class="card-body">
                                <h6 class="card-category">Best cards</h6>
                                <h4 class="card-title"><a href="#paper-kit">Green Card</a></h4>
                                <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                            </div>
                        </div> <!-- end card -->
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="card-big-shadow">
                        <div class="card card-just-text" data-background="color" data-color="yellow" data-radius="none">
                            <div class="card-body">
                                <h6 class="card-category">Best cards</h6>
                                <h4 class="card-title"><a href="#paper-kit">Yellow Card</a></h4>
                                <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                            </div>
                        </div> <!-- end card -->
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="card-big-shadow">
                        <div class="card card-just-text" data-background="color" data-color="brown" data-radius="none">
                            <div class="card-body">
                                <h6 class="card-category">Best cards</h6>
                                <h4 class="card-title"><a href="#paper-kit">Brown Card</a></h4>
                                <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                            </div>
                        </div> <!-- end card -->
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="card-big-shadow">
                        <div class="card card-just-text" data-background="color" data-color="purple" data-radius="none">
                            <div class="card-body">
                                <h6 class="card-category">Best cards</h6>
                                <h4 class="card-title"><a href="#paper-kit">Purple Card</a></h4>
                                <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                            </div>
                        </div> <!-- end card -->
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="card-big-shadow">
                        <div class="card card-just-text" data-background="color" data-color="orange" data-radius="none">
                            <div class="card-body">
                                <h6 class="card-category">Best cards</h6>
                                <h4 class="card-title"><a href="#paper-kit">Orange Card</a></h4>
                                <p class="card-description">What all of these have in common is that they're pulling information out of the app or the service and making it relevant to the moment. </p>
                            </div>
                        </div> <!-- end card -->
                    </div>
                </div>

            </div>

            <h4 class="title">Pricing cards</h4>
            <div class="row">
                <div class="col-md-3">
                    <div class="card card-pricing">
                        <div class="card-body">
                            <h6 class="card-category">Enterprise</h6>
                            <h1 class="card-title">$139</h1>
                            <ul>
                                <li><i class="fa fa-check text-success" aria-hidden="true"></i> Sharing Tools</li>
                                <li><i class="fa fa-check text-success" aria-hidden="true"></i> Design Tools</li>
                                <li><i class="fa fa-times text-danger" aria-hidden="true"></i> Private Messages</li>
                                <li><i class="fa fa-times text-danger" aria-hidden="true"></i> Personal Brand</li>
                                <li><i class="fa fa-times text-danger" aria-hidden="true"></i> Twitter API</li>
                            </ul>
                            <a href="#pablo" class="btn btn-success btn-round card-link">Get Started</a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing" data-color="orange">
                        <div class="card-body">
                            <div class="card-icon">
                                <span class="icon-simple"><i class="nc-icon nc-istanbul"></i></span>
                            </div>
                            <h3 class="card-title">$249</h3>

                            <p class="card-description">
                                This is good if your company size is between 100 and 299 employees.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-neutral btn-round">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing">
                        <div class="card-body">
                            <h6 class="card-category">Premium</h6>
                            <h1 class="card-title">&euro;89</h1>
                            <ul>
                                <li><b>10</b> Projects</li>
                                <li><b>80</b> Team Members</li>
                                <li><b>Unlimited</b> Contacts</li>
                                <li><b>Unlimited</b> Messages</li>
                            </ul>
                            <a href="#pablo" class="btn btn-warning btn-round">
                                Get Started
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card card-pricing" data-background="image" style="background-image: url('assets/img/sections/mika-matin.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Single User</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-user-run"></i>
                            </div>
                            <h3 class="card-title">$29</h3>
                            <p class="card-description">
                                This is good if you are a freelancer of photographer.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-info btn-round card-link">Choose Plan</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <h4 class="title">Full background cards</h4>
            <div class="row">
                <div class="col-md-4">
                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/uriel-soberanes.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">
                                <i class="fa fa-newspaper-o"></i> Technology</h6>
                            <a href="#pablo">
                                <h3 class="card-title">The aesthetic quality of a product</h3>
                            </a>
                            <p class="card-description">
                                 It clarifies the product’s structure. Better still, it can make the product clearly express its function by making use of the user’s intuition.
                            </p>
                            <div class="card-footer">
                                <div class="author">
                                    <a href="#pablo">
                                       <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="..." class="avatar img-raised">
                                       <span>Ayo Michael</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/david-marcu.jpg')">
                        <div class="card-body">
                            <h6 class="card-category">Travel</h6>
                            <div class="card-icon">
                                <i class="nc-icon nc-spaceship"></i>
                            </div>
                            <p class="card-description">Unlike fashionable design, it lasts many years – even in today’s throwaway society.</p>

                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-danger btn-round card-link">
                                    <i class="fa fa-book" aria-hidden="true"></i> Read Article
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="card" data-background="image" style="background-image: url('assets/img/sections/leonard-cotte.jpg')">
                        <div class="card-body">
                            <label class="label label-info">Web Design</label>
                            <a href="#pablo">
                                <h3 class="card-title">San Francisco Museum of Modern Art</h3>
                            </a>
                            <p class="card-description">Nothing must be arbitrary or left to chance. Care and accuracy in the design process show respect towards the consumer.
                            </p>
                            <div class="card-footer">
                                <a href="#pablo" class="btn btn-link btn-neutral">
                                    <i class="fa fa-book" aria-hidden="true"></i> Read
                                </a>
                                <a href="#pablo" class="btn btn-link btn-neutral">
                                    <i class="fa fa-clock-o" aria-hidden="true"></i> Bookmark
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <h4 class="title">Team Members Cards</h4>
            <div class="row">
                <div class="col-md-3 col-sm-6">

                    <div class="card card-profile">
                        <div class="card-cover" style="background-image: url('assets/img/sections/angelo-pantazis.jpg')">
                        </div>
                        <div class="card-avatar border-white">
                            <a href="#avatar">
                                <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="..."/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Ayo Michael</h4>
                            <h6 class="card-category">Designer</h6>
                            <p class="card-description">
                            Hey there! Welcome to your user card. As you can see, it is already looking great.
                            </p>
                        </div>
                    </div> <!-- end card -->

                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="card card-profile">
                        <div class="card-cover" style="background-image: url('assets/img/sections/pavel-kosov.jpg')">
                        </div>
                        <div class="card-avatar border-white">
                            <a href="#avatar">
                                <img src="assets/img/faces/joe-gardner-2.jpg" alt="..."/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Audrey Hepburn</h4>
                            <h6 class="card-category">Developer</h6>

                            <div class="card-footer text-center">
                                <a href="#paper-kit" class="btn btn-just-icon btn-twitter">
                                    <i class="fa fa-twitter"></i>
                                </a>
                                <a href="#paper-kit" class="btn btn-just-icon btn-dribbble">
                                    <i class="fa fa-dribbble"></i>
                                </a>
                                <a href="#paper-kit" class="btn btn-just-icon btn-facebook">
                                    <i class="fa fa-facebook-square"></i>
                                </a>
                            </div>
                        </div>
                    </div> <!-- end card -->
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="card card-profile">
                        <div class="card-avatar border-white">
                            <a href="#avatar">
                                <img src="assets/img/faces/kaci-baum-2.jpg" alt="..."/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Laci Thor</h4>
                            <h6 class="card-category">Marketing Specialist</h6>
                            <p class="card-description">
                            British actress and humanitarian recognised as a film and fashion icon.
                            </p>
                            <div class="card-footer text-center">
                                <a href="#paper-kit" class="btn btn-just-icon btn-outline-default">
                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                                <a href="#paper-kit" class="btn btn-just-icon btn-outline-default ">
                                    <i class="fa fa-github-alt" aria-hidden="true"></i>
                                </a>
                                <a href="#paper-kit" class="btn btn-just-icon btn-outline-default">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div> <!-- end card -->
                </div>

                <div class="col-md-3 col-sm-6">
                    <div class="card card-profile">
                        <div class="card-avatar border-white">
                            <a href="#avatar">
                                <img src="assets/img/faces/erik-lucatero-2.jpg" alt="..."/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Joe Gardner</h4>
                            <h6 class="card-category">Artist and Music Composer</h6>
                            <p class="card-description">
                            One of the co-founders. Joe drives the technical strategy of the platform, customer support and brand.
                            </p>
                            <div class="card-footer text-center">
                               <button href="#paper-kit" class="btn btn-icon btn-outline-default btn-border btn-round"><i class="fa fa-check"></i> Following</button>
                            </div>
                        </div>
                    </div> <!-- end card -->
                </div>

            </div>

            <h4 class="title"><small>Plain</small></h4>
            <div class="row">
                <div class="col-md-4 col-sm-6 ml-auto mr-auto">
                    <div class="card card-profile card-plain">
                        <div class="card-avatar border-white">
                            <a href="#avatar">
                                <img src="assets/img/placeholder.jpg" alt="..."/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Anonymous Tim</h4>
                            <h6 class="card-category">A private guy</h6>
                            <p class="card-description">
                            Hey there! Welcome to your user card. As you can see, it is already looking great. But if you want to tell us more about yourself by adding details in the settings page.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                            <a href="#paper-kit" class="btn btn-icon btn-rotate btn-round"><i class="fa fa-cog"></i> Settings</a>
                        </div>
                    </div> <!-- end card -->
                </div>

                <div class="col-md-4 col-sm-6 ml-auto mr-auto">
                    <div class="card card-profile card-plain">
                        <div class="card-avatar border-white">
                            <a href="#avatar">
                                <img src="assets/img/faces/joe-gardner-2.jpg" alt="..."/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Joe Gardner</h4>
                            <h6 class="card-category">Artist and Music Composer</h6>
                            <p class="card-description">
                            Joe Garnder is one of the co-founders and drives the technical strategy of the platform, customer support and brand. Talk with Joe only interesting topics.
                            </p>
                        </div>
                        <div class="card-footer text-center">
                           <button href="#paper-kit" class="btn btn-icon btn-outline-default btn-border btn-round"><i class="fa fa-check"></i> Following</button>
                        </div>

                    </div> <!-- end card -->
                </div>
            </div>

            <h4 class="title">Image Cards</h4>
            <h4 class="title"><small>Cards with creators</small></h4>
            <div class="row">
                <div class="col-md-3 col-sm-6">
                    <div class="card card-plain">

                        <div class="card-image">
                            <a href="#paper-kit">
                                <img src="assets/img/sections/ilya-yakover.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <a href="#paper-kit">
                                    <div class="author">
                                        <img src="assets/img/faces/clem-onojeghuo-3.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive img-raised">
                                    </div>
                                    <span class="name">Eric Faker</span>
                                </a>
                                <div class="meta">Thinking in Textures</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="card-image text-center">
                        <a href="#paper-kit">
                            <img src="assets/img/sections/rawpixel-coms.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                        </a>
                        <div class="card-body details-center">
                            <a href="#paper-kit">
                                <div class="author">
                                    <img src="assets/img/faces/ayo-ogunseinde-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                    <div class="text">
                                        <span class="name">Tom Hanks</span>
                                        <div class="meta">Drawn on 23 Jan</div>
                                    </div>
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card-image text-center">
                        <a href="#paper-kit">
                            <img src="assets/img/sections/joshua-stannard.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                        </a>
                        <div class="card-body details-center">
                            <a href="#paper-kit">
                                <div class="author">
                                    <img src="assets/img/faces/erik-lucatero-2.jpg" alt="Circle Image" class="img-circle img-no-padding img-responsive">
                                    <div class="text">
                                        <span class="name">Sunset Bling</span>
                                        <div class="meta">Lincoln</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <h4 class="title"><small>Cards with products</small></h4>
            <div class="row">
                <div class="col-md-3 col-sm-4">
                    <div class="card card-product card-plain">
                        <div class="card-image">
                            <a href="#paper-kit">
                                <img src="assets/img/balmain-1.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <div class="card-description">
                                    <h5 class="card-title">Double Breasted Mini Dress</h5>
                                    <p class="card-description">Dresses & Skirts</p>
                                </div>
                                <div class="actions">
                                    <h5>$300</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4">
                    <div class="card card-product card-plain">
                        <div class="card-image">
                            <a href="#paper-kit">
                                <img src="assets/img/balmain-2.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <div class="card-description">
                                    <h5 class="card-title">Chrystal Dress</h5>
                                    <div class="meta">Dresses & Skirts</div>
                                </div>

                                <div class="actions">
                                    <button class="btn btn-link btn-just-icon"><i class="fa fa-star-o" aria-hidden="true"></i></button>
                                    <button class="btn btn-link btn-just-icon"><i class="nc-icon nc-simple-delete"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-sm-4">
                    <div class="card card-product card-plain">
                        <div class="card-image">
                            <a href="#paper-kit">
                                <img src="assets/img/balmain-3.jpg" alt="Rounded Image" class="img-rounded img-responsive">
                            </a>
                            <div class="card-body">
                                <div class="card-description">
                                    <h5 class="card-title">Chrystal Skirt</h5>
                                    <p class="card-description">Only on demand</p>
                                </div>

                                <div class="actions">
                                    <button class="btn btn-link btn-danger btn-just-icon">234 <i class="nc-icon nc-favourite-28"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
