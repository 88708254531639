<div class="wrapper pt-5 ">
    <div class="team-5 section-image" style="background-image: url('assets/img/staticImages/wip.jpg')">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center"><h2 class=" text-warning">Work in progress</h2>
                    <h5 class="title">
                        L’archivio storico del Gruppo Iren nel suo complesso conserva quasi 2 Km di documentazione
                        d’archivio, decine di migliaia di disegni tecnici, fotografie, pubblicazioni e materiali tecnico
                        scientifici.
                    </h5>
                </div>
            </div>
            <div class="row mt-5 wip-numbers">
                <div class="col-sm-3 text-center text-white">
                    <span class="fa-stack fa-2x" style="vertical-align: top;">
                        <i class="far fa-circle fa-stack-2x"></i>
                        <i class="fas fa-file-contract fa-stack-1x"></i>
                    </span>
                    <br>
                    <ng2-odometer
                            [number]="1300"
                            [config]="{ animation: 'count', format: 'd', theme: 'minima', value: 0, auto: true }">
                    </ng2-odometer>
                    <h5 class="title-uppercase text-white font-weight-bold">Schede descrittive</h5>
                </div>
                <div class="col-sm-3 text-center text-white">

                    <span class="fa-stack fa-2x" style="vertical-align: top;">
                        <i class="far fa-circle fa-stack-2x"></i>
                        <i class="fas fa-file-invoice fa-stack-1x"></i>
                    </span><br>
                    <ng2-odometer
                            [number]="1500"
                            [config]="{ animation: 'count', format: 'd', theme: 'minima', value: 0, auto: true }">
                    </ng2-odometer>
                    <h5 class="title-uppercase text-white font-weight-bold">Documenti digitali</h5>
                </div>
                <div class="col-sm-3 text-center text-white">
                    <span class="fa-stack fa-2x" style="vertical-align: top;">
                        <i class="far fa-circle fa-stack-2x"></i>
                    <i class="far fa-file-video fa-stack-1x"></i>
                    </span><br>
                    <ng2-odometer
                            [number]="20"
                            [config]="{ animation: 'count', format: 'd', theme: 'minima', value: 0, auto: true }">
                    </ng2-odometer>
                    <h5 class="title-uppercase text-white font-weight-bold">Video</h5>
                </div>
                <div class="col-sm-3 text-center text-white">
                 <span class="fa-stack fa-2x" style="vertical-align: top;">
                        <i class="far fa-circle fa-stack-2x"></i>
                    <i class="fas fa-route fa-stack-1x"></i>
                 </span><br>
                    <ng2-odometer
                            [number]="5"
                            [config]="{ animation: 'count', format: 'd', theme: 'minima', value: 0, auto: true }">
                    </ng2-odometer>
                    <h5 class="title-uppercase text-white font-weight-bold">Storymaps</h5>
                </div>
                <div class="col-sm-12 text-center mt-5">
                    <h5 class="text-white">...in continuo aumento</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="wrapper wip">
    <div class="section landing-section mb-0 pt-5">
        <div class="container">


            <div class="row">
                <div class="col-sm-8 m-auto wip-card">
                    <span class="date">aprile 2019</span>
                    <h4 class="">Aggiornamento archivio</h4>
                    <p><strong>Abbiamo aggiornato il portale con le seguenti nuove pubblicazioni:</strong><br>

                        <span class="ml-2">• Archivio aggregato AMIAT</span> <br>
                        <span class="ml-2">• Archivio ASM (Azienda Servizi Municipalizzati di Piacenza)</span> <br>
                        <span class="ml-2">• Disegni tecnici e relazioni Impianto di Rosone (Archivio AEM)</span> <br>
                        <span class="ml-2">• Storymap “La nascita di un acquedotto” (Piacenza)</span> <br>
                        <span class="ml-2">• Storymap “E luce fu” (Genova)</span> </p>

                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-sm-8 m-auto wip-card">
                    <span class="date">dicembre 2018</span>
                    <h4 class="">Archivio online</h4>
                    <p>Lo straordinario patrimonio archivistico del Gruppo Iren diventa accessibile. Il Portale è in
                        continua evoluzione e costantemente aggiornato. Seguici e scopri con noi la Storia e le Storie
                        del Gruppo.</p>
                    <p>
                        <span class="font-weight-bold">Cooming soon:</span> <br>

                        <strong> Archivi aggregati</strong> <br>
                        <span class="ml-2">• Archivio Amiat</span> <br>
                        <span class="ml-2">• Archivio amministrativo De Ferrari Galliera</span>

                    </p>
                </div>
            </div>
            <hr>
        </div>
    </div>
</div>