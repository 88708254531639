import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';

import {PresentationComponent} from './home/presentation.component';
import {Error404Component} from './error404/error404.component';
import {StorymapComponent} from './percorsi/storymap/storymap.component';
import {LagoAgnelComponent} from './percorsi/storymap/lago-agnel/lago-agnel.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ProjectComponent} from './project/project.component';
import {PartecipaComponent} from './partecipa/partecipa.component';
import {GenovaComponent} from './percorsi/storymap/genova/genova.component';
import {MetanoComponent} from './percorsi/storymap/metano/metano.component';
import {PiacenzaComponent} from './percorsi/storymap/piacenza/piacenza.component';
import {GenovaGavetteComponent} from './percorsi/storymap/genova-gavette/genova-gavette.component';
import {PresidentiComponent} from './presidenti/presidenti.component';
import {AmiatComponent} from './percorsi/storymap/amiat/amiat.component';
import {ReplaceDash} from '../shared/pipes/replaceDash';
import { NewsListComponent } from './news/news-list/news-list.component';
import { NewsSingleComponent } from './news/news-single/news-single.component';
import { HighlightsComponent } from './highlights/highlights/highlights.component';
import {OrderModule} from 'ngx-order-pipe';
import { UnSecoloDiStoriaComponent } from './un-secolo-di-storia/un-secolo-di-storia.component';
import { Timeline3dModule } from 'app/components/timeline3d/timeline3d.module';
import { TimelineStandaloneComponent } from './timeline-standalone/timeline-standalone.component';
import { AcademyComponent } from './academy/academy.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
        LazyLoadImageModule,
        OrderModule,
        Timeline3dModule
    ],
    declarations: [
        PresentationComponent,
        Error404Component,
        StorymapComponent,
        LagoAgnelComponent,
        ProjectComponent,
        PartecipaComponent,
        GenovaComponent,
        MetanoComponent,
        PiacenzaComponent,
        GenovaGavetteComponent,
        PresidentiComponent,
        AmiatComponent,
        ReplaceDash,
        NewsListComponent,
        NewsSingleComponent,
        HighlightsComponent,
        UnSecoloDiStoriaComponent,
        TimelineStandaloneComponent,
        AcademyComponent
    ],
    exports: [PresentationComponent, Error404Component],
    providers: []
})
export class PagesModule {
}
