import {Component, OnInit, OnDestroy, HostListener, ElementRef, AfterViewInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EmbedVideoService} from 'ngx-embed-video/dist';
import {environment} from '../../../environments/environment';

declare const $: any;


@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})

export class PresentationComponent implements OnInit, OnDestroy, AfterViewInit {


    cmsEndpoint = environment.cmsEndpoint;
    imgEndpoint = environment.imgEndpoint;

    order: string = 'item_date';

    model = {
        left: true,
        middle: false,
        right: false
    };
    date: Date = new Date();
    newsList;
    contentList;
    closeResult: string;

    yt_iframe_html: any;

    timeline;
    TL;

    constructor(public el: ElementRef, private http: HttpClient, private modalService: NgbModal,
                private embedService: EmbedVideoService) {
        /*this.yt_iframe_html = this.embedService.embed('https://youtu.be/LbUYMzFDf7I', {attr: {width: '100%', height: 400}});*/
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentPosition = document.getElementsByClassName('add-animation');
        const scrollPosition = window.pageYOffset;

        for (var i = 0; i < componentPosition.length; i++) {
            var rec = componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
            if (scrollPosition + window.innerHeight >= rec) {
                componentPosition[i].classList.add('animated');
            } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
                componentPosition[i].classList.remove('animated');
            }
        }
    }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('presentation-page');
        body.classList.add('loading');
        var navbar = document.getElementsByTagName('nav')[0];


        this.http.get<any>(this.cmsEndpoint + '/contents/news+work-in-progress?limit=3&offset=0').subscribe(res => {
            this.newsList = res.data;
            console.log(this.newsList);

        });

        this.http.get<any>(this.cmsEndpoint + '/contents/documenti+foto+interviste+video?limit=3&offset=0').subscribe(res => {
            this.contentList = res.data;
            console.log(this.contentList);
        })

        /*navbar.classList.add('navbar-transparent');*/

        // IsoGrid(document.querySelector('.isolayer--deco1'), {
        //     transform : 'translateX(33vw) translateY(-340px) rotateX(45deg) rotateZ(45deg)',
        //     stackItemsAnimation : {
        //         // properties(pos) {
        //         //     return {
        //         //         translateZ: (pos + 1) * 30,
        //         //         rotateZ: Math.floor(Math.random() * (4 - (-4) + 1)) + (-4)
        //         //     };
        //         // },
        //         options(pos, itemstotal) {
        //             return {
        //                 type: dynamics.bezier,
        //                 duration: 500,
        //                 points: [{"x":0,"y":0,"cp":[{"x":0.2,"y":1}]},{"x":1,"y":1,"cp":[{"x":0.3,"y":1}]}],
        //                 delay: (itemstotal-pos-1)*40
        //             };
        //         }
        //     }
        // });
    }


    open(content, type) {
        if (type === 'lg') {
            console.log('aici');
            this.modalService.open(content, {size: 'lg'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            this.modalService.open(content, {size: 'lg'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }


    private getDismissReason(reason: any): string {

        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    ngAfterViewInit() {
        // @ts-ignore
        const timeline = new TL.Timeline('timeline-embed',
            'https://docs.google.com/spreadsheets/d/1iVXFHFhBh0pQHyn4SR0IDKO_xITLMjCPgZ3zfIbphFI');
    }

    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('presentation-page');
        body.classList.remove('loading');
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }
}
