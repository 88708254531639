import {Component, HostListener, OnInit} from '@angular/core';
import {ApiService} from '../services/api.service';
import {map} from 'rxjs/operators';
import {OrderPipe} from 'ngx-order-pipe';
import {MonthPipe} from '../services/monthPipe';
import {AuthService} from '../auth/auth.service';
import {Inject} from '@angular/core';
import {DOCUMENT} from '@angular/platform-browser';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
    errorAlertMessage: boolean = false;
    auth;
    returnedTree;
    order = 'DateElement.FromYear';
    keywordOrder = 'KeyWordValue';
    active: string;
    keywordList;
    timelineActive;
    translatedMonth;
    collapsed = true;

    constructor(
        private _api: ApiService,
        private orderPipe: OrderPipe,
        private monthPipe: MonthPipe,
        private _auth: AuthService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.auth = this._auth.getIsAuth()
    }

    ngOnInit() {

        if (!this.auth) {
            setTimeout(function () {
                window.location.reload();
                console.log('NAH');
            }, 100);
        } else {
            this.startingTimeline();
            this._api.ReturnKeyWordsList()
                .pipe(map((res: any) => res.WsObjects))
                .subscribe(res => {
                    this.keywordList = this.orderPipe.transform(res, this.keywordOrder);
                    console.log(this.keywordList)
                }, error => {
                    this.errorAlertMessage = true;
                });
        }

    }


    startingTimeline() {
        this.collapsed = true;
        this._api.ReturnKeyWordsSearchResults('TimelineIniziale', '', '', 0, 100)
            .pipe(map((res: any) => res.ReturnedTree))
            .subscribe(res => {
                this.returnedTree = this.orderPipe.transform(res, this.order);
                window.scrollTo(0, 0);
                this.active = 'eventiPrincipali';
            }, error => {
                this.errorAlertMessage = true;
            })
    }

    filterCity(city) {
        this.collapsed = true;
        this._api.ReturnKeyWordsSearchResults('Timeline', city, '', 0, 100)
            .pipe(map((res: any) => res.ReturnedTree))
            .subscribe(res => {
                this.returnedTree = this.orderPipe.transform(res, this.order);
                this.active = city;
                if (!city) {
                    this.active = 'tuttiGliEventi'
                }
                window.scrollTo(0, 0)
            }, error => {
                this.errorAlertMessage = true;
            })
    }


    getFlagColor(value) {
        switch (value) {
            case 'Timeline/Torino':
                return 'flagYellow';
            case 'Timeline/Genova':
                return 'flagOrange';
            case 'Timeline/Reggio Emilia':
                return 'flagRed';
            case 'Timeline/Piacenza':
                return 'flagGreen';
            case 'Timeline/Parma':
                return 'flagBlue';
            case 'Timeline/Comunicazione':
                return 'flagGreen';
            case 'Timeline/Comunicazione':
                return 'flagBlue';
        }
    }

    getColor(value) {
        switch (value) {
            case 'Timeline/Torino':
                return 'yearYellow';
            case 'Timeline/Genova':
                return 'yearOrange';
            case 'Timeline/Reggio Emilia':
                return 'yearRed';
            case 'Timeline/Piacenza':
                return 'yearGreen';
            case 'Timeline/Parma':
                return 'yearBlue';
            case 'Timeline/Comunicazione':
                return 'yearGreen';
            case 'Timeline/Comunicazione':
                return 'yearBlue';
        }
    }

    getLabelColor(value) {
        switch (value) {
            case 'Torino':
                return '#f8d751';
            case 'Genova':
                return '#ea9244';
            case 'Reggio Emilia':
                return '#de3f39';
            case 'Piacenza':
                return '#55af64';
            case 'Parma':
                return '#2a4680';
            case 'Comunicazione':
                return '#55af64';
            case 'Amiat':
                return '#2a4680';
        }
    }

    /*TODO: agganciare sotto parole chiave*/

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
        if (window.pageYOffset > 100) {
            this.collapsed = true;
        } else {
            if (window.innerWidth > 375) {
                this.collapsed = false;
            }
        }
    }

}
