import {Component, OnInit, AfterViewInit, ViewChildren, HostListener} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import * as zoom from '../../../assets/js/zoom';
import {AuthService} from 'app/auth/auth.service';
import {ApiService} from 'app/services/api.service';
import {map} from 'rxjs/operators';
import {EmbedVideoService} from 'ngx-embed-video/dist';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-timeline3d',
    templateUrl: './timeline3d.component.html',
    styleUrls: ['./timeline3d.component.scss']
})
export class Timeline3dComponent implements OnInit {
    public endpoint = environment.endpoint;
    @ViewChildren('elementsTimeline') elementsTimeline;
    public timelineData;
    public config;
    public fullScreen = false;
    public hideForIe = false;
    public auth;
    detailsOpenedAt: number = null;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private _api: ApiService,
        private embedService: EmbedVideoService,
        private _auth: AuthService,
    ) {
        this.isIE();
        this.auth = this._auth.getIsAuth();
    }

    splitDate(date: string, format: string) {
        return moment(new Date(date)).format(format);
    }

    showDetails(id: string) {
        this.detailsOpenedAt = window.scrollY;
        const element = this.timelineData.find(el => el.id === id);
        if (element.type !== 'type2') {
            const section = document
                .getElementById(id)
                .getElementsByTagName('section')[0];
            section.classList.add('expanded');
            const nav_item: HTMLElement = document.getElementsByClassName(
                'menu__item--active'
            )[0] as HTMLElement;
            nav_item.click();
        }
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events

    onScroll(event) {
        if ((window.scrollY + 100) < this.detailsOpenedAt || (window.scrollY - 500) > this.detailsOpenedAt) {
            const detail = document
            .getElementsByClassName('expanded')[0];
            if (detail) {
                detail.classList.remove('expanded');
            }
        }
    }

    hideDetails(id: string) {
        this.detailsOpenedAt = null;
        const section = document
            .getElementById(id)
            .getElementsByTagName('section')[0];
        section.classList.remove('expanded');
    }


    ngOnInit() {
        this.authService.login();
        if (!this.auth) {
            setTimeout(function () {
                window.location.reload();
                console.log('NAH');
            }, 100);
        } else {
            this.http.get('assets/json/timeline.json').subscribe((data: { config: any; elements: any }) => {
                this.config = data.config;
                this.timelineData = data.elements;
                this.timelineData.forEach(element => {
                    if (element.links) {
                        element.links.forEach(link => {
                            if (link.type === 'Mosaiko') {
                                this._api
                                    .returnScheda('UA', link.id)
                                    .pipe(map((res: any) => res.ReturnedScheda))
                                    .subscribe(response => {
                                        const scheda = response;
                                        link.url = 'scheda/' + link.id;
                                        link.title = scheda.AssignedTitle;
                                        link.excerpt = scheda.Content;
                                        link.image = scheda.Attachments[0].MediumUrl;
                                    });
                            }
                        });
                    }
                    if (element.videoUrl) {
                        console.log('videourl', element.videoUrl)
                        /* element.videoId = this._sanitizer.bypassSecurityTrustResourceUrl(
                          "https://www.youtube.com/embed/" + element.videoId
                        ) */;
                        element.videoEmbed = this.embedService.embed(element.videoUrl,
                            {
                                attr: {
                                    width: 500,
                                    height: 300
                                }
                            }
                        );
                    }
                });
                this.elementsTimeline.changes.subscribe(t => {
                    window['zoom'].init();
                });
            });
        }


    }


    isIE() {
        const ua = navigator.userAgent;
        /* MSIE used to detect old browsers and Trident used to newer ones*/
        let is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
        console.log('is_ie: ', is_ie);
        if (is_ie) {
            this.hideForIe = true;
        }
    }
}
