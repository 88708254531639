<div id="headers" class="header-4 cd-section">
    <div class="header-wrapper">

        <div class="page-header header-video">
            <div class="filter"></div>
            <!-- We show the video image placeholder instead of the video for small devices  -->
            <div class="video-image visible-xs visible-sm"
                 style="background-image: url('assets/img/storymaps/agnel-copertina-video.jpg')"></div>
            <div class="video-container">
                <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
                    <source src="assets/video/genovaGavette.mp4" type="video/mp4">
                    Video not supported
                </video>
            </div>
            <div class="content-center">
                <div class="container upper-container text-center">
                    <div class="video-text">
                        <h2>Storymap</h2>
                        <h1 class="title-uppercase title-no-upper-margin">E luce fu</h1>
                        <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                                class="btn btn-outline-neutral btn-round mt-5">
                            <i class="fa fa-chevron-down"></i> Inizia il percorso
                        </button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CAPITOLO 1 -->
<div class="section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 1</h3>
                            <h2 class="title ">La Genova del "gas francese"</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong> La prima volta che si sente nominare tra le strade cittadine, il gas ha
                                        ancora la
                                        “zeta” (gaz) e l’accento francese</strong>. Francesi le prime proposte di
                                    impianto
                                    industriale, fatte dalla Compagnia del Gaz di Lione nel 1838 e respinte dal comune;
                                    francese la <strong>Union des Gaz che nel 1857 assume l’incarico dell’illuminazione
                                    pubblica
                                    e della costruzione di una rete cittadina</strong> stipulando una convenzione
                                    ultrasessantennale con la città.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/genova-gavette/genova-gavette-1.jpg);"></div>
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Non è in realtà la<em> Union des Gaz</em> a costituire la prima fornitura
                                    industriale: dopo le prime proposte rifiutate dal comune, <strong>la<em> Società di
                                    Illuminazione</em> degli ingegneri Cole di Lione e Chevillet di Cologne aveva
                                    ottenuto nel 1844 la concessione per costruire un’officina</strong> in via Canevari.
                                    Nonostante il raddoppio degli impianti con la costruzione di una seconda officina a
                                    Sampierdarena, tuttavia, la società non riesce a reggere la crescente richiesta di
                                    fornitura e il servizio viene rilevato dalla società parigina.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/genova-gavette/genova-gavette-2.jpg);"></div>
</div>
<!--CAPITOLO 2-->
<div class="section-white" id="capitolo2">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 2</h3>
                            <h2 class="title ">L’officina alle Gavette</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    È da poco svoltato il secolo quando, <strong>nel 1906, in località Gavette viene
                                    iniziata la
                                    costruzione di una nuova officina del gas</strong>. Nel 1908 l’officina, costruita
                                    sull’ex
                                    galoppatoio dei marchesi Durazzo Pallavicini, viene inaugurata su un’area di 70000
                                    metri quadri. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/genova-gavette/genova-gavette-3.jpg);"></div>
</div>

<!--CAPITOLO 3-->
<div class="section-white" id="capitolo3">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 3</h3>
                            <h2 class="title ">Verso la municipalizzazione: gestione in economia e nascita di AMGA </h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-3">
                            <img src="assets/img/storymaps/genova-gavette/genova-gavette-4.jpg" class="img-fluid"
                                 alt="Verbali Commissione 1922">
                        </div>
                        <div class="col-md-6">

                            <div class="article-content align-middle">
                                <p class="mb-2 mt-5">
                                    Ma il nuovo secolo aveva portato anche la legge del 1903 sulla municipalizzazione
                                    dei servizi pubblici. Con l’avvicinarsi della fine della concessione, a cavallo
                                    degli anni Venti, ecco allora che <strong>il comune di Genova inizia a pensare a una
                                    gestione municipale del gas.</strong>

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <div class="article-content align-middle">
                                <p class="mb-2 mt-5">
                                    <strong>Nel 1922 il consiglio comunale decide per la rescissione consensuale dei
                                        contratti con l’<em>Union des Gaz</em></strong> e assume la proprietà e la
                                    gestione delle tre officine (via Canevari, Sampierdarena e Gavette) in consorzio con
                                    alcuni comuni limitrofi.
                                </p>
                                <p>
                                    <strong>Tra il 1922 e il 1936 il servizio è assunto dal comune "in
                                        economia"</strong>, cioè con strutture e personale comunali e con bilancio
                                    consolidato a quello del comune. <strong>Dopo questa fase pionieristica, la
                                    municipalizzazione diventa effettiva con la nascita di un’azienda dedicata: inizia
                                    l’avventura dell’AMGA.</strong>


                                </p>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <img src="assets/img/storymaps/genova-gavette/genova-gavette-5.jpg" class="img-fluid"
                                 alt="Verbali Commissione 1922">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<nav id="cd-vertical-nav">
    <ul>
        <li>
            <a data-number="1" (click)="smoothScroll('headers')">
                <span class="cd-dot"></span>
                <span class="cd-label">Introduzione</span>
            </a>
        </li>
        <li>
            <a data-number="2" (click)="smoothScroll('capitolo1')">
                <span class="cd-dot"></span>
                <span class="cd-label">La Genova del “gas francese”</span>
            </a>
        </li>
        <li>
            <a data-number="3" (click)="smoothScroll('capitolo2')">
                <span class="cd-dot"></span>
                <span class="cd-label">L’officina alle Gavette</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo3')">
                <span class="cd-dot"></span>
                <span class="cd-label">Verso la municipalizzazione</span>
            </a>
        </li>
    </ul>
</nav>