<div class="background-img" style="background-image: url('./assets/img/staticImages/404.jpg')">
    <div class="filter"></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h1 class="title"> &nbsp;404 <br/>
                    <p class="error-msg">La pagina che cercavi non è stata trovata</p>
                </h1>
                <ul class="list-group">
                    <li><a class="text-white" [routerLink]="['/']">HOME</a></li>
                    <li><a class="text-white" [routerLink]="['/esplora']">ESPLORA</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
