<div class="wrapper">
    <div class="main">
        <div class="section pb-0">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-sm-12">
                        <div class="badge-danger text-center p-3 mt-3" *ngIf="errorAlertMessage">Si è verificato un
                            errore nel caricamento dei dati. Si prega di ricaricare la pagina.<br>Se l'errore
                            persiste riprovare tra qualche minuto.
                        </div>
                    <div class="col-sm-12">
                        <h1 class="text-center mt-5">Cronologia</h1>

                        <ul class="timeline">
                            <li *ngFor="let card of returnedTree, let index=index; let odd=odd; let even=even;" style="position:relative;">
                                <span class="flag" [ngClass]="getFlagColor(card.KeyWordsResult)"></span>
                                <div class="col-md-6 col-sm-12" [ngClass]="{'direction-r' :odd , 'direction-l': even }">
                                    <a [routerLink]="['/scheda/' + card?.Id]" style="display:block;">
                                        <img *ngIf="card?.MediumUrl; else showPlaceholder"
                                             [defaultImage]="'assets/img/staticImages/imgLoading.gif'"
                                             [lazyLoad]="card?.MediumUrl"
                                             [offset]="100"
                                             class="img">
                                        <ng-template #showPlaceholder>
                                            <img class="img" src="assets/img/staticImages/placeholder.jpg">
                                        </ng-template>
                                    </a>
                                </div>
                                <div class="col-md-6 col-sm-12" [ngClass]="{'direction-r' :even , 'direction-l': odd }">
                                    <p class="time" [ngClass]="getColor(card.KeyWordsResult)">{{card.DateElement.FromYear}}</p>
                                    <p> <span *ngIf="card.DateElement.FromDay !== 0">{{card.DateElement.FromDay}}</span>
                                        {{card.DateElement.FromMonth | repeat}}
                                    </p>
                                    <div class="">
                                        <a [routerLink]="['/scheda/' + card?.Id]" style="display:block;">
                                            <h6 *ngIf="card?.OriginalTitle">{{card?.OriginalTitle}}</h6>
                                            <h6 *ngIf="!card?.OriginalTitle">{{card?.AssignedTitle}}</h6>
                                        </a>
                                        <p><small><span [innerHtml]="card?.Content"></span></small></p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="sidebar-wrapper" [ngClass]="collapsed ? 'collapsed' : ''">
                <div class="setting-btn"
                     (click)="collapsed = !collapsed">
                    <i class="fas fa-cog rotating"></i>
                </div>
                <div class="filter-block sidebar" style="position: relative">
                    <h4 class="card-title mt-0"><i class="far fa-calendar-alt  pl-2"></i> TIMELINE</h4>
                    <hr>
                    <span class="btn btn-link btn-default" (click)="startingTimeline()"
                          [class.text-success]="active === 'eventiPrincipali'">
                                <i class="fas fa-star mr-2"></i>
                                Eventi principali
                            </span> <br>
                    <span class="btn btn-link btn-default" (click)="filterCity('')"
                          [class.text-success]="active === 'tuttiGliEventi'">
                               <i class="far fa-list mr-2"></i>
                                Tutti gli eventi
                            </span>
                    <h4 class="card-title"><i class="far fa-filter  pl-2"></i> FILTRI</h4>
                    <hr>
                    <h6 class="text-muted">Filtra per città</h6>
                    <span *ngFor="let keyword of keywordList | orderBy: keywordOrder"
                          [hidden]="keyword.KeyWordType !== 'Timeline' || keyword.KeyWordValue === 'Impianti' || keyword.KeyWordValue === 'Amiat' || keyword.KeyWordValue === 'Comunicazione'">
                                 <span class="btn btn-link btn-default" (click)="filterCity(keyword.KeyWordValue)">
                                     <i class="mr-2 fal" [ngStyle]="{color: getLabelColor(keyword.KeyWordValue)}"
                                        [ngClass]="(keyword.KeyWordValue === 'Torino' || keyword.KeyWordValue ==='Reggio Emilia' ||
                                   keyword.KeyWordValue === 'Genova' || keyword.KeyWordValue === 'Parma'  || keyword.KeyWordValue === 'Piacenza') ? 'fa-city' : 'fa-tag'"> </i>
                                     {{keyword.KeyWordValue}}
                                 </span> <br>
                            </span>

                    <h6 class="mt-3 text-muted">Filtra per argomento</h6>
                    <span *ngFor="let keyword of keywordList | orderBy: keywordOrder"
                          [hidden]="keyword.KeyWordType !== 'Timeline' || keyword.KeyWordValue === 'Torino' || keyword.KeyWordValue === 'Reggio Emilia'
                                  || keyword.KeyWordValue === 'Genova'|| keyword.KeyWordValue === 'Parma' || keyword.KeyWordValue === 'Piacenza' ">
                                 <span class="btn btn-link btn-default" (click)="filterCity(keyword.KeyWordValue)">
                                     <i class="mr-2 fal" [ngStyle]="{color: getLabelColor(keyword.KeyWordValue)}"
                                        [ngClass]="(keyword.KeyWordValue === 'Torino' || keyword.KeyWordValue ==='Reggio Emilia' ||
                                   keyword.KeyWordValue === 'Genova' || keyword.KeyWordValue === 'Parma'  || keyword.KeyWordValue === 'Piacenza' ) ? 'fa-city' : 'fa-tag'"
                                        [ngStyle]="{color: getLabelColor(keyword.KeyWordValue)}"> </i>
                                     {{keyword.KeyWordValue}}
                                 </span> <br>
                            </span>

                    <span class="btn btn-link btn-default" (click)="filterCity('')">
                                <i class="fa fa-close"></i>
                                <small>Rimuovi filtri</small>
                    </span>

                </div>
            </div>
        </div>
    </div>
</div>