import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';

import {TreeModel} from '../models/tree.model';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()

export class ApiService {


    baseUrl = environment.mosaikoEndpoint;
    options = {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')};
    token: string = localStorage.getItem('token');
    rootname: string = 'WS';


    body;
    Offset;
    PageSizeResult;
    SubKeyWordValue;
    searchCriteria;

    constructor(private http: HttpClient) {
    }


    returnTree(NodeType, NodeValue): Observable<TreeModel[]> {

        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('NodeValue', NodeValue);
        body.set('NodeType', NodeType);
        this.body = body;

        return this.http.post<TreeModel[]>(this.baseUrl + '/ReturnTree', this.body.toString(), this.options);
    };

    returnScheda(NodeType: any, NodeValue: any): Observable<any> {

        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('NodeType', NodeType);
        body.set('NodeValue', NodeValue);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnScheda', this.body.toString(), this.options);
    }


    returnSchedaReference(NodeValue) {
        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('NodeType', 'UA');
        body.set('NodeValue', NodeValue);
        body.set('Offset', '0');
        body.set('PageSizeResults', '100');


        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnSchedaReferences', this.body.toString(), this.options);
    }


    ReturnKeyWordsSearchResults(TagType, TagDescription, SubKeyWordValue?, Offset?, PageSizeResult?) {

        if (!PageSizeResult) {
            this.PageSizeResult = 10;
        } else {
            this.PageSizeResult = PageSizeResult;
        }
        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('KeyWordType', TagType);
        body.set('KeyWordValue', TagDescription);
        body.set('SubKeyWordValue', SubKeyWordValue);
        body.set('Offset', Offset);
        body.set('PageSizeResults', this.PageSizeResult);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnKeyWordsSearchResults', this.body.toString(), this.options);

    }

    ReturnIndexSearchResults(IndexType, IndexValue, Offset?, PageSizeResult?): Observable<any> {
        if (!PageSizeResult) {
            this.PageSizeResult = 10;
        } else {
            this.PageSizeResult = PageSizeResult;
        }
        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('IndexType', IndexType);
        body.set('IndexValue', IndexValue);
        body.set('Offset', Offset);
        body.set('PageSizeResults', this.PageSizeResult);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnIndexSearchResults', this.body.toString(), this.options);

    }

    ReturnKeyWordsList(): Observable<any> {
        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnKeyWordsList', this.body.toString(), this.options);
    }

    ReturnIndexList(): Observable<any> {
        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnIndexList', this.body.toString(), this.options);
    }


    ReturnSearchCriteriaResults(searchCriteria, searchContext, Offset?, PageSizeResult?): Observable<any> {

        if (!PageSizeResult) {
            this.PageSizeResult = 10;
        } else {
            this.PageSizeResult = PageSizeResult;
        }


        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('SearchCriteria', searchCriteria);
        body.set('SearchContext', searchContext);
        body.set('Offset', Offset);
        body.set('PageSizeResults', this.PageSizeResult);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnSearchCriteriaResults', this.body.toString(), this.options);

    }


    ReturnDocumentTypeList() {
        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnDocumentTypeList', this.body.toString(), this.options);
    }


    ReturnDocumentTypeSearchResults(DocumentType, Offset?, PageSizeResult?): Observable<any> {


        if (!PageSizeResult) {
            this.PageSizeResult = 10;
        } else {
            this.PageSizeResult = PageSizeResult;
        }

        const body = new URLSearchParams();
        body.set('token', this.token);
        body.set('rootname', this.rootname);
        body.set('DocumentTypeValue', DocumentType);
        body.set('Offset', Offset);
        body.set('PageSizeResults', this.PageSizeResult);

        this.body = body;
        return this.http.post(this.baseUrl + '/ReturnDocumentTypeSearchResults', this.body.toString(), this.options);
    }


}
