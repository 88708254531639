<div class="wrapper">
  <div class="main">
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="badge-danger text-center p-3" *ngIf="errorAlertMessage">
            Si è verificato un errore nel caricamento dei dati. Si prega di
            ricaricare la pagina.<br />Se l'errore persiste riprovare tra
            qualche minuto.
          </div>
          <div class="col-md-7 card-content">
            <h2>{{ returnedScheda?.AssignedTitle }}</h2>

            <h5>
              <em>
                <ul class="serie">
                  <li
                    *ngFor="
                      let serie of returnedScheda?.ParentTree;
                      let last = last;
                      let i = index
                    "
                  >
                    <a
                      class="linkSerie"
                      [routerLink]="['/esplora/' + serie.Id]"
                    >
                      {{ serie.AssignedTitle }}
                    </a>
                  </li>
                  <li>{{ returnedScheda?.AssignedTitle }}</li>
                </ul>
              </em>
            </h5>
            <p [innerHtml]="returnedScheda?.Content"></p>
            <p>
              <small
                ><em>{{ returnedScheda?.Notes1 }}</em></small
              >
            </p>
            <div class="mt-3 mb-3">
              <div
                *ngIf="returnedScheda?.Notes2"
                [innerHtml]="yt_iframe_html"
              ></div>
            </div>

            <div class="row card-details" *ngIf="returnedScheda?.Keywords">
              <div class="col-sm-12 card-tags">
                <h3><i class="nc-icon nc-tag-content"></i> Tag</h3>
                <div class="wrapper-tags">
                  <span
                    *ngFor="let tag of returnedScheda?.Keywords"
                    class="mr-2"
                  >
                    <a
                      *ngIf="
                        tag.Type !== 'Timeline' &&
                        tag.Type !== 'TimelineIniziale' &&
                        tag.Type !== 'Storymap'
                      "
                      [routerLink]="['/ricerca/risultati']"
                      [queryParams]="{
                        searchType: 'Keyword',
                        type: 'Tag',
                        value: tag.Description
                      }"
                      class="label label-success"
                    >
                      {{ tag.Description }}
                    </a>
                  </span>
                </div>
              </div>
              <div
                class="col-sm-12 card-tags"
                *ngIf="returnedScheda?.IndexList"
              >
                <h3><i class="nc-icon nc-tag-content"></i> Indici</h3>
                <div class="wrapper-tags">
                  <a
                    *ngFor="let indici of returnedScheda?.IndexList"
                    [routerLink]="['/ricerca/risultati']"
                    [queryParams]="{
                      searchType: 'Index',
                      type: indici.Type,
                      value: indici.Description
                    }"
                    class="btn btn-link btn-default pl-0"
                  >
                    <i
                      *ngIf="indici.Type === 'Ente'"
                      class=" fa fa-building-o"
                    ></i>
                    <i
                      *ngIf="indici.Type === 'Luogo'"
                      class=" fa fa-map-marker"
                    ></i>
                    <i
                      *ngIf="indici.Type === 'Persona'"
                      class=" fa fa-male"
                    ></i>
                    {{ indici.Description }}
                  </a>
                </div>
              </div>
            </div>
            <div class="row card-details " *ngIf="showDetails()">
              <div class="col-sm-12">
                <h3><i class="nc-icon nc-alert-circle-i"></i> Dettagli</h3>
                <table class="table-details">
                  <tr *ngIf="returnedScheda?.OriginalTitle">
                    <td width="60%">Titolo originale</td>
                    <td>{{ returnedScheda?.OriginalTitle }}</td>
                  </tr>
                  <!--<tr *ngIf="returnedScheda?.SignaturePrefix || returnedScheda?.SignatureNumber || returnedScheda?.Signature">
                                        <td>Antica Segnatura</td>
                                        <td>
                                            {{returnedScheda?.SignaturePrefix}}
                                            <span *ngIf="returnedScheda?.SignaturePrefix">&nbsp;</span>{{returnedScheda?.SignatureNumber}}
                                            <span *ngIf="returnedScheda?.SignatureNumber">&nbsp;</span>-
                                            {{returnedScheda?.Signature}}
                                        </td>
                                    </tr>-->
                  <tr *ngIf="returnedScheda?.DateElement.Label">
                    <td *ngIf="returnedScheda?.DateElement.ToYear != '0'">
                      Estremi Cronologici
                    </td>
                    <td *ngIf="returnedScheda?.DateElement.ToYear == '0'">
                      Data
                    </td>
                    <td>
                      <span
                        *ngIf="
                          returnedScheda?.DateElement.FromDay ||
                          returnedScheda?.DateElement.FromDay != '0'
                        "
                        >{{ returnedScheda?.DateElement.FromDay }}/</span
                      ><span
                        *ngIf="
                          returnedScheda?.DateElement.FromMonth ||
                          returnedScheda?.DateElement.FromMonth != '0'
                        "
                        >{{ returnedScheda?.DateElement.FromMonth }}/</span
                      >{{ returnedScheda?.DateElement.FromYear }}
                      <span
                        *ngIf="
                          returnedScheda?.DateElement.ToYear &&
                          returnedScheda?.DateElement.ToYear != '0'
                        "
                      >
                        -
                      </span>
                      <span
                        *ngIf="
                          returnedScheda?.DateElement.ToDay ||
                          returnedScheda?.DateElement.ToDay != '0'
                        "
                        >{{ returnedScheda?.DateElement.ToDay }}/</span
                      ><span
                        *ngIf="
                          returnedScheda?.DateElement.ToMonth ||
                          returnedScheda?.DateElement.ToMonth != '0'
                        "
                        >{{ returnedScheda?.DateElement.ToMonth }}/</span
                      ><span
                        *ngIf="
                          returnedScheda?.DateElement.ToYear &&
                          returnedScheda?.DateElement.ToYear != '0'
                        "
                        >{{ returnedScheda?.DateElement.ToYear }}</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.TopicDate">
                    <td>Data Topica</td>
                    <td>{{ returnedScheda?.TopicDate }}</td>
                  </tr>
                  <tr *ngIf="returnedScheda?.CenturySpecification">
                    <td>Secolo</td>
                    <td>
                      {{ returnedScheda?.CenturySpecification }}
                      <span *ngIf="returnedScheda?.DateClarification">
                        - {{ returnedScheda?.DateClarification }}</span
                      >
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.PreciseDate">
                    <td>Data</td>
                    <td>{{ returnedScheda?.PreciseDate }}</td>
                  </tr>
                  <tr
                    *ngIf="
                      returnedScheda?.ConservationState &&
                      !returnedScheda?.Notes2
                    "
                  >
                    <td>Stato di conservazione</td>
                    <td>{{ returnedScheda?.ConservationState }}</td>
                  </tr>
                  <tr *ngIf="returnedScheda?.DocumentType">
                    <td>Tipologia</td>
                    <td>{{ returnedScheda?.DocumentType }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row card-details" *ngIf="returnedScheda?.Aggregation">
              <div class="col-sm-12">
                <h3>
                  <i class="nc-icon nc-alert-circle-i"></i> Livello di descrizione:  <span style="text-transform: lowercase !important;"><span style="text-transform: capitalize">{{returnedScheda?.Aggregation}}</span></span>
                </h3>
              </div>
            </div>

            <div class="row card-details" *ngIf="returnedScheda?.SchedaDetail">
              <div class="col-sm-12">
                <h3>
                  <i class="nc-icon nc-alert-circle-i"></i> Descrizione tecnica
                </h3>
                <table class="table-details">
                  <tr width="60%" *ngIf="returnedScheda?.SchedaDetail.Field1">
                    <td>Oggetto</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field1 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field2">
                    <td>Quantità</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field2 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field3">
                    <td>Cronologia</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field3 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field4">
                    <td>Soggetto</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field4 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field5">
                    <td>Materia e tecnica</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field5 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field6">
                    <td>Misure</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field6 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field7">
                    <td>Stato di conservazione</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field7 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field8">
                    <td>Fonti e documenti di riferimento</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field8 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field9">
                    <td>Note</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field9 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field10">
                    <td>Oggetto</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field10 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field11">
                    <td>Quantità</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field11 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field12">
                    <td>Soggetto</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field12 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field13">
                    <td>Titolo</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field13 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field14">
                    <td>Cronologia</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field14 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field15">
                    <td>Ambito Culturale</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field15 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field16">
                    <td>Autore</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field16 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field17">
                    <td>Colore</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field17 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field18">
                    <td>Materia e tecnica</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field18 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field19">
                    <td>Misure</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field19 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field20">
                    <td>Stato di conservazione</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field20 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field21">
                    <td>Fonti e documenti di riferimento</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field21 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field22">
                    <td>Note</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field22 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field25">
                    <td>Oggetto</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field25 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field26">
                    <td>Tipologia</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field26 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field27">
                    <td>Cronologia</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field27 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field28">
                    <td>Quantità</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field28 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field29">
                    <td>Materia e tecnica</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field29 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field30">
                    <td>Misure</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field30 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field31">
                    <td>Stato di conservazione</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field31 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field32">
                    <td>Fonti e documenti di riferimento</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field32 }}
                    </td>
                  </tr>
                  <tr *ngIf="returnedScheda?.SchedaDetail.Field33">
                    <td>Note</td>
                    <td>
                      {{ returnedScheda?.SchedaDetail.Field33 }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-5" *ngIf="!hideGallery">
            <ngx-gallery
              [options]="galleryOptions"
              [images]="galleryImages"
            ></ngx-gallery>
          </div>
          <div class="col-md-5" *ngIf="hasPDF">
            <div class="col-sm-12 text-center">
              <h3 class="mb-4">
                <i class="nc-icon nc-single-copy-04"></i>Allegati
              </h3>
              <img
                [defaultImage]="'assets/img/staticImages/imgLoading.gif'"
                [lazyLoad]="previewImage"
                [offset]="100"
                class="img-fluid previewImage"
                [alt]="returnedScheda?.AssignedTitle"
                *ngIf="hasPDF === true"
              />
              <div class="text-center p-5" *ngIf="hasPDF === true">
                <button
                  *ngIf="hasPDF === true"
                  class="btn btn-round btn-outline-warning"
                  (click)="open(classic, 'lg')"
                >
                  <i class="fal fa-expand-alt mr-2"></i> VISUALIZZA DOCUMENTO
                </button>
              </div>

              <ng-template #classic let-c="close" let-d="dismiss">
                <ngx-extended-pdf-viewer
                  [src]="pdfUrl"
                  useBrowserLocale="true"
                  [showFindButton]="false"
                  [showOpenFileButton]="false"
                  [showPrintButton]="false"
                  [showDownloadButton]="false"
                  [showBookmarkButton]="false"
                  [showPropertiesButton]="false"
                  [zoom]="'page-fit'"
                  height="80vh"
                ></ngx-extended-pdf-viewer>
              </ng-template>

              <!--ng-template #classic let-c="close" let-d="dismiss">
                                <div class="modal-header">
                                    <h5 class="modal-title text-center">
                                        {{returnedScheda?.AssignedTitle}}</h5>
                                    <button type="button" class="close" (click)="d('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-footer">
                                    <div class="left-side">
                                        <h5>Zoom </h5>

                                        <button class="btn btn-outline-default btn-just-icon mr-2" type="button"
                                                (click)="zoom('-')">
                                            <i aria-hidden="true" class="fa fa-search-minus"></i>
                                        </button>
                                        <button class="btn btn-outline-default btn-just-icon " type="button"
                                                (click)="zoom('+')">
                                            <i aria-hidden="true" class="fa fa-search-plus "></i>
                                        </button>
                                    </div>
                                    <div class="divider"></div>
                                    <div class="right-side">
                                        <button type="button" class="btn btn-danger btn-link"
                                                (click)="c('Close click')"><i class="fa fa-times-circle-o"></i> Chiudi
                                        </button>
                                    </div>
                                </div>
                                <div class="modal-body">
                                    <div class="text-center p-5" *ngIf="loadingPDF">
                                        <p>Caricamento PDF</p>
                                        <div class="uil-reload-css reload-background">
                                            <div></div>

                                        </div>
                                    </div>
                                    <pdf-viewer [src]="pdfUrl"
                                                [original-size]="true"
                                                [fit-to-page]="true"
                                                [zoom]="pdfZoom"
                                                style="display: block;"
                                                (after-load-complete)="callBackFn($event,'modal')"
                                    ></pdf-viewer>
                                </div>

                            </ng-template-->
            </div>
          </div>
        </div>

        <!--CHILDREN (only for SL with content-->
        <div class="row card-children" *ngIf="returnedTree?.length > 0">
          <div class="col-sm-12 mb-4">
            <h3><i class="nc-icon nc-single-copy-04"></i> {{returnedScheda?.Aggregation}}</h3>
          </div>
            <app-card-square
              class="col-sm-6 col-md-4 col-lg-3"
              [cardPreview]="card"
              *ngFor="let card of returnedTree; let i = index"
            >
            </app-card-square>
        </div>

        <!--REFERENCES-->
        <div class="row card-details" *ngIf="returnedScheda?.References">
        <div class="col-sm-12 mb-4">
            <h3>
            <i class="nc-icon nc-single-copy-04"></i> Schede correlate
            </h3>
        </div>
        <app-card-square
            class="col-sm-4 col-lg-3"
            [cardPreview]="card"
            *ngFor="let card of references; let i = index"
        >
        </app-card-square>
        </div>
        
      </div>
    </div>
  </div>
</div>
