<div class="section section-footers">
    <div class="container tim-container">
        <div class="title" id="footers">
            <h3>Footers</h3>
        </div>
    </div>
</div>
<footer class="footer footer-big">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-9 ml-auto mr-auto">
                <div class="links">
                    <ul class="uppercase-links">
                        <li>
                            <a href="#paper-kit">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                                Company
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                                Portfolio
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                               Team
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                               Blog
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                               Contact
                            </a>
                        </li>


                    </ul>
                    <hr />

                    <div class="copyright">
                        © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-2">
                <div class="social-area">
                    <a href="#facebook" class="btn btn-neutral btn-round btn-just-icon btn-facebook facebook-sharrre">
                       <i class="fa fa-facebook" aria-hidden="true"></i>
                   </a>
                    <a href="#twitter" class="btn btn-neutral btn-just-icon btn-round btn-twitter twitter-sharrre">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#google" class="btn btn-neutral btn-just-icon btn-round btn-google google-sharrre">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                    <a href="#pin" class="btn btn-neutral btn-just-icon btn-round btn-pinterest pinterest-sharrre">
                        <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="separator"></div>
<footer class="footer footer-black footer-big">

    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-4 ml-auto mr-auto">
                <div class="logo text-center">
                    <h3>Creative Tim</h3>
                </div>
            </div>
            <div class="col-md-6 offset-md-2 col-sm-8">
                <div class="links">
                    <ul>
                        <li>
                            <a href="#paper-kit">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                                Company
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                                Portfolio
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                               Team
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                               Contact
                            </a>
                        </li>
                        <li>
                            <a href="#paper-kit">
                               We're Hiring!
                            </a>
                        </li>
                    </ul>
                    <hr />
                    <div class="copyright">
                        <div class="pull-left">
                            © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> in Bucharest
                        </div>
                        <div class="pull-right">
                            <ul>
                                <li>
                                    <a href="#paper-kit">
                                        Terms
                                    </a>
                                </li>
                                |
                                <li>
                                    <a href="#paper-kit">
                                        Privacy
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>
<div class="separator"></div>
<footer class="footer footer-big">

    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-12 ml-auto mr-auto">
                 <div class="form-group">
                    <angular2-multiselect [data]="dropdownList" [(ngModel)]="selectedItems"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)"
                        (onDeSelect)="OnItemDeSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeSelectAll($event)">
                    </angular2-multiselect>
                </div>
                 <div class="form-group">
                     <angular2-multiselect [data]="dropdownList1" [(ngModel)]="selectedItems1"
                         [settings]="dropdownSettings1"
                         (onSelect)="onItemSelect($event)"
                         (onDeSelect)="OnItemDeSelect($event)"
                         (onSelectAll)="onSelectAll($event)"
                         (onDeSelectAll)="onDeSelectAll($event)">
                     </angular2-multiselect>
                </div>
            </div>
            <div class="col-md-9 ml-auto mr-auto col-sm-9 col-12">
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="uppercase-links stacked-links">
                                <li>
                                    <a href="#paper-kit" >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                        Discover
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                        Blog
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="uppercase-links stacked-links">
                                <li>
                                    <a href="#paper-kit">
                                       Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       We're Hiring
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       About Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="uppercase-links stacked-links">
                                <li>
                                    <a href="#paper-kit">
                                        Portfolio
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       How it works
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       Testimonials
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="social-area">
                            <a class="btn btn-just-icon btn-round btn-facebook">
                                <i class="fa fa-facebook" aria-hidden="true"></i>
                            </a>
                            <a class="btn btn-just-icon btn-round btn-twitter">
                                <i class="fa fa-twitter" aria-hidden="true"></i>
                            </a>
                            <a class="btn btn-just-icon btn-round btn-google">
                                <i class="fa fa-google-plus" aria-hidden="true"></i>
                            </a>
                            <a class="btn btn-just-icon btn-round btn-pinterest">
                                <i class="fa fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="copyright">
                    <div class="pull-left">
                        © {{data | date: 'yyyy'}} Creative Tim, made with love
                    </div>
                    <div class="links pull-right">
                        <ul>
                            <li>
                                <a href="#paper-kit">
                                    Company Policy
                                </a>
                            </li>
                            |
                            <li>
                                <a href="#paper-kit">
                                    Terms
                                </a>
                            </li>
                            |
                            <li>
                                <a href="#paper-kit">
                                    Privacy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>

<div class="separator"></div>
<footer class="footer footer-black footer-big">
    <div class="container">
        <div class="row">
            <div class="col-md-2 text-center col-sm-3 col-12 ml-auto mr-auto">
                <h4>Creative Tim</h4>
                <div class="social-area">
                    <a class="btn btn-just-icon btn-round btn-facebook">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a class="btn btn-just-icon btn-round btn-twitter">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a class="btn btn-just-icon btn-round btn-google">
                        <i class="fa fa-google-plus" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
            <div class="col-md-9 col-sm-9 col-12 ml-auto mr-auto">
                <div class="row">
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="uppercase-links stacked-links">
                                <li>
                                    <a href="#paper-kit" >
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                        Discover
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                        Live Support
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                        Money Back
                                    </a>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="uppercase-links stacked-links">
                                <li>
                                    <a href="#paper-kit">
                                       Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       We're Hiring
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       About Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="uppercase-links stacked-links">
                                <li>
                                    <a href="#paper-kit">
                                        Portfolio
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       How it works
                                    </a>
                                </li>
                                <li>
                                    <a href="#paper-kit">
                                       Testimonials
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-3 col-6">
                        <div class="links">
                            <ul class="stacked-links">
                                <li>
                                    <h4>13.723<br /> <small>accounts</small></h4>
                                </li>
                                <li>
                                    <h4>55.234<br /> <small>downloads</small></h4>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="copyright">
                    <div class="pull-left">
                        &copy; {{data | date: 'yyyy'}} Creative Tim, made with love
                    </div>
                    <div class="links pull-right">
                        <ul>
                            <li>
                                <a href="#paper-kit">
                                    Company Policy
                                </a>
                            </li>
                            |
                            <li>
                                <a href="#paper-kit">
                                    Terms
                                </a>
                            </li>
                            |
                            <li>
                                <a href="#paper-kit">
                                    Privacy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</footer>
