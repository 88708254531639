import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import * as _ from 'lodash';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-academy',
    templateUrl: './academy.component.html',
    styleUrls: ['./academy.component.scss']
})
export class AcademyComponent implements OnInit {

    bookId;
    books;
    openedBook;
    activeTab = 'abstract';
    openedSaggio;
    environment = environment;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private http: HttpClient) {
    }

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            this.bookId = paramMap.get('bookId');
            this.http.get(environment.endpoint + '/assets/json/academy.json').subscribe(books => {
                this.books = books;
                console.log(this.books);
                if (this.bookId) {
                    console.log('this.bookId', this.bookId);
                    this.openedBook = _.filter(this.books, {'id': +this.bookId})[0];
                    console.log('opened', this.openedBook);
                    if (!this.openedBook) {
                        this.bookId = '';
                    }
                } else {
                    console.log('nessun ID')
                }
            });
        })
    }

    switchTab(tab) {
        this.activeTab = tab
    }

    openSaggio(saggioId){
        this.activeTab = 'abstract';
        this.openedSaggio = _.filter(this.openedBook.saggi, {'id': +saggioId})[0];

    }

}
