import { Component, OnInit } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent implements OnInit {
    cmsEndpoint = environment.cmsEndpoint;
    imgEndpoint = environment.imgEndpoint;
    contentList;
  constructor(private http: HttpClient) { }

  ngOnInit() {
      this.http.get<any>(this.cmsEndpoint + '/contents/documenti+foto+interviste+video?limit=1000&offset=0').subscribe(res => {
          this.contentList = res.data;
          console.log(this.contentList);
      })
  }

}
