<div class="wrapper">
  <div class="main">

    <div class="projects-2 section ">

      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">

            <h2 class="title">In Evidenza</h2>

          </div>
        </div>
        <div class="space-top"></div>
        <div class="row">
          <div class="col-md-4 text-center" *ngFor="let content of contentList">
            <div class="card card-plain">
              <div class="card-img-top">
                <a [href]="content.link">
                  <img class="img" [src]="imgEndpoint + '/' + content.featured_image"/>
                </a>
              </div>
              <div class="card-body">
                <h6 class="card-category text-muted">{{content?.type.alias | replaceDash}}</h6>
                <a [href]="content.link"><h4 class="card-title">{{content?.title}}</h4></a>

                <p class="card-description">
                  {{content?.abstract}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>