<div class="wrapper">
    <div class="page-header section-dark"
         style="background-image: url('./assets/img/staticImages/home-featured-2.jpg')">
        <div class="filter"></div>
        <div class="content-center">
            <div class="container">
                <div class="motto">
                    <h1 class="title">Storia e storie di Iren</h1>
                    <h3 class="description">Il portale dell'archivio storico del Gruppo Iren</h3>
                    <br/>
                    <span class="btn btn-neutral btn-round videoBtn mr-4" (click)="open(classic, 'lg')"><i
                            class="fa fa-play mr-2"></i>Guarda il video</span>
                    <a [routerLink]="['/ricerca']" class="btn btn-outline-neutral btn-round"><i
                            class="far fa-search mr-2"></i> Ricerca</a>
                </div>
            </div>
        </div>
    </div>

    <!--IL PROGETTO-->
    <div class="section">
        <div class="container">

            <div class="row">
                <div class="col-md-5 ">
                    <img src="assets/img/presentation-page/il-progetto.jpg" alt="" class="img-fluid image-rounded">

                </div>
                <div class="col-md-6 col-md-push-1 mr-auto">
                    <div class="card card-plain">
                        <h3 class="card-title mb-1">Il progetto</h3>
                        <h6 class="category mb-5">Riscoprire il passato per raccontare il futuro</h6>
                        <h5 class="description">L’archivio storico del Gruppo Iren rappresenta un patrimonio culturale
                            di grande valore e testimonia più di un secolo di storia italiana. Attraverso i documenti
                            d’archivio, attraverso le fonti grafiche, fotografiche e video, attraverso le fonti orali,
                            il progetto Iren Storia intende far emergere la storia del Gruppo insieme a tutte le storie
                            che l’hanno caratterizzata nel tempo.</h5>
                        <div class="row mt-3">
                            <div class="col-sm-12">
                                <a class="btn btn-outline-primary btn-round mr-5" [routerLink]="['/progetto']"> Scopri
                                    di più</a>
                                <a class="btn btn-outline-primary btn-round" [routerLink]="['/esplora']"> Esplora
                                    l'archivio</a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>

        <!--   <div class="row">
               <div class="col-sm-12">

                   <h2 class="title text-center mb-2 pb-0">Il Progetto</h2>
                   <h6 class="category text-center mb-5">Riscoprire il passato per raccontare il futuro</h6>

               </div>


               <div class="col-md-6">
                   <div class="card card-plain card-blog text-center">
                       <div class="card-image"><img class="img img-raised"
                                                    src="./assets/img/staticImages/home-progetto.png">
                       </div>
                       <div class="card-body">

                           <p class="card-description"> L’archivio storico del Gruppo Iren rappresenta un patrimonio
                               culturale di grande valore e testimonia più di un secolo di storia italiana. Un patrimonio che racconta la rete
                               diffusa dei servizi: la rete idrica, la rete del gas, la rete dell’illuminazione, la rete della
                               raccolta rifiuti. Ma anche una rete intesa in senso ampio, che si snoda in maniera
                               capillare sui territori e che della rete ha tutte le caratteristiche: peculiarità, diffusione,
                               nodi, sviluppo.
                               Una rete storica nella quale affondano le radici della rete futura che stiamo
                               costruendo</p><br></div>
                   </div>
               </div>
               <div class="col-md-6">
                   <div class="card card-plain card-blog text-center">
                       <div class="card-image"><img class="img img-raised"
                                                    src="./assets/img/staticImages/home-esplora.png">
                       </div>
                       <div class="card-body">

                           <p class="card-description"> Attraverso i documenti d’archivio, attraverso le fonti
                               grafiche, fotografiche e video,
                               attraverso le fonti orali, il progetto Iren Storia intende far emergere la storia del
                               Gruppo
                               Iren, da considerare insieme a tutte le storie che l’hanno caratterizzata nel tempo e
                               che,
                               con il contributo di molti e il sacrificio di tutti, hanno portato alle persone un
                               cambiamento di vita e un benessere comune. </p><br></div>
                   </div>
               </div>

           </div>-->


    </div>


    <!--TIMELINE-->

    <!--    <h2 class=" text-center info-title mb-4"> Le grandi tappe </h2>-->
    <div class="d-block d-sm-none">
        <div id='timeline-embed' style="width: 100%; height: 600px"></div>
    </div>
    <section class="d-none d-sm-block">
        <div class="section section-content timeline-home">
            <h3 class="description text-white">Scorri un secolo di Iren Storia</h3>
            <img src="https://www.irenstoria.it/timeline_home/iren-storia-logo-white.png" alt=""> <br>
            <a [routerLink]="['/un-secolo-di-storia']" class="btn btn-outline-neutral btn-round mt-5">Visualizza la
                timeline</a>
        </div>
    </section>

    <!-- SCOPRI LE STORIE -->


    <div id="fadeInAnim">
        <div class="section section-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <div class="image-container">
                            <img class="img" src="./assets/img/presentation-page/iPad-content-2.png"/>
                            <img class="area-img add-animation" src="./assets/img/presentation-page/ipad-left-img.JPG"/>
                            <img class="info-img add-animation"
                                 src="./assets/img/presentation-page/ipad-right-img.JPG"/>
                        </div>
                    </div>

                    <div class="col-md-4 ">
                        <div class="section-description">
                            <h3 class="title">I percorsi</h3>
                            <h6 class="category">Scopri le storie di Iren</h6>
                            <h5 class="description">La grande storia di Iren è l’insieme di tante storie particolari.
                                <br>
                                Attraverso le <strong>storymap</strong> - un nuovo modo per navigare tra i documenti
                                attraverso uno
                                storytelling digitale – scopri le storie che hanno reso grande Iren e portato servizi ai
                                territori. </h5>
                            <a [routerLink]="['/percorsi']" class="btn btn-outline-primary btn-round mt-3">Guarda i
                                percorsi</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <!--IN EVIDENZA-->
    <div class="projects-2 section section-gray">

        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <!--<h6 class="text-muted">Our work</h6>-->
                    <h2 class="title">In Evidenza</h2>
                    <!-- <h5 class="description">This is the paragraph where you can write more details about your projects. Keep you user engaged by providing meaningful information.</h5>-->
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-4 text-center" *ngFor="let content of contentList">
                    <div class="card card-plain">
                        <div class="card-img-top">
                            <a [href]="content.link" [target]="content.link_target">
                                <img class="img" [src]="imgEndpoint + '/' + content.featured_image"/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category text-muted">{{content?.type.alias | replaceDash}}</h6>
                            <a [href]="content.link" [target]="content.link_target"><h4 class="card-title">
                                {{content?.title}}</h4></a>

                            <p class="card-description">
                                {{content?.abstract}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--NEWS-->
    <div class="projects-2 section section-dark">

        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto text-center">
                    <h2 class="title">Le novità di IrenStoria</h2>
                </div>
            </div>
            <div class="space-top"></div>
            <div class="row">
                <div class="col-md-4 text-center" *ngFor="let news of newsList | orderBy: order:true">
                    <div class="card card-plain">
                        <div class="card-img-top">
                            <a [routerLink]="['/news/' + news?.type.alias +'/' +  news?.alias]">
                                <img class="img" [src]="imgEndpoint + '/' + news.featured_image"/>
                            </a>
                        </div>
                        <div class="card-body">
                            <h6 class="card-category text-muted">{{news?.type.alias | replaceDash}}</h6>
                            <a [routerLink]="['/news/' + news?.type.alias +'/' +  news?.alias]"><h4 class="card-title">
                                {{news?.title}}</h4></a>

                            <p class="card-description">
                                {{news?.abstract}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 text-center">
                    <a [routerLink]="['/news']" class="btn btn-outline-neutral btn-round">Scopri tutte le novità</a>
                </div>
            </div>
        </div>
    </div>


    <!--PARTECIPA-->

    <div class="section section-components section-gray">
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <div class="image-container">
                    <img class="components-macbook" src="./assets/img/presentation-page/laptop-basic.png" alt=""/>
                    <img class="table-img" src="./assets/img/presentation-page/table.jpg" alt=""/>
                    <img class="share-btn-img" src="./assets/img/presentation-page/share-btn.png" alt=""/>
                    <img class="coloured-card-btn-img" src="./assets/img/presentation-page/coloured-card-with-btn.png"
                         alt=""/>
                    <img class="coloured-card-img" src="./assets/img/presentation-page/coloured-card.jpg" alt=""/>
                    <img class="social-img" src="./assets/img/presentation-page/social-row.jpg" alt=""/>
                    <img class="pin-btn-img" src="./assets/img/presentation-page/pin-btn.png" alt=""/>
                </div>
            </div>
            <div class="col-md-10 col-lg-4 ml-auto mr-auto">
                <div class="container basic-container">
                    <h3 class="title">Partecipa</h3>
                    <h6 class="category">Hai del materiale sulla storia di Iren?</h6>
                    <h5 class="description">Il progetto Iren Storia è un progetto che nasce determinato dalla volontà di
                        recuperare la storia che ci è stata consegnata e che, inevitabilmente, costituisce il nostro
                        presente. Per questo è un progetto partecipativo aperto. Puoi collaborare con noi in tanti modi,
                        condividendo materiali o raccontando la tua storia.
                    </h5>
                    <a [routerLink]="['/partecipa']" class="btn btn-outline-primary btn-round mt-3">Scopri di più</a>
                </div>
            </div>
        </div>
    </div>

    <!--  <div class="section section-components bg-primary">
          <div class="container">
              <div class="row">
                  <div class="col-sm-12 text-center text-white">
                      <h2 class="text-white mb-2">Hai del materiale sulla storia di Iren?</h2>
                      <p>Stiamo raccogliendo foto, video, immagini che riguardano la storia di Iren</p>
                      <p>Inviaci i tuoi materiali e li pubblicheremo sul sito di Iren Storia.</p>
                      <p><strong class="text-white">Scrivici a email@iren.it</strong></p>
                  </div>
              </div>
          </div>
      </div>-->
</div>


<ng-template #classic let-c="close" let-d="dismiss">
    <div class="modal-body">
        <button type="button" class="close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="video-responsive">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/LbUYMzFDf7I" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div>
    </div>

</ng-template>
