<footer>
    <div class="container-fluid">
        <div class="row">
            <div class=" mainLinks col-md-9 col-sm-9 col-xs-12">
                <div class="iren-footer-links">
                    <a href="https://www.gruppoiren.it/"
                       target="_blank">Gruppo iren</a>
                    <a href="https://www.gruppoiren.it/sedi-e-contatti">
                    contatti</a>
                    <a href="assets/IRENStoria_InformativaCookie.pdf" target="_blank">
                        privacy e cookie policy</a>
                </div>
            </div>
            <!--<div class="col-md-3 col-sm-3 col-xs-12"><a
                    class=" btn btn-neutral btn-round pull-right btn full-white blue"
                    target="_blank"
                    href="https://www.ireninforma.it/iscrizione-newsletter">Iscriviti
                alla newsletter</a></div>-->
        </div>
        <div class="row">
            <div class="disclaimer col-md-6">
                <div class="iren-footer-text hidden-xs">Tutti i diritti riservati © 2010-2019 Iren S.p.A. <br>Via Nubi
                    di Magellano, 30 - 42123 Reggio Emilia <br> <a href="mailto:irenspa@pec.gruppoiren.it">irenspa@pec.gruppoiren.it</a>
                    <br>
                    Capitale sociale I.V. 1.300.931.377,00 euro - Codice fiscale/Partita IVA: 07129470014 - <a
                            href="https://www.heritage-srl.it" target="_blank">Credits IrenStoria</a></div>
                <!--TODO: cambiare credits-->
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</footer>


