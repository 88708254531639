import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-card-square',
    templateUrl: './card-square.component.html',
    styleUrls: ['./card-square.component.scss']
})
export class CardSquareComponent implements OnInit {


    link;
    @Input() cardPreview: any;


    constructor() {
    }

    ngOnInit() {

        /*if (this.cardPreview.ChildrenCount.SL === 0 && this.cardPreview.ChildrenCount.UA === 0) {
            this.link = '/scheda/';
        } else {
            this.link = '/esplora/';
        }*/
        if (this.cardPreview.Type === 'UA') {
            this.link = '/scheda/';
        } else {
            this.link = '/esplora/';
        }

    }

}
