<div class="wrapper">
    <div class="section landing-section mb-0">
        <div class="container">
            <div class="row">
                <div class="col-md-7"><h1>{{news?.titolo}}</h1>
                    <h2 class="mb-2">{{news?.sottotitolo}}</h2>
                    <p class="data"><em>{{news?.data}}</em></p></div>
                <div class="col-md-3 col-md-push-1"></div>
            </div>
            <div class="row news">

                <div class="col-md-7 mb-5">
                    <div [innerHtml]="news?.testo"></div>
                    <!--<div class='mt-5'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/hzw7e3GwAmU" frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>-->
                </div>
                <div class="col-sm-1"></div>
                <div class="col-md-4"><img *ngFor="let img of news?.immagini" [src]="'./assets/img/news/'+img"
                                           class="img-fluid mb-2" alt="">
                </div>
            </div>
        </div>
    </div>
</div>