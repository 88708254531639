<div class="wrapper">
    <div class="main">
        <div class="section section-gray mb-0 pb-0 bg-search-image">
            <div class="container-fluid">
                <div class="row related-items">
                    <div class="col-sm-12 pt-4">
                        <div class="row">
                            <div class="col-sm-12 text-white">
                                <h2 class="text-center mt-5">Ricerca nell'archivio</h2>
                                <p class="text-center">Benvenuto nella sezione di ricerca di <strong>Iren
                                    Storia</strong></p>
                            </div>
                        </div>
                        <!--RICERCA SEMPLICE-->
                        <form [formGroup]="ricercaSemplice" (ngSubmit)="onSubmitRicercaSemplice()">
                            <div class="row justify-content-md-center mb-5 text-white">
                                <div class="col-sm-12 text-center">
                                    <h4 class="mb-3">Ricerca semplice</h4>
                                </div>
                                <div class="col-md-5">

                                    <div class="input-group">
                                        <input type="text"
                                               placeholder="Inserisci un termine di ricerca"
                                               class="form-control"
                                               formControlName="elementoRicercato">
                                        <span class="input-group-addon"><i class="fa fa-search"></i></span>
                                    </div>
                                </div>

                                <div class="col-sm-12 text-center mt-4">
                                    <button class="btn btn-orange btn-round pr-4"
                                            [disabled]="!ricercaSemplice.valid"
                                            type="submit"><i
                                            class="fa fa-search mr-2"></i>CERCA
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!--RICERCA AVANZATA-->
        <div class="section pt-2">
            <div class="container">
                <div class="row related-items">
                    <div class="col-sm-12">
                        <h4 class="mb-5 text-center">Ricerca Avanzata</h4>
                    </div>
                    <div class="col-md-8">
                        <form [formGroup]="ricercaAvanzata" (ngSubmit)="onSubmitRicercaAvanzata()">
                            <div class="row">
                                <div class="col-sm-12">
                                    <p class="text-muted">Titolo</p>
                                    <input type="text"
                                           placeholder="Inserisci un titolo"
                                           class="form-control"
                                           formControlName="titolo">
                                </div>
                                <div class="col-sm-12">
                                    <p class="text-muted">Contenuto</p>
                                    <input type="text"
                                           placeholder="Inserisci un contenuto"
                                           class="form-control"
                                           formControlName="contenuto">
                                </div>

                                <div class="col-sm-12">
                                    <p class="text-muted">Estremi cronologici</p>
                                    <div class="row">
                                        <div class="form-group col-sm-6">
                                            <div class='input-group date'>

                                                <input class="form-control"
                                                       placeholder="Data di inizio"
                                                       name="di"
                                                       ngbDatepicker
                                                       #d="ngbDatepicker"
                                                       (click)="d.toggle()"
                                                       formControlName="dataInizio"
                                                       [minDate]="minDate"
                                                       [maxDate]="maxDate"
                                                       (dateSelect)="setStartDate2($event)">

                                                <span class=" input-group-addon">
                                                <span class="glyphicon glyphicon-calendar">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                            </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group col-sm-6">
                                            <div class='input-group date'>

                                                <input class="form-control"
                                                       placeholder="Data di fine"
                                                       name="df"
                                                       ngbDatepicker
                                                       #d2="ngbDatepicker"
                                                       (click)="d2.toggle(d2)"
                                                       formControlName="dataFine"
                                                       [minDate]="minDate2"
                                                       [maxDate]="maxDate">

                                                <span class="input-group-addon">
                                            <span class="glyphicon glyphicon-calendar">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                            </span>
                                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--  <div class="col-sm-12">
                                      <p class="text-muted">Soggetto produttore</p>
                                      <input type="text"
                                             placeholder="Inserisci un soggetto produttore"
                                             class="form-control"
                                             formControlName="soggettoProduttore">
                                  </div>-->
                                <!--  <div class="col-sm-12">
                                      <p class="text-muted">Stato di conservazione</p>
                                      <input type="text"
                                             placeholder="Inserisci lo stato di conservazione"
                                             class="form-control"
                                             formControlName="statoConservazione">
                                  </div>-->
                                <!-- <div class="col-sm-12">
                                     <p class="text-muted">Segnatura</p>
                                     <input type="text"
                                            placeholder="Ricerca per segnatura"
                                            class="form-control"
                                            formControlName="segnatura">
                                 </div>-->
                                <!-- <div class="col-sm-12">
                                     <p class="text-muted">Tipologia documentaria</p>
                                     <input type="text"
                                            placeholder="Seleziona una tipologia documentaria"
                                            class="form-control">
                                 </div>-->

                                <div class="col-sm-12">
                                    <button class="btn btn-orange btn-round pr-4 " type="submit"><i
                                            class="fa fa-search mr-2"></i>CERCA
                                    </button>
                                </div>
                            </div>
                        </form>
                        <hr>
                        <form [formGroup]="ricercaTag" (ngSubmit)="onRicercaTag()">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h4 class="mb-2">Ricerca per tag</h4>
                                </div>
                                <div class="col-sm-12">

                                    <select class="custom-select" formControlName="tagValue">
                                    <option [value]="null" disabled selected> Seleziona un Tag</option>
                                        <option [value]="tag.KeyWordValue"  *ngFor="let tag of tags"> {{tag?.KeyWordValue}}</option>
                                    </select>
                                    <button class="btn btn-orange btn-round pr-4 btn-sm ml-3" type="submit"><i
                                            class="fa fa-search mr-2"></i>CERCA
                                    </button>
                                </div>

                            </div>
                        </form>
                        <hr>
                        <form [formGroup]="ricercaIndex" (ngSubmit)="onRicercaIndex()">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h4 class="mb-2">Ricerca per indice</h4>
                                </div>
                                <div class="col-sm-12">
                                    <select class="custom-select mb-3"  formControlName="indexType" (change)="optionchanged($event.target.value)">
                                        <option [value]="null" disabled selected> Seleziona un indice</option>
                                        <option [value]="index.IndexType"  *ngFor="let index of indexes"> {{index?.IndexType}}</option>
                                    </select> <br>
                                    <select *ngIf="!hideIndexChildren" class="custom-select" formControlName="indexValue">
                                        <option [value]="null" disabled selected> Seleziona un nome</option>
                                        <option [value]="index.IndexValue"  *ngFor="let index of indexType"> {{index.IndexValue}}</option>
                                    </select>
                                    <button *ngIf="!hideIndexChildren"
                                            class="btn btn-orange btn-round pr-4 btn-sm ml-3" type="submit"><i
                                            class="fa fa-search mr-2"></i>CERCA
                                    </button>
                                </div>

                            </div>
                        </form>
                        <hr>


                        <form [formGroup]="filterByDocumentTypeData" (ngSubmit)="filterByDocumentType()">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h4 class="mb-2">Ricerca per tipologie documentarie</h4>

                                </div>
                            </div>
                            <select class="custom-select" formControlName="DocumentTypeValue">
                                <option [value]="null" disabled selected> Seleziona una Tipologia</option>
                                <option [value]="item.DocumentTypeValue"
                                        *ngFor="let item of documentTypeList">{{item.DocumentTypeValue}}
                                </option>
                            </select>
                            <button type="submit" class="btn btn-orange btn-round pr-4 btn-sm ml-3"><i
                                    class="fa fa-search mr-2"></i>CERCA
                            </button>
                        </form>

                        <hr>
                     <!--   <form action="">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h4 class="mb-2">Ricerca Ricerca per Oggetto</h4>
                                </div>
                                <div class="col-sm-12">
                                    <p class="text-muted">Oggetto</p>
                                    <input type="text"
                                           placeholder="Seleziona un oggetto"
                                           class="form-control">
                                </div>
                                <div class="col-sm-12">
                                    <p class="text-muted">Soggetto</p>
                                    <input type="text"
                                           placeholder="Inserisci un soggetto"
                                           class="form-control">
                                </div>
                                <div class="col-sm-12">
                                    <p class="text-muted">Materia tecnica</p>
                                    <input type="text"
                                           placeholder="Inserisci una materia tecnica"
                                           class="form-control"
                                           formControlName="materiaTecnica">
                                </div>
                                <div class="col-sm-12">
                                    <p class="text-muted">Ambito culturale</p>
                                    <input type="text"
                                           placeholder="Inserisci un ambito culturale"
                                           class="form-control"
                                           formControlName="ambitoCulturale">
                                </div>
                            </div>
                        </form>-->
                    </div>
                    <div class="col-md-4 border border-right-0 border-top-0 border-bottom-0">
                        <h5><strong>Come effettuare una ricerca</strong></h5>
                        <p>
                            <small>È possibile effettuare ricerche su tutto l’archivio attraverso la modalità ricerca
                                semplice e ricerca avanzata.
                                <br>
                                La <strong>ricerca semplice</strong> permette di effettuare una ricerca full text su
                                tutto il patrimonio schedato.
                                <br>
                                La <strong>ricerca avanzata</strong> permette di selezionare i campi sui quali
                                effettuare la ricerca e di combinare i criteri tra loro con l'uso di operatori booleani
                            </small>
                        </p>
                    </div>


                </div>
            </div>
        </div>


    </div>
</div>