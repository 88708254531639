import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {CommonService} from '../../services/common.service';
import {map} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import * as _ from 'lodash';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

    errorAlertMessage: boolean = false;

    // sezione ricerca
    searchCriteria;
    TC; // tutti i campi
    ricercaSemplice = new FormGroup({
        elementoRicercato: new FormControl('')

    });


    // sezione risultati

    searchType: string;
    type: string;
    value: string;
    returnedTree: any;
    UrlParams;
    Detail;
    noResults = false;

    pageSizeResult: number = 12;
    offset;
    totalItems;
    pages;
    itemsShown;
    forwardOffset;
    backwardOffset;
    backwardActive = false;
    forwardActive = true;


    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _api: ApiService,
        private _common: CommonService) {


        this.UrlParams = this._route.snapshot.queryParams;
        console.log('prima di delete', this.UrlParams);

        this.UrlParams = _.omit(this.UrlParams, 'offset');

        console.log('Dopo delete', this.UrlParams);

        let currentUrl2 = this._router;
        console.log(currentUrl2);
    }

    ngOnInit() {

        this._common.showNavbar();

        this._route.queryParams.subscribe(params => {

            this.searchType = params.searchType;
            this.type = params.type;
            this.value = params.value;
            if (params.offset > 0) {
                this.offset = Number(params.offset);
            } else {
                this.offset = 0;
            }
            if (params.searchType === 'SS') {
                this.TC = params.TC;
                this.searchCriteria = [{
                    'Id': 0,
                    'SearchCriteriaLabel': 'TUTTI I CAMPI',
                    'SearchCriteriaMapping': 'TUTTI I CAMPI',
                    'SearchCriteriaDataType': 'varchar',
                    'WsObjectType': 2,
                    'SearchCriteriaValue': this.TC,
                    'SearchCriteriaPrecision': 'Contiene'
                }];
                console.log('ricerca semplice', this.searchCriteria);

                this._api.ReturnSearchCriteriaResults(JSON.stringify(this.searchCriteria), '', this.offset, this.pageSizeResult)
                    .subscribe(res => {
                        this.value = this.TC;
                        this.returnedTree = res.ReturnedTree;
                        if (params.offset > 0) {
                            this.offset = Number(params.offset);
                        } else {
                            this.offset = 0;
                        }
                        if (res.ReturnedTree) {
                            this.setPagination(res.Detail, this.offset, this.pageSizeResult);
                        } else {
                            this.noResults = true;
                        }

                    }, error => {
                        this.errorAlertMessage = true;
                    })


            } else if (params.searchType === 'AS') {
                console.log('sono in ricerca avanzata');

                this.doAdvancedSearch(params.T, params.C, params.DI, params.DF);

            } else if (params.searchType === 'DocType') {
                console.log('sono in doctype')
                this.filterByDocumentType(params.value, this.offset, this.pageSizeResult)

            } else if (params.searchType === 'Index' || params.searchType === 'Keyword') {
                this.type = params.type;
                this.value = params.value;
                if (params.offset || params.offset > 0) {
                    this.offset = Number(params.offset);
                } else {
                    this.offset = 0;
                }
                this.doResearch(this.searchType, this.type, this.value, this.offset);
            }


        });
    }

    doResearch(searchType, type, value, offset) {
        if (searchType === 'Keyword') {
            this._api.ReturnKeyWordsSearchResults(type, value, '', offset, this.pageSizeResult)
                .subscribe((res: any) => {
                    this.returnedTree = res.ReturnedTree;
                    console.log(res);
                    this.setPagination(res.Detail, offset, this.pageSizeResult);

                }, error => {
                    this.errorAlertMessage = true;
                })
        } else if (searchType === 'Index') {
            this._api.ReturnIndexSearchResults(type, value, offset, this.pageSizeResult)
                .subscribe((res: any) => {
                    this.returnedTree = res.ReturnedTree;
                    console.log(res);
                    this.setPagination(res.Detail, offset, this.pageSizeResult);
                }, error => {
                    this.errorAlertMessage = true;
                })


        } else {
            /*   console.log('redirect')
               this._common.pageNotFound();*/
        }
    }


    // ricerca avanzata

    doAdvancedSearch(T, C, DI, DF) {

        this.searchCriteria = [];
        if (T) {
            var titolo = {
                'Id': 3,
                'SearchCriteriaLabel': 'Tit. attribuito',
                'SearchCriteriaMapping': 'TITOLO_ATTRIBUITO',
                'SearchCriteriaDataType': 'varchar',
                'WsObjectType': 2,
                'SearchCriteriaOperator': 'OR',
                'SearchCriteriaValue': T,
                'SearchCriteriaPrecision': 'Contiene'
            };
            var titoloOriginale = {
                'Id': 2,
                'SearchCriteriaLabel': 'Tit. originale',
                'SearchCriteriaMapping': 'TITOLO_ORIGINALE',
                'SearchCriteriaDataType': 'varchar',
                'WsObjectType': 2,
                'SearchCriteriaOperator': 'AND',
                'SearchCriteriaValue': T,
                'SearchCriteriaPrecision': 'Contiene'
            }
            this.searchCriteria.push(titolo);
            this.searchCriteria.push(titoloOriginale);
        }
        if (C) {
            var contenuto = {
                'Id': 8,
                'SearchCriteriaLabel': 'Contenuto',
                'SearchCriteriaMapping': 'CONTENUTO',
                'SearchCriteriaDataType': 'ntext',
                'WsObjectType': 2,
                'SearchCriteriaOperator': 'AND',
                'SearchCriteriaValue': C,
                'SearchCriteriaPrecision': 'Contiene'
            }
            this.searchCriteria.push(contenuto);
        }
        if (DI && DF) {

            var dataInizio = {
                'Id': 11,
                'SearchCriteriaLabel': 'Estremi partenza',
                'SearchCriteriaMapping': 'DATA_DA',
                'SearchCriteriaDataType': 'date',
                'WsObjectType': 2,
                'SearchCriteriaValue': DI,
                'SearchCriteriaOperator': 'AND',
                'SearchCriteriaPrecision': 'Superiore a'
            }
            this.searchCriteria.push(dataInizio);

            var dataFine = {
                'Id': 12,
                'SearchCriteriaLabel': 'Estremi arrivo',
                'SearchCriteriaMapping': 'DATA_A',
                'SearchCriteriaDataType': 'date',
                'WsObjectType': 2,
                'SearchCriteriaValue': DF,
                'SearchCriteriaOperator': 'OR',
                'SearchCriteriaPrecision': 'Inferiore a'
            }
            this.searchCriteria.push(dataFine);
        } else if (DI) {

            var soloDataInizio = {
                'Id': 11,
                'SearchCriteriaLabel': 'Estremi partenza',
                'SearchCriteriaMapping': 'DATA_DA',
                'SearchCriteriaDataType': 'date',
                'WsObjectType': 2,
                'SearchCriteriaValue': DI,
                'SearchCriteriaOperator': 'AND',
                'SearchCriteriaPrecision': 'Contiene'
            }
            this.searchCriteria.push(soloDataInizio);
        }


        console.log('this.searchCriteria AS', this.searchCriteria)
        this._api.ReturnSearchCriteriaResults(JSON.stringify(this.searchCriteria), '', this.offset, this.pageSizeResult)
            .subscribe(res => {

                if (res.ReturnedTree) {
                    this.returnedTree = res.ReturnedTree;
                    this.setPagination(res.Detail, this.offset, this.pageSizeResult);
                } else {
                    this.noResults = true;
                }
            }, error => {
                this.errorAlertMessage = true;
            })

    }


    filterByDocumentType(DocType, offset, pagesizeresult) {

        this._api.ReturnDocumentTypeSearchResults(DocType, this.offset, 12)
            .subscribe(res => {
                console.log(res);
                this.returnedTree = res.ReturnedTree;
                this.setPagination(res.Detail, this.offset, this.pageSizeResult);
            }, error => {
                this.errorAlertMessage = true;
            });
    }


    // PAGINAZIONE

    setPagination(returnedDetail, offset, pageSizeResult) {
        console.log('dateil:', returnedDetail);
        this.totalItems = returnedDetail.split('di ')[1];
        this.itemsShown = returnedDetail.split('Risultati: ')[1];
        if (this.totalItems % 10 !== 0) {
            this.pages = Math.floor(this.totalItems / pageSizeResult) + 1;
        } else {
            this.pages = Math.floor(this.totalItems / pageSizeResult);
        }
        this.offset = offset;

        this.backwardOffset = offset - pageSizeResult;
        this.backwardActive = this.backwardOffset >= 0;

        this.forwardOffset = offset + pageSizeResult;
        this.forwardActive = this.forwardOffset < this.totalItems;
    }

    setOffset(offset) {
        console.log('newOffset: ', offset);
        let newParams = _.assign(this.UrlParams, {offset: offset});
        console.log(newParams);
        this._router.navigate(['/ricerca/risultati'], {queryParams: newParams})
    }


}
