import {NgModule} from '@angular/core';
import {CommonModule,} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';

import {PresentationComponent} from './pages/home/presentation.component';
import {ComponentsComponent} from './components/components.component';
import {NucleoiconsComponent} from './components/nucleoicons/nucleoicons.component';
import {ExploreComponent} from './archive/explore/explore.component';
import {SingleCardComponent} from './archive/components/single-card/single-card.component';
import {Error404Component} from './pages/error404/error404.component';
import {SearchComponent} from './archive/search/search.component';
import {SearchformComponent} from './archive/searchform/searchform.component';
import {TimelineComponent} from './timeline/timeline.component';
import {StorymapComponent} from './pages/percorsi/storymap/storymap.component';
import {LagoAgnelComponent} from './pages/percorsi/storymap/lago-agnel/lago-agnel.component';
import {ProjectComponent} from './pages/project/project.component';
import {NewsComponent} from './news/news.component';
import {WipComponent} from './wip/wip.component';
import {PartecipaComponent} from './pages/partecipa/partecipa.component';
import {GenovaComponent} from './pages/percorsi/storymap/genova/genova.component';
import {MetanoComponent} from './pages/percorsi/storymap/metano/metano.component';
import {PiacenzaComponent} from './pages/percorsi/storymap/piacenza/piacenza.component';
import {GenovaGavetteComponent} from './pages/percorsi/storymap/genova-gavette/genova-gavette.component';
import {PresidentiComponent} from './pages/presidenti/presidenti.component';
import {AmiatComponent} from './pages/percorsi/storymap/amiat/amiat.component';
import {NewsSingleComponent} from './pages/news/news-single/news-single.component';
import {NewsListComponent} from './pages/news/news-list/news-list.component';
import {HighlightsComponent} from './pages/highlights/highlights/highlights.component';
import { UnSecoloDiStoriaComponent } from './pages/un-secolo-di-storia/un-secolo-di-storia.component';
import { TimelineStandaloneComponent } from './pages/timeline-standalone/timeline-standalone.component';
import {AcademyComponent} from './pages/academy/academy.component';

const routes: Routes = [
    {path: 'home', component: PresentationComponent},
    {path: 'components', component: ComponentsComponent},
    {path: 'esplora', component: ExploreComponent},
    {path: 'esplora/:NodeValue', component: ExploreComponent},
    {path: 'ricerca', component: SearchformComponent},
    {path: 'ricerca/risultati', component: SearchComponent},
    {path: 'ricerca/risultati/:item', component: SearchComponent},
    {path: 'scheda/:NodeValue', component: SingleCardComponent},
    {path: 'cronologia', component: TimelineComponent},
    {path: 'progetto', component: ProjectComponent},
    {path: 'percorsi', component: StorymapComponent},
    {path: 'percorsi/storie-dei-presidenti', component: PresidentiComponent},
    {path: 'percorsi/lago-agnel', component: LagoAgnelComponent},
    {path: 'percorsi/sopravvivere-alla-guerra', component: GenovaComponent},
    {path: 'percorsi/avventura-del-metano', component: MetanoComponent},
    {path: 'percorsi/nascita-di-un-acquedotto', component: PiacenzaComponent},
    {path: 'percorsi/e-luce-fu', component: GenovaGavetteComponent},
    {path: 'percorsi/storia-di-una-vertenza', component: AmiatComponent},
    {path: 'errore-404', component: Error404Component},
    {path: 'news-old', component: NewsComponent},
    {path: 'work-in-progress', component: WipComponent},
    {path: 'partecipa', component: PartecipaComponent},
    {path: 'news', component: NewsListComponent},
    {path: 'news/:contentSlug/:newsSlug', component: NewsSingleComponent},
    {path: 'in-evidenza', component: HighlightsComponent},
    {path: 'un-secolo-di-storia', component: UnSecoloDiStoriaComponent},
    {path: 'timeline3d/un-secolo-di-storia', component: TimelineStandaloneComponent},
    {path: 'academy', component: AcademyComponent},
    {path: 'academy/:bookId', component: AcademyComponent},
    {path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [],
})
export class AppRoutingModule {
}
