import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-news-single',
    templateUrl: './news-single.component.html',
    styleUrls: ['./news-single.component.scss']
})
export class NewsSingleComponent implements OnInit {
    contentSlug;
    newsSlug;
    news;
    cmsEndpoint = environment.cmsEndpoint;
    imgEndpoint = environment.imgEndpoint;

    constructor(private _route: ActivatedRoute, private http: HttpClient) {
    }

    ngOnInit() {
        this._route.paramMap.subscribe((paramMap: ParamMap) => {
            this.contentSlug = paramMap.get('contentSlug');
            this.newsSlug = paramMap.get('newsSlug');


            this.http.get(this.cmsEndpoint + '/contents/' + this.contentSlug + '/' + this.newsSlug).subscribe(news => {
                this.news = news;
            })

        }, err => {
            alert('Si è verificato un errore nel recuperare la news. Si prega di riprovare più tardi')
        });
    }

}
