import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../../services/api.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {map} from 'rxjs/operators';
import {EmbedVideoService} from 'ngx-embed-video/dist';
import {AuthService} from '../../../auth/auth.service';
import {CommonService} from '../../../services/common.service';

@Component({
    selector: 'app-single-card',
    templateUrl: './single-card.component.html',
    styleUrls: ['./single-card.component.scss']
})
export class SingleCardComponent implements OnInit, OnDestroy {

    errorAlertMessage: boolean = false;
    auth;

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    galleryImage;

    parentTree = [];

    NodeValue;
    nodeType;
    returnedScheda;
    references: any;
    fileType;
    pdfUrl: string;
    pdfZoom: number = 0.5;
    loadingPDF = true;
    loadingPDFpreview = true;
    closeResult: string;

    hideGallery: boolean = true;
    hasPDF: boolean = false;
    previewImage: string;

    yt_iframe_html: any;

    returnedTree;

    constructor(
        private modalService: NgbModal,
        private _api: ApiService,
        private _route: ActivatedRoute,
        private embedService: EmbedVideoService,
        private _auth: AuthService,
        private _common: CommonService
    ) {
        this.auth = this._auth.getIsAuth();
        this._common.showNavbar();
    }


    ngOnInit() {

        this.galleryOptions = [
            {
                width: '600px',
                height: '400px',
                fullWidth: true,
                thumbnails: false,
                thumbnailsPercent: 0,
                imageAnimation: NgxGalleryAnimation.Slide
            },
            // max-width 800
            {
                'previewFullscreen': true,
                'previewKeyboardNavigation': true,
                'previewCloseOnClick': true,
                'previewCloseOnEsc': true,
                'previewZoom': true,
                'imageSwipe': true,
                'thumbnailsSwipe': true,
                'previewSwipe': true,
                'imageArrowsAutoHide': true,
                'thumbnailsArrowsAutoHide': true,
                'imageAnimation': 'slide'
            },
            {
                thumbnails: false,
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
            },
            // max-width 400
            {
                thumbnails: false,
                breakpoint: 400,
                preview: false
            }
        ];

        console.log('Auth?', this.auth);


        if (!this.auth) {
            setTimeout(function () {
                window.location.reload();
                console.log('NAH');
            }, 100);

        } else {
            this._route.paramMap.subscribe((paramMap: ParamMap) => {
                if (paramMap.has('NodeValue')) {

                    this.NodeValue = paramMap.get('NodeValue');
                    console.log('NodeValue: ' + this.NodeValue);
                    // I NEED TO KNOW IF THE NODE IS UA or SL

                    this._api.returnTree('SL', this.NodeValue).pipe(map((res: any) => res.ReturnedTree)).subscribe((res: any) => {
                        res ? this.nodeType = 'SL' : this.nodeType = 'UA';
                        this.returnedTree = res;

                        this._api.returnScheda(this.nodeType, this.NodeValue).pipe(map(res => res.ReturnedScheda)).subscribe(res => {
                            this.returnedScheda = res;
    
    
                            for (let parent of res.ParentTree) {
                                this.parentTree.push(parent.ParentId);
                            }
    
                            // Se c'è video
                            if (this.returnedScheda.Notes2) {
                                this.yt_iframe_html = this.embedService.embed(this.returnedScheda.Notes2, {attr: {width: '100%', height: 400}});
                            }
                            this.references = res.References;
    
                            /*TODO: DOC PDF VIEWER https://github.com/VadimDez/ng2-pdf-viewer*/
    
                            if (this.returnedScheda.Attachments) { // se ci sono allegati
                                for (let attachment  of this.returnedScheda.Attachments) { // cerco se c'è un PDF
                                    this.hideGallery = true; // nascondo la gallery
                                    this.fileType = attachment.FileCode.split('.')[1];
                                    if (this.fileType === 'pdf') { // se c'è un PDF
                                        this.hasPDF = true;
                                        this.pdfUrl = attachment.Url;
                                    } else {
                                        this.previewImage = attachment.MediumUrl;
                                        this.hasPDF = false;
                                    }
                                }
    
                                if (this.hasPDF === false) {
                                    this.hideGallery = false;
                                    this.galleryImages = [];
                                    for (let attachment  of this.returnedScheda.Attachments) {
                                        console.log(attachment);
                                        this.galleryImage = {
                                            small: '',
                                            medium: attachment.MediumUrl,
                                            big: attachment.Url
                                        };
                                        console.log('GALLERY IMAGE: ', this.galleryImage);
                                        this.galleryImages.push(this.galleryImage);
                                        console.log('GALLERY IMAGES: ', this.galleryImages);
                                    }
                                }
                            }
    
                        }, error => {
                            this.errorAlertMessage = true;
                        });
                    });
                }
            });
        }
    }

   /* zoom(value) {
        if (value === '+') {
            this.pdfZoom = this.pdfZoom + 0.5;
            console.log(this.pdfZoom)
        } else if (value === '-') {
            this.pdfZoom = this.pdfZoom - 0.5;
            console.log(this.pdfZoom)
        }
    }

    callBackFn(pdf: PDFDocumentProxy, type) {
        if (type === 'preview') {
            this.loadingPDFpreview = false;
        } else {
            this.loadingPDF = false;
        }

    }*/

    open(content, type) {
        if (type === 'lg') {
            console.log('aici');
            this.pdfZoom = 0.5;
            this.modalService.open(content, {size: 'lg'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else {
            this.modalService.open(content, {size: 'lg'}).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }


    private getDismissReason(reason: any): string {
        this.loadingPDF = true;
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    openGallery() {
        console.log('gallery aperta')
    }


    ngOnDestroy() {
        this.hasPDF = false;
        this.pdfUrl = '';
        this.galleryImages = [];
    }

    showDetails() {
        if (this.returnedScheda.OriginalTitle == '' && this.returnedScheda.DateElement.ToYear == '0' && this.returnedScheda.DateElement.FromDay == '0' && this.returnedScheda.DateElement.ToYear == 0 && this.returnedScheda.DateElement.ToDay == 0 && this.returnedScheda.TopicDate == '' && this.returnedScheda.CenturySpecification == '' && this.returnedScheda.PreciseDate == '' && this.returnedScheda.ConservationState == '' && this.returnedScheda.Notes2 == '' && this.returnedScheda.DocumentTye == '') {
            return false;
        } else {
            return true;
        }
    }

}
