<div class="wrapper">
    <div class="section text-center landing-section mb-0">
        <div class="container">
            <div class="row">
                <div class="col-md-8 ml-auto mr-auto mb-5">

                    <img src="assets/img/irenStoria.png" width="80%" alt="" class="mb-3 mt-5">
                    <h3 class="title">Il progetto IrenStoria</h3><h5>
                    Il patrimonio storico archivistico di Iren <strong>rappresenta un patrimonio culturale di notevole
                    interesse</strong>, testimonianza che ha un valore – oltre che storico in senso stretto – sociale,
                    aziendale,
                    artistico, tecnico, grafico, iconografico e comunicazionale.</h5>
                </div>
                <div class="col-sm-6 text-left">
                    <p>L’intero archivio storico del Gruppo Iren <strong>testimonia la rete diffusa dei servizi</strong>: la rete idrica, la rete gas, la rete dell’illuminazione, la rete della
                        raccolta rifiuti.</p>
                    <p> Una rete, intesa in senso ampio, che si snoda in maniera capillare per l’Italia, e che della
                        rete ha tutte le caratteristiche: peculiarità, diffusione, capillarità, nodi, sviluppo.</p>
                </div>
                <div class="col-sm-6 text-left">
                    <p> Attraverso i documenti d’archivio, attraverso le fonti grafiche, fotografiche o video,
                        attraverso i racconti delle fonti orali intendiamo far emergere la storia del Gruppo Iren, da
                        considerare insieme a tutte le storie che l’hanno caratterizzata nel tempo e che con il
                        contributo di molti e il sacrificio di tutti, hanno portato alle persone la luce, il calore, l'acqua. i servizi
                        <strong>favorendo un cambiamento di vita e un benessere comune</strong>.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="cd-section section-white" id="features">
        <div class="features-5 section-image"
             style="background-image: url('assets/img/staticImages/project-bg.jpg')">
            <div class="container">

                <div class="row">
                    <div class="col-sm-5 ml-auto">
                        <div class="info">
                            <div class="icon"><i class="fal fa-layer-group"></i></div>
                            <h4 class="title">Tipologie eterogenee</h4>
                            <p>Partendo dalla varietà di documenti, esistono enormi potenzialità di ricostruzione della
                                storia aziendale che porta con sé il racconto di un’epoca.</p>
                            <button (click)="smoothScroll('tipologie')" class="btn btn-link text-white mt-3">
                                Approfondisci
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-5 mr-auto">
                        <div class="info">
                            <div class="icon"><i class="fal fa-file-signature"></i></div>
                            <h4 class="title">Soggetti produttori</h4>
                            <p>Per ciascuna delle sedi interessate è evidente l'articolazione di differenti soggetti
                                produttori. </p>
                            <button (click)="smoothScroll('soggetti')" class="btn btn-link text-white mt-3">
                                Approfondisci
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row bottom-line">
                    <div class="col-sm-5 ml-auto">
                        <div class="info">
                            <div class="icon"><i aria-hidden="true" class="fas fa-books"></i></div>
                            <h4 class="title">Ricchezza delle fonti</h4>
                            <p>È notevole la ricchezza delle fonti non solo documentarie. Sono imponenti le sezioni
                                relative alla fototeca, all’oggettistica e ai luoghi.</p>
                            <button (click)="smoothScroll('fonti')" class="btn btn-link text-white mt-3">Approfondisci
                            </button>
                        </div>
                    </div>
                    <div class="col-sm-5 mr-auto">
                        <div class="info">
                            <div class="icon"><i class="fal fa-code-branch"></i></div>
                            <h4 class="title">Molteplicità dei servizi</h4>
                            <p>Il vasto campo dei servizi forniti oggi dal Gruppo Iren affonda le sue radici in un
                                passato che è possibile ricostruire attraverso i documenti d’archivio reperiti presso
                                le singole sedi.</p>
                            <button (click)="smoothScroll('servizi')" class="btn btn-link text-white mt-3">
                                Approfondisci
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="section textDescription" id="tipologie">
        <div class="container">
            <div class="row">

                <div class="col-sm-1 ml-auto">
                    <div class="icon"><i class="fal fa-layer-group text-warning"></i></div>
                </div>
                <div class="col-sm-7  mr-auto">

                    <h3 class="mb-2">Tipologie documentarie</h3>
                    <p>Partendo dalla varietà di documenti, esistono enormi potenzialità di ricostruzione della storia
                        aziendale che porta con sé il racconto di un’epoca.
                        <br>
                        Sono presenti nella quasi totalità delle varie sedi interessate i verbali delle Commissioni
                        amministratrici e i Registri delle deliberazioni. Questi documenti permettono la ricostruzione
                        puntuale della storia societaria, dei cambi di proprietà e dei ruoli apicali assunti da diverse
                        persone nel tempo.
                        <br>
                        Una ulteriore e imponente tipologia documentaria è data dai disegni architettonici e dai disegni
                        tecnici, dalle planimetrie e dagli schemi delle centrali, sino ai progetti esecutivi. Questa
                        tipologia di documentazione costituisce una fonte preziosa per tracciare la storia aziendale. La
                        prassi dei disegni dei progetti era: rilievo, lucidatura e riproduzione eliografica, spesso
                        colorata ad acquerello. I disegni così completati servivano per presentare i progetti al Genio
                        civile. È possibile quindi, rilevare la storia del metodo di lavoro dei progettisti. Inoltre è
                        possibile risalire a tutta la storia di costruzione di impianti, dighe e centrali che hanno
                        permesso e permettono tuttora lo svolgimento dell’imponente attività di distribuzione.
                        <br>
                        Di analoga importanza e potenzialità di esplorazione storica sono i registri del personale,
                        documentazione per ogni periodo della storia e del contesto sociale di riferimento, i libri
                        contabili e mastri, testimonianza dell’andamento economico aziendale, i contratti relativi alle
                        utenze, segno della consistente entità dei servizi resi, la corrispondenza, patrimonio
                        archivistico essenziale.
                        Infine, ma non di minore rilevanza, sono le testimonianze della comunicazione aziendale, che nel
                        tempo è sempre stata all’avanguardia. Dalle pubblicazioni aziendali alle riviste e alle
                        locandine pubblicitarie, sino ai manuali di galateo per gli addetti al controllo della
                        distribuzione. Sono tutte testimonianze di una fervida attività di comunicazione pensata nei
                        contenuti e nella forma grafica.
                    </p>
                </div>
            </div>
        </div>
    </div>


    <div class="section textDescription" id="soggetti">
        <div class="container">
            <div class="row">
                <div class="col-sm-1 ml-auto">
                    <div class="icon"><i class="fal fa-file-signature text-orange"></i></div>
                </div>
                <div class="col-sm-7 mr-auto">
                    <h3 class="mb-2">Varietà dei soggetti produttori</h3>
                    <p>Per ciascuna delle sedi interessate è evidente l'articolazione di differenti soggetti produttori.
                        Il dato estremamente interessante è la presenza di documentazione relativa agli archivi di
                        soggetti privati (anche di fine ‘800), prima della municipalizzazione delle differenti società,
                        con relativa documentazione, sino alle nuove privatizzazioni succedutesi a fine secolo sino alla
                        costituzione del Gruppo Iren. Si tratta di una complessa articolazione storica relativa
                        all’Azienda, di cui tuttavia si conserva un’importante documentazione, che costituisce una parte
                        del suo patrimonio.
                    </p>
                </div>
            </div>
        </div>

    </div>


    <div class="section textDescription" id="fonti">
        <div class="container">
            <div class="row">
                <div class="col-sm-1 ml-auto">
                    <div class="icon"><i aria-hidden="true" class="fas fa-books text-danger"></i></div>
                </div>
                <div class="col-sm-7 mr-auto">
                    <h3 class="mb-2">Ricchezza delle fonti</h3>
                    <p>E' notevole la ricchezza delle fonti non solo documentarie. Sono imponenti le sezioni relative
                        alla fototeca, all’oggettistica e ai luoghi. Siano esse già in parte musealizzate, siano invece
                        conservate in armadi e scatole presso singoli uffici, rappresentano una fonte archivistica
                        estremamente suggestiva e foriera di significati, di storie, di rappresentazioni sociali e
                        d’epoca.
                    </p>
                </div>
            </div>
        </div>

    </div>


    <div class="section textDescription" id="servizi">
        <div class="container">
            <div class="row">
                <div class="col-sm-1 ml-auto">
                    <div class="icon"><i class="fal fa-code-branch text-success"></i></div>
                </div>
                <div class="col-sm-7 mr-auto">
                    <h3 class="mb-2">Molteplicità dei servizi</h3>
                    <p>Il vasto campo dei servizi forniti oggi dal Gruppo Iren affonda le sue radici in un passato che è
                        possibile, ricostruire attraverso i documenti d’archivio reperiti presso le singole sedi. A
                        Torino emergono la sezione costruzione, la sezione produzione e la gestione dell’illuminazione
                        pubblica; a Reggio Emilia i reparti idrico, gas, teleriscaldamento e rifiuti; a Piacenza i
                        reparti idrico e rifiuti; a Parma la distribuzione del gas e dell’elettricità; a Genova la
                        produzione e distribuzione dell’acqua e del gas.
                        <br>
                        L’eterogeneità, la ricchezza e la varietà delle fonti favoriscono una proposta unitaria di
                        valorizzazione dell’archivio.

                    </p>
                </div>
            </div>
        </div>

    </div>

  <!--  <div class="section textDescription" id="servizi">
        <div class="container">
            <div class="row">
                <div class="col-sm-12"><h3 class="mb-2">ATTIVITÀ E OUTPUT DI PROGETTO</h3></div>
                <div class="col-sm-6 ">

                    <ul>
                        <li>Ricerca storica</li>
                        <li>Ricerca archivistica</li>
                        <li>Schedatura e digitalizzazione</li>
                        <li>Raccolta di testimonianze orali</li>
                        <li>Piattaforma digitale integrata per la schedatura e la rappresentazione innovativa dei
                            contenuti
                        </li>
                        <li>Digital storytelling interattivo</li>

                    </ul>
                </div>
                <div class="col-sm-6">
                    <ul>
                        <li>Applicazione mobile</li>
                        <li>Virtual tour 360°</li>
                        <li>Video clip e interviste</li>
                        <li>Workshop</li>
                        <li>Ebook multimediali</li>
                        <li>Mostre multimediali</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>-->
</div>
