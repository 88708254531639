import {Injectable} from '@angular/core';
import {HttpClient, HttpInterceptor} from '@angular/common/http';
import {DatePipe} from '@angular/common';
import {getToken} from '../../../node_modules/codelyzer/angular/styles/cssLexer';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    date: Date = new Date();

    user = environment.user;
    psw = environment.psw;

    validTo;
    private isAuthenticated = false;
    private token: any;
    private tokenTimer: any;
    private authStatusListener = new Subject<boolean>();


    constructor(private http: HttpClient, private datePipe: DatePipe) {
    }

    getToken() {
        return this.token;
    }

    getIsAuth() {
        return this.isAuthenticated;
    }


    getAuthStatusListener() {
        return this.authStatusListener.asObservable();
    }


    login() {

        this.http.get('https://mosaiko.heritage.srl/irenStoria/webservices/connection.asmx/Login?username=' + this.user + '&password=' + this.psw)
            .subscribe(data => {
                this.token = data;

                if (this.token) {
                    let tokenExpireDate = moment(this.token.TokenValidTo, 'YYYYMMDDHHmmss');
                    let nowDate = moment(this.date, 'YYYYMMDDHHmmss');
                    // console.log('validto convertito', tokenExpireDate);
                    // console.log('oggi convertito', nowDate);
                    // console.log('differenza', tokenExpireDate.diff(nowDate));

                    const expiresInDuration = tokenExpireDate.diff(nowDate);
                    // console.log('expiresInDuration', expiresInDuration);

                    this.setAuthTimer(expiresInDuration);
                    this.isAuthenticated = true;

                    this.authStatusListener.next(true);
                    const now = new Date();
                    const expirationDate = new Date(
                        now.getTime() + expiresInDuration
                    );
                    // console.log(expirationDate);
                    this.saveAuthData(this.token.Token, expirationDate);
                }

            }, error => {
                console.log('ERRORE!');
                alert('Si è verificato un errore. Si prega di ricaricare la pagina. Se l\'errore persiste riprovare fra qualche minuto');
                window.location.reload();
            });

    } // fine login


    autoAuthUser() {
        const authInformation = this.getAuthData();
        if (!authInformation) {
            return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        // console.log('expiresIn', expiresIn);
        if (expiresIn > 0) {
            this.token = authInformation.token;
            this.isAuthenticated = true;
            /*
                        this.setAuthTimer(expiresIn / 1000);*/

            this.setAuthTimer(expiresIn);
            this.authStatusListener.next(true);
        }
    }


    private setAuthTimer(duration: number) {
        // console.log('Setting timer: ' + duration);
        this.tokenTimer = setTimeout(() => {
            // console.log('ìlogout da timeout')
            /* this.login();*/
            // console.log('tempo scaduto');
            /*        }, duration * 1000);*/
        }, duration);
    }

    private saveAuthData(token: string, expirationDate: Date) {
        localStorage.setItem('token', token);
        localStorage.setItem('expiration', expirationDate.toISOString());
    }

    private getAuthData() {
        const token = localStorage.getItem('token');
        const expirationDate = localStorage.getItem('expiration');
        if (!token || !expirationDate) {
            return;
        }
        return {
            token: token,
            expirationDate: new Date(expirationDate)
        }

        // Salvo nel local storage token e scadenza token
        /* getToken() {
             this.http.get('http://51.144.175.115/MosaikoIren/webservices/connection.asmx/Login?username=irenWS&password=wsHeri9999')
                 .subscribe(data => {
                     this.token = data;
                     this.validTo = this.token.TokenValidTo;
                     console.log('token', this.token.Token);
                     localStorage.setItem('token', this.token.Token);
                     localStorage.setItem('TokenValidTo', this.token.TokenValidTo);
                 });
         }*/


    }
}

// TODO: Controllo su token prima di effettuare qualsiasi altra chiamata
