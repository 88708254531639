<nav class="navbar navbar-expand-lg fixed-top nav-down" color-on-scroll="500">
    <div class="container-fluid">
        <div class="navbar-translate">
            <div class="navbar-header">
                <a class="navbar-brand" [routerLink]="['/home']"><img src="./assets/img/irenStoria.png" width="150" alt=""></a>
            </div>
            <button class="navbar-toggler navbar-burger" type="button" data-toggle="collapse"
                    data-target="#navbarToggler" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                    aria-label="Toggle navigation" (click)="sidebarToggle()">
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
                <span class="navbar-toggler-bar"></span>
            </button>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">

                <!--PROGETTO-->
                <div ngbDropdown class="nav-item dropdown">
                    <div class="nav-link dropdown-toggle" ngbDropdownToggle>Il progetto</div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-danger">
                        <a ngbDropdownItem [routerLink]="['/progetto']" class="dropdown-item"> Il progetto</a>
                        <a ngbDropdownItem [routerLink]="['/academy']" class="dropdown-item"> Academy</a>
                        <a ngbDropdownItem [routerLink]="['/news']" class="dropdown-item"> News</a>
                        <a ngbDropdownItem [routerLink]="['/partecipa']"  class="dropdown-item"> Partecipa</a>
                    </div>
                </div>


<!--ESPLORA ARCHIVIO-->
                <div ngbDropdown class="nav-item dropdown">
                    <div class="nav-link dropdown-toggle" ngbDropdownToggle>Esplora l'archivio</div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-danger">
                        <a ngbDropdownItem [routerLink]="['/esplora']" class="dropdown-item">Archivio digitale</a>
                        <a ngbDropdownItem [routerLink]="['/cronologia']" class="dropdown-item">Cronologia</a>
                        <a ngbDropdownItem [routerLink]="['/ricerca']" class="dropdown-item">Ricerca</a>
                    </div>
                </div>

                <div ngbDropdown class="nav-item dropdown">
                    <div class="nav-link dropdown-toggle" ngbDropdownToggle>Scopri le storie</div>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-danger">
                        <a ngbDropdownItem [routerLink]="['/percorsi']" class="dropdown-item">I Percorsi</a>
                        <a ngbDropdownItem [routerLink]="['/in-evidenza']" class="dropdown-item">In evidenza</a>
                    </div>
                </div>




               <!-- <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/progetto']">il progetto</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/percorsi']">percorsi</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/esplora']">Archivio Digitale</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/cronologia']">cronologia</a>
                </li>-->
              <!--  <li class="nav-item">
                    <a href="https://www.heritage-srl.it/developer" target="_blank" class="nav-link">approfondimenti</a>
                </li>-->
              <!--  <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/partecipa']">partecipa</a>
                </li>-->
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/ricerca']"><i class="nc-icon nc-zoom-split search-icon"></i></a>
                </li>
            </ul>
        </div>
    </div>
</nav>
