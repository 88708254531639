<div id="headers" class="header-4 cd-section">
    <div class="header-wrapper">

        <div class="page-header header-video">
            <div class="filter"></div>
            <!-- We show the video image placeholder instead of the video for small devices  -->
            <div class="video-image visible-xs visible-sm"
                 style="background-image: url('assets/img/storymaps/amiat/storymap-amiat-7.jpg')"></div>
            <div class="video-container">
                <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
                    <source src="assets/video/amiat.mp4" type="video/mp4">
                    Video not supported
                </video>
            </div>
            <div class="content-center">
                <div class="container upper-container text-center">
                    <div class="video-text">
                        <h2>Storymap</h2>
                        <h1 class="title-uppercase title-no-upper-margin">Storia di una vertenza</h1>
                        <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                                class="btn btn-outline-neutral btn-round mt-5">
                            <i class="fa fa-chevron-down"></i> Inizia il percorso
                        </button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- CAPITOLO 1 -->
<div class="section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 1</h3>
                            <h2 class="title ">Tra Woodstock e Torino</h2>
                        </div>
                    </div>
                    <div class="row pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    1969: mentre Jimi Hendrix infiamma Woodstock e Neil Armstrong posa il primo piede
                                    sulla Luna, a Torino volge a soluzione una questione forse più prosaica ma
                                    altrettanto vitale: la gestione della raccolta rifiuti. Dopo alcuni anni
                                    avventurosi, in cui l’eredità della società Urbiochimica
                                    era stata rilevata dalla comunale Satti, <strong> il 1° gennaio 1969 finalmente
                                    entra in funzione la nuova municipalizzata dedicata alla raccolta rifiuti: la
                                    AMRR</strong>.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/amiat/storymap-amiat-1.jpg);"></div>
</div>
<!--CAPITOLO 2-->
<div class="section-white" id="capitolo2">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 2</h3>
                            <h2 class="title ">Un’azienda in costruzione</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    La lunga battaglia in Comune aveva lasciato strascichi: l’opposizione alla
                                    costituzione dell’azienda era stata serrata e decisa e <strong>la presa in carico
                                    dei rifiuti di una città come Torino</strong>, che dai poco più di 700.000 abitanti
                                    del 1951 arriverà nel 1971 a quasi 1.200.000, <strong>non è cosa da farsi in un
                                    giorno</strong>. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/amiat/storymap-amiat-2.jpg);"></div>
</div>

<!--CAPITOLO 3-->
<div class="section-white" id="capitolo3">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 3</h3>
                            <h2 class="title ">Da "padroni" a "datori"</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>Anni duri, gli anni Settanta, anni di sindacato, di dispute spesso feroci tra datori
                                    di lavoro e dipendenti. <strong>E proprio nel 1970 vede la luce lo Statuto dei
                                        Lavoratori, che riconsidera finalmente su base paritaria i rapporti tra datori
                                        di lavoro e dipendenti</strong>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/amiat/storymap-amiat-3.jpg);"></div>
</div>

<!--CAPITOLO 4-->
<div class="section-white" id="capitolo4">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 4</h3>
                            <h2 class="title ">Torino si ferma</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>Ma le battaglie non sempre si fanno per obbiettivi diversi, capita di scontrarsi
                                    anche sulla strada per raggiungere uno stesso fine. Così, siamo <strong>a dicembre
                                        1970, le difficoltà di organizzazione e gestione del lavoro esplodono</strong>:
                                    gli ex spazzaturai si fermano per dieci giorni e <strong>Torino è paralizzata dai
                                        rifiuti</strong>.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s15297?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s15297?.AssignedTitle}}</h5>
                                    <p>{{s15297?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/amiat/storymap-amiat-4.jpg);"></div>
</div>
<!--CAPITOLO 5-->
<div class="section-white" id="capitolo5">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 5</h3>
                            <h2 class="title ">Per meglio lavorare</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>Alla base delle agitazioni, tuttavia, non ci sono rivendicazioni salariali, ma
                                    anzitutto la richiesta da parte degli operai di
                                    <strong>unificare i servizi di raccolta rifiuti e nettezza urbana</strong>, come già
                                    avviene in altri comuni.</p>
                            </div>
                        </div>

                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s15320?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s15320?.AssignedTitle}}</h5>
                                    <p>{{s15320?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/amiat/storymap-amiat-5.jpg);"></div>
</div>

<!--CAPITOLO 6-->
<div class="section-white" id="capitolo6">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 6</h3>
                            <h2 class="title ">Uno scontro costoso</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>La trattativa è dura e lunga, nonché molto costosa: <strong>l’azienda in più
                                    occasioni deve ricorrere a ditte private per non lasciare che la città resti
                                    sommersa dai rifiuti</strong>. Rarissimi, tuttavia, sono i momenti in cui questo
                                    scontro travalica una fiera lealtà sui due fronti della trattativa..</p>
                            </div>
                        </div>

                        <div class="col-sm-4 ">
                            <a [routerLink]="['/scheda/' + s15321?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s15321?.AssignedTitle}}</h5>
                                    <p>{{s15321?.Content}}</p>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--CAPITOLO 7-->
<div class="section-white" id="capitolo7">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 7</h3>
                            <h2 class="title ">Tra gentiluomini ci si intende"</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>Ma se l’obbiettivo è la meta – e non la strada per giungervi – presto o tardi un
                                    accordo si raggiunge. <strong>Il 30 giugno 1971 la Commissione Amministratrice si impegna
                                        a riorganizzare il servizio unificando raccolta rifiuti e nettezza
                                        urbana</strong>. La vertenza è terminata, Torino attende un servizio migliore.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s15322?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s15322?.AssignedTitle}}</h5>
                                    <p>{{s15322?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><!--
    <div class="section-white bg-image-storymap"
         style="background-image:url(assets/img/storymaps/amiat/storymap-amiat-7.jpg);"></div>-->
</div>


<div class="section-white mb-0">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white section-background"
                 style="background: url(assets/img/storymaps/amiat/storymap-amiat-7.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">

                            <h2 class="title font-weight-bold text-white"> Scopri tutti i documenti relativi a questa
                                storymap</h2>
                            <a class="btn btn-neutral btn-round videoBtn mt-4"
                               href="ricerca/risultati?searchType=Keyword&type=Storymap&value=Amiat">Visualizza tutte le
                                schede</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<nav id="cd-vertical-nav">
    <ul>
        <li>
            <a data-number="1" (click)="smoothScroll('headers')">
                <span class="cd-dot"></span>
                <span class="cd-label">Introduzione</span>
            </a>
        </li>
        <li>
            <a data-number="2" (click)="smoothScroll('capitolo1')">
                <span class="cd-dot"></span>
                <span class="cd-label">Tra Woodstock e Torino</span>
            </a>
        </li>
        <li>
            <a data-number="3" (click)="smoothScroll('capitolo2')">
                <span class="cd-dot"></span>
                <span class="cd-label">Un'azienda in costruzione</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo3')">
                <span class="cd-dot"></span>
                <span class="cd-label">Da "padroni" a "datori"</span>
            </a>
        </li>
        <li>
            <a data-number="5" (click)="smoothScroll('capitolo4')">
                <span class="cd-dot"></span>
                <span class="cd-label">Torino si ferma</span>
            </a>
        </li>
        <li>
            <a data-number="6" (click)="smoothScroll('capitolo5')">
                <span class="cd-dot"></span>
                <span class="cd-label">Per meglio lavorare</span>
            </a>
        </li>
        <li>
            <a data-number="7" (click)="smoothScroll('capitolo6')">
                <span class="cd-dot"></span>
                <span class="cd-label">Uno scontro costoso</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo7')">
                <span class="cd-dot"></span>
                <span class="cd-label">Tra gentiluomini ci si intende</span>
            </a>
        </li>
    </ul>
</nav>