import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    errorAlertMessage = false;

    constructor(
        private router: Router,
        private location: Location) {
    }

    /***********************/
    /********HELPERS********/

    /***********************/
    showNavbar() {
        const navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('nav-up');
    }


    pageNotFound() {
        this.router.navigate(['/errore-404']);
    }

    goBack() {
        this.location.back();
    }

    apiErrorAlert(error) {
        console.log('ERRORE!');
        /*alert('Si è verificato un errore nel caricamento dei dati. Se l\'errore persiste riprovare fra qualche minuto');
        window.location.reload();*/
        this.errorAlertMessage = true;
    }

}
