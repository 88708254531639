<div class="team-2 section-image" style="background-image: url('assets/img/staticImages/percorsi_bg.jpg');">

    <div class="container pt-5 pb-5">
        <div class="row">
            <div class="col-md-10 ml-auto mr-auto text-center mt-5 pt-5 pb-5">
                <h2 class="title">PARTECIPA</h2>
                <h5 class="description text-white">
                    <span style="font-weight: 700;">Il nostro archivio è un progetto partecipativo aperto, <br>
                        Hai del materiale sulla storia di Iren?
                    </span>
                </h5>

                <h5 class="text-white font-weight-bold mt-4"> Scrivici a irenstoria@gruppoiren.it</h5>
            </div>
        </div>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <p>Il progetto IrenSroria è un progetto che nasce determinato dalla volontà di recuperare la storia che
                    ci è stata consegnata e che, inevitabilmente, costituisce il nostro presente.
                    <br>
                    Come ha detto il Presidente, in occasione del lancio del progetto:<em> “Se il Gruppo nasce relativamente
                    pochi anni fa (nel 2010), le aziende che l’hanno costituita attraversano tutto il ‘900. Via via, nei
                    materiali raccolti, si succedono fonti documentarie provenienti delle molte aziende che oggi formano
                    il Gruppo. Fonti che hanno enormi potenzialità per ricostruire una vicenda aziendale che porta con
                    sé il racconto di più di un’epoca”</em>.
                    <br>
                    Per questo il progetto IrenStoria intende perseguire una collaborazione fattiva con chiunque,
                    persone, Enti o Istituzioni che possano contribuire alla ricostruzione storica del Gruppo,
                    desiderando condividere documenti, immagini, video, storie o racconti, ovvero promuovendo azioni
                    sinergiche intorno a patrimoni documentari e archivistici attinenti.
                </p>
            </div>
        </div>
    </div>
</div>