import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';
import { map } from 'rxjs/operators';

import {
  TREE_ACTIONS,
  KEYS,
  IActionMapping,
  ITreeOptions
} from 'angular-tree-component';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss']
})
export class ExploreComponent implements OnInit {
  errorAlertMessage: boolean = false;
  auth;

  NodeValue: string;

  returnedTree: any;
  builtTree = [];
  treeUnflatten;
  nodes = [];
  documentTypeList;

  sliderRange;

  newNode: any = {};
  loadedNode: any = {};
  list = [];
  parentTree = [];

  dateFilter: any = {
    connect: false,
    start: [1800, 2000],
    step: 1,
    tooltips: [true, true],
    range: {
      min: 1800,
      max: 2000
    },
    behaviour: 'drag'
  };

  filterByDocumentTypeData = new FormGroup({
    DocumentTypeValue: new FormControl(null)
  });

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _api: ApiService,
    private _common: CommonService,
    private _auth: AuthService,
  ) {
    this.auth = this._auth.getIsAuth();
  }

  ngOnInit() {
    this.list = [];
    if (!this.auth) {
      setTimeout(function() {
        window.location.reload();
        console.log('NAH');
      }, 100);
    } else {
      this._route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has('NodeValue')) {
          this.NodeValue = paramMap.get('NodeValue'); // getting the node id on url params
          this.buildTree(this.NodeValue);
          this._api
            .returnTree('SL', this.NodeValue)
            .pipe(map((res: any) => res.ReturnedTree))
            .subscribe(
              res => {
                this.returnedTree = res;
                if (this.returnedTree) {
                  // se la scheda esiste
                  if ( this.returnedTree[0].ChildrenCount.UA === 0 && this.returnedTree[0].ChildrenCount.SL === 0 ) {
                    // this.getParentTree('UA', this.returnedTree[0].Id);
                  } else {
                    // this.getParentTree('SL', this.returnedTree[0].Id);
                  }
                } else {
                  this._common.pageNotFound();
                }
              },
              error => {
                this._common.apiErrorAlert(error);
                this.errorAlertMessage = true;
              }
            );
        } else {
          this.buildTree(0);
          this._api
            .returnTree('SL', 0)
            .pipe(map((res: any) => res.ReturnedTree))
            .subscribe(
              res => {
                this.returnedTree = res;
                // this.nodes = this.createDataTree(this.returnedTree, 'Id', 'ParentId', 'children');
                // console.log('Nodevalue ? 0', this.returnedTree);
              },
              error => {
                console.log('Errore', error);
                this._common.apiErrorAlert(error);
                this.errorAlertMessage = true;
              }
            );
        }
      });
      /*this._api
        .ReturnDocumentTypeList()
        .pipe(map((res: any) => res.WsObjects))
        .subscribe(
          res => {
            this.documentTypeList = res;
            // console.log(this.documentTypeList);
          },
          error => {
            this._common.apiErrorAlert(error);
            this.errorAlertMessage = true;
          }
        );*/

    }
  }



    async buildTree(nodeId) {
        this.loadedNode = {};
        let childId;
        let currentTree = await this._api.returnTree('SL', nodeId).pipe(map((res: any) => res.ReturnedTree)).toPromise();
        // For my building Tree loop and for my breadcrumb i need an array with all parents and the current node too
        // In the returnScheda API if i use the current nodeId as param, it will returns an array with a list of its parents.
        // Actually i need ad array with its parents AND the current node too. -> {grandfather, father, AND ME TOO}
        // To force this i'll use a childId of this current node as param of retournScheda API.
        childId = currentTree[0].Id;
        let currentScheda = await this._api.returnScheda(currentTree[0].Type, childId)
                                            .pipe(map((res: any) => res.ReturnedScheda))
                                            .toPromise();
        this.parentTree = currentScheda.ParentTree;
        // now the current node is into the parent tree.

        for (let i = this.parentTree.length - 1 ; i >= 0; i--) {
            await this.getChildren(this.parentTree[i].Id);
            if (Object.keys(this.loadedNode).length === 0 && this.loadedNode.constructor === Object) {
                // Is this.loadedNode empty --> it happens only for the first children created
                this.loadedNode = this.newNode;
            } else {
              let storedNode = this.loadedNode;
              const childIndex = this.newNode.children.map(node => node.id ).indexOf(this.loadedNode.id);
              if ( childIndex >= 0 ) {
                  this.loadedNode = this.newNode;
                  this.loadedNode.children[childIndex] = storedNode;
              } else {
                  console.log('Some error in sidebar tree');
              }
            }
        }

        // Push main root child to the list
        await this.getChildren(0);
        
        if (Object.keys(this.loadedNode).length === 0 && this.loadedNode.constructor === Object) {
          // We are in the main root
          this.list = this.newNode.children;
        } else {
          let storedNode = this.loadedNode;
          const childIndex = this.newNode.children.map(node => node.id ).indexOf(this.loadedNode.id);
          console.log('newNode.children', this.newNode.children);
          console.log('loadedNode', this.loadedNode.id);
          console.log('childIndex', childIndex);
          if ( childIndex >= 0 ) {
            this.loadedNode = this.newNode.children;
            this.loadedNode[childIndex] = storedNode;
          } else {
              console.log('Some error in sidebar tree');
          }
          this.list = this.loadedNode;
        }

        console.log('Sidebar Menu', this.list);

    }

    async getChildren(nodeId) {
      let schedaResponse;
      if ( nodeId !== 0 ) {
        schedaResponse = await this._api.returnScheda('SL', nodeId).pipe(map((res: any) => res.ReturnedScheda)).toPromise();
      } else {
        schedaResponse = {
          Id: 0,
          AssignedTitle: 'Archivio',
          ParentId: 'root',
          Type: 'SL',
          ReturnSchedaIsMandatory: false
        };
      }
        let newNode = {};
        let treeResponse = await this._api.returnTree('SL', nodeId).pipe(map((res: any) => res.ReturnedTree)).toPromise();
        let formattedChildren = [];
        for (let child of treeResponse) {
            formattedChildren.push({
                id: child.Id,
                title: child.AssignedTitle,
                parentId: child.ParentId,
                type: child.Type,
                children: [],
                returnSchedaIsMandatory: child.ReturnSchedaIsMandatory

            });
        }

        newNode = {
            id: schedaResponse.Id,
            title: schedaResponse.AssignedTitle,
            parentId: schedaResponse.ParentId,
            type: schedaResponse.Type,
            children: formattedChildren,
            returnSchedaIsMandatory: schedaResponse.ReturnSchedaIsMandatory
        }
        this.newNode = newNode;
    }

    async onNavigateOnTree(node) {
        if (node.children.length === 0) {
            let currentTree = await this._api.returnTree('SL', node.id).pipe(map((res: any) => res.ReturnedTree)).toPromise();
            console.log('children of ', currentTree);
            console.log('il nodone', node);
            let formattedChildren = [];
            for (let child of currentTree) {
                formattedChildren.push({
                    id: child.Id,
                    title: child.AssignedTitle,
                    parentId: child.ParentId,
                    type: child.Type,
                    children: [],
                    returnSchedaIsMandatory: child.ReturnSchedaIsMandatory
                });
            }
            node.children = formattedChildren;
        } else {
            node.children = [];
        }
    }

  filterByDocumentType() {
    let docType = this.filterByDocumentTypeData.value.DocumentTypeValue;
    if (docType) {
      console.log('invio');
      this._router.navigate(['/ricerca/risultati'], {
        queryParams: { searchType: 'DocType', value: docType }
      });
    }
  }

  dates(dates) {
    this._router.navigate(['/ricerca/risultati'], {
      queryParams: { searchType: 'AS', DI: dates[0], DF: dates[1] }
    });
  }

  // ALBI OLD METHODS

  /* buildTree(returnedTree) {
        if (returnedTree[0].ParentId !== 0) {
            if (this.builtTree.find(x => x.ParentId === returnedTree[0].ParentId)) {
                console.log('trovato')
            } else {
                this.builtTree = [...this.builtTree, ...returnedTree];
                this.nodes = this.createDataTree(this.builtTree, 'Id', 'ParentId', 'children');
            }


        }
    } */

  /*FROM FLAT TO TREE*/

  /* createDataTree(flatList, idFieldName, parentKeyFieldName, fieldNameForChildren) {
        let rootElements = [];
        let lookup = {};

        flatList.forEach(function (flatItem) {
            let itemId = flatItem[idFieldName];
            lookup[itemId] = flatItem;
            flatItem[fieldNameForChildren] = [];
        });

        flatList.forEach(function (flatItem) {
            let parentKey = flatItem[parentKeyFieldName];
            if (parentKey != null) {
                let parentObject = lookup[flatItem[parentKeyFieldName]];
                if (parentObject) {
                    parentObject[fieldNameForChildren].push(flatItem);
                } else {
                    rootElements.push(flatItem);
                }
            } else {
                rootElements.push(flatItem);
            }

        });

        return rootElements;
    } */
} // fine class
