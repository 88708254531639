<div *ngIf="hideForIe" style="min-height: 100vh;" class="mt-5 pt-5 container">
    <h1 class="small description text-center">
        Questa timeline è stata realizzata utilizzando funzionalità disponibili solo per i browser moderni. </h1>
    <h1 class="small description text-center">
        Per assicurarti una visualizzazione corretta visita questa pagina con Chrome o Firefox.
    </h1>
    <div class="row text-center  mt-5">
        <div class="col-sm-4"></div>
        <div class="col-sm-4 offset-sm-4">
            <img src="assets/img/chrome-firefox.png" class="img-fluid" alt="">
        </div>
    </div>

</div>
<div id="timeline_background"
     [ngStyle]="{ 'background-image': 'url(assets/img/' + config?.backgroundImage + ')' }"></div>
<div id="timeline3d_wrapper" *ngIf="!hideForIe">
    <div class="time-nav">
        <nav class="menu">
            <a
                    *ngFor="let elem of timelineData"
                    [attr.href]="'#' + elem.id"
                    class="menu__item"
            >
                <div class="date">
                    <div class="timedate">
                        <div class="tt-day">{{ splitDate(elem.date, "YYYY") }}</div>
                        <!-- <div class="tt-month">{{ splitDate(elem.date, "DD.MM") }}</div> -->
                    </div>
                    <div class="timedateafter"></div>
                </div>
            </a>
        </nav>
    </div>
    <div class="environment_wrapper">
        <div class="text-center mt-5 titolo"><img class="logo-timeline" src="assets/img/iren-storia-logo-white.png"
                                                  alt="Iren Storia">
            <h1 class="text-white text-center">UN SECOLO DI STORIA</h1>
        </div>
        <div class="environment">
            <div class="scene">
                <div
                    #elementsTimeline
                    *ngFor="let elem of timelineData; let i = index"
                    [attr.id]="elem.id"
                    class="layer"
                    [attr.data-depth]="i * 500"
                >
                    <section>
                        <div class="section-content arrow_box"
                             [class.right]="i % 2 === 0"
                             [class.left]="i % 2 !== 0"
                             (click)="showDetails(elem.id)">
                            <div [class.rounded]="elem.type == 'type2'"
                                 [class.square]="elem.type == 'type1' || elem.type == 'type3'">
                                <div class="info-content"
                                     [ngStyle]="{'background-image': 'url(assets/img/' + elem.image + ')'}">
                                    <div class="bg-color-screener"
                                         [ngStyle]="{ 'background-color': config?.screenerColor }"></div>
                                    <div
                                            class="featured-image"
                                            [ngStyle]="{'background-image': 'url(' + endpoint + '/'+elem.image + ')'}"
                                    ></div>
                                    <div class="text-box" [ngStyle]="{ 'background-color': config?.screenerColor }">
                                        <h1 [attr.data-target]="elem.id">{{ elem.title }}</h1>
                                        <p [innerHTML]="elem.excerpt"></p>
                                    </div>
                                </div>
                            </div>
                            <div class="tt-arrow-dot blink"
                                 [ngStyle]="{ 'background-color': config?.dateDarkColor }"></div>
                        </div>

                        <!--Details -->
                        <div class="details_wrapper">
                            <div class="content">
                                <div class="featured-image"
                                     [ngStyle]="{ 'background-image': 'url(' + endpoint + '/'+elem.image + ')' }"
                                     *ngIf="elem.type !== 'type3'"></div>
                                <div *ngIf="elem.type === 'type3'" class="video_wrapper pt-2 pb-2">
                                    <div [innerHTML]="elem.videoEmbed" class="video"></div>
                                    <!-- <iframe width="560" height="315" [src]="elem.videoId" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                </div>
                                <div class="px-3">
                                    <h2 class="mb-2" [ngStyle]="{ 'color': config?.screenerColor }">{{elem.title}}</h2>
                                    <div [innerHTML]="elem.content"></div>
                                    <div [innerHTML]="elem.excerpt" *ngIf="!elem.content"></div>
                                </div>
                            </div>
                            <div class="detail_sidebar" *ngIf="elem.type === 'type1' && elem.links"
                                 [ngStyle]="{ 'background-color': config?.screenerColor + '66' }">
                                <h2 class="text-white">{{elem.linksTitle}}</h2>
                                <div class="text-white" [innerHtml]="elem.linksExcerpt"></div>
                                <article *ngFor="let link of elem.links">
                                    <a [href]="link.url" [target]="link.target">
                                        <h4 class="text-white font-weight-bold" *ngIf="link.title">{{link.title}}</h4>
                                    </a>
                                    <div class="d-flex article_body">
                                        <div class="img" [ngStyle]="{ 'background-image': 'url(' + link.image + ')' }"
                                             *ngIf="link.type==='Mosaiko'"></div>
                                        <div class="text-white article_excerpt" [innerHtml]="link.excerpt"></div>
                                    </div>
                                </article>
                            </div>
                            <div class="close_btn" (click)="hideDetails(elem.id)"><i class="fas fa-times"></i></div>
                        </div>
                        <!--end of details -->

                        <div class="section-date">
                            <div class="timedate" [ngStyle]="{ 'background-color': config?.dateLightColor }">
                                <div class="tt-day">{{ splitDate(elem.date, "YYYY") }}</div>
                                <!-- <div class="tt-month">{{ splitDate(elem.date, "DD.MM") }}</div> -->
                            </div>
                            <div class="timedateafter" [ngStyle]="{ 'background-color': config?.dateDarkColor }"></div>
                            <div class="text-white title_below_date text-center"><h3>{{elem.title}}</h3></div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <div class="scene__depth"></div>
</div>
