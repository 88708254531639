<div class="team-2 section-image" style="background-image: url('assets/img/staticImages/academy-bg.png');">

    <div class="container pt-2 pb-2">
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center mt-5 pt-5 pb-5">
                <img src="assets/img/staticImages/iren-academy-logo.png" alt="" class="img-fluid">
                <h5 class="description text-white mt-4">
            <span><strong>Iren Storia Academy</strong> è la sezione dedicata a studiosi e ricercatori pensata per offrire volumi e i saggi a stampa che riguardano la storia del Gruppo Iren nell’ambito della “Storia d’impresa” e della “Storia Industriale Italiana”.
                    </span>
                </h5>
            </div>
        </div>
    </div>
</div>
<div class="section pb-1" *ngIf="!bookId">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <p>In questa sezione troverai una serie di pubblicazioni a stampa che riguardano la <strong>storia delle
                    municipalizzate</strong> che negli anni sono confluite nel Gruppo Iren.
                    Le pubblicazioni sono ordinate in senso cronologico (dalle più recenti alle meno recenti) e per
                    ognuna è data una
                    <strong>scheda sintetica</strong>, una <strong>descrizione</strong> e uno o più
                    <strong>link</strong> per
                    l’acquisto, la consultazione o la ricerca sull’OPAC SBN. <br>
                    Delle pubblicazioni i cui diritti sono del Gruppo Iren è possibile consultare e scaricare l’intero
                    contenuto. Delle altre, ove è stato possibile per i diritti, è disponibile un’anteprima.

                </p>
            </div>
        </div>
    </div>
</div>
<div class="section" *ngIf="!bookId">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 bookIndex" *ngFor="let book of books">
                <div class="row">
                    <div class="col-4">
                        <img [src]="environment.endpoint + '/' + book.imgPath" [alt]="book.title" class="img-fluid">
                    </div>
                    <div class="col-8 bookDescriptionBox">
                        <h2 class="book-title">{{book.title}}</h2>
                        <h3 class="book-subtitle">{{book.subtitle}}</h3>
                        <p><strong>{{book.author}}</strong></p>
                        <p>{{book.notes}}</p>
                        <a [routerLink]="['/academy/' + book.id]"
                           class="btn btn-outline-black btn-round text-black mt-4 linkBottom">SCHEDA</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="section" *ngIf="bookId">

    <div class="container" *ngIf="!openedSaggio">
        <div class="row">
            <div class="col-sm-3">
                <a [routerLink]="['../']"><i class="fa fa-chevron-left"></i> TORNA ALL'INDICE</a>
            </div>
            <div class="col-sm-9">
                <small><em><a [routerLink]="['../']">Academy</a> / {{openedBook?.title | slice:0:40}}...</em></small>
            </div>
            <div class="col-sm-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 mb-4">
                <div class="row">
                    <div class="col-sm-8 col-8 col-lg-12 m-auto">
                        <img [src]="environment.endpoint + '/' +openedBook?.imgPath" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="tab" *ngIf="openedBook?.abstract" (click)="switchTab('abstract')"
                             [ngClass]="{'tabActive': activeTab === 'abstract'}">ABSTRACT
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="tab" *ngIf="openedBook?.bibliography" (click)="switchTab('bibliography')"
                             [ngClass]="{'tabActive': activeTab === 'bibliography'}">BIBLIOGRAFIA
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-sm-12">
                        <h2 class="book-title">{{openedBook?.title}}</h2>
                        <h3 class="book-subtitle">{{openedBook?.subtitle}}</h3>
                        <p><strong>{{openedBook?.author}}</strong></p>
                        <p><em>{{openedBook?.code}}</em></p>
                        <p class="mt-2" [innerHTML]="openedBook?.abstract" *ngIf="activeTab === 'abstract'">
                        <div class="mt-2 bio" *ngIf="activeTab === 'bibliography'">
                            <ul>
                                <li *ngFor="let bio of openedBook?.bibliography"><span [innerHTML]="bio"></span></li>
                            </ul>

                        </div>
                        <div>
                            <a *ngIf="openedBook?.link_purchase" target="_blank" [href]="openedBook?.link_purchase"
                               class="btn btn-outline-black btn-round text-black mt-4 mr-3">ACQUISTA</a>
                            <a *ngIf="openedBook?.link_download" target="_blank" [href]="openedBook?.link_download"
                               class="btn btn-outline-black btn-round text-black mt-4 mr-3">SCARICA PDF</a>
                            <a *ngIf="openedBook?.link_search" target="_blank" [href]="openedBook?.link_search"
                               class="btn btn-outline-black btn-round text-black mt-4 mr-3">CERCA</a>
                            <a *ngIf="openedBook?.link_preview" target="_blank" [href]="openedBook?.link_preview"
                               class="btn btn-success btn-round mt-4 ">ANTEPRIMA</a>
                        </div>
                    </div>
                    <div class="col-sm-12 mt-4 title-hr" *ngIf="openedBook?.saggi.length > 0">
                        <h4>SAGGI</h4>
                        <hr>
                    </div>
                    <div class="col-sm-12 saggio" *ngIf="openedBook?.saggi.length > 0">
                        <div class="row">
                            <div class="col-sm-12 mb-2" *ngFor="let saggio of openedBook.saggi">
                                <h2 class="book-title"
                                    (click)="openSaggio(saggio.id)">{{saggio.title}}</h2>
                                <p>{{saggio.author}}</p>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!--saggio-->
    <div class="container" *ngIf="openedSaggio">
        <div class="row">
            <div class="col-sm-3">
                <span class="backToBook" (click)="openedSaggio = ''"><i
                        class="fa fa-chevron-left"></i> TORNA AL LIBRO</span>
            </div>
            <div class="col-sm-9">
                <small><em><a [routerLink]="['../']">Academy</a> / <a
                        (click)="openedSaggio = ''">{{openedBook.title | slice:0:20}}...</a>
                    / {{openedSaggio.title | slice:0:20}}...</em></small>
                <p class="font-weight-bold">SAGGIO IN LIBRO: "{{openedBook.title}}"</p>

            </div>
            <div class="col-sm-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 mb-4">
                <div class="row">
                    <div class="col-sm-8 col-8 m-auto col-lg-12">
                        <img [src]="environment.endpoint + '/' +openedBook?.imgPath" class="img-fluid" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="tab" *ngIf="openedSaggio?.abstract" (click)="switchTab('abstract')"
                             [ngClass]="{'tabActive': activeTab === 'abstract'}">ABSTRACT
                        </div>
                    </div>
                    <div class="col-sm-12 ">
                        <div class="tab" *ngIf="openedSaggio?.bibliography" (click)="switchTab('bibliography')"
                             [ngClass]="{'tabActive': activeTab === 'bibliography'}">BIBLIOGRAFIA
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-sm-12">
                        <h2 class="book-title">{{openedSaggio?.title}}</h2>
                        <h3 class="book-subtitle">{{openedSaggio?.subtitle}}</h3>
                        <p><strong>{{openedSaggio?.author}}</strong></p>
                        <p><em>{{openedSaggio?.code}}</em></p>
                        <p class="mt-2" [innerHTML]="openedSaggio?.abstract" *ngIf="activeTab === 'abstract'">
                        <div class="mt-2 bio" *ngIf="activeTab === 'bibliography'">
                            <ul>
                                <li *ngFor="let bio of openedSaggio?.bibliography"><span [innerHTML]="bio"></span></li>
                            </ul>

                        </div>
                        <div>
                            <a *ngIf="openedSaggio?.link_purchase" target="_blank" [href]="openedSaggio?.link_purchase"
                               class="btn btn-outline-black btn-round text-black mt-4 mr-3">ACQUISTA</a>
                            <a *ngIf="openedSaggio?.link_download" target="_blank" [href]="openedSaggio?.link_download"
                               class="btn btn-outline-black btn-round text-black mt-4 mr-3">SCARICA PDF</a>
                            <a *ngIf="openedSaggio?.link_search" target="_blank" [href]="openedSaggio?.link_search"
                               class="btn btn-outline-black btn-round text-black mt-4 mr-3">CERCA</a>
                            <a *ngIf="openedSaggio?.link_preview" target="_blank" [href]="openedSaggio?.link_preview"
                               class="btn btn-success btn-round mt-4 ">ANTEPRIMA</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
