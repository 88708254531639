<div id="headers" class="header-4 cd-section">
    <div class="header-wrapper">

        <div class="page-header header-video">
            <div class="filter"></div>
            <!-- We show the video image placeholder instead of the video for small devices  -->
            <div class="video-image visible-xs visible-sm"
                 style="background-image: url('assets/img/storymaps/reggio/RE_1489.jpg')"></div>
            <div class="video-container">
                <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
                    <source src="assets/video/metano.mp4" type="video/mp4">
                    Video not supported
                </video>
            </div>
            <div class="content-center">
                <div class="container upper-container text-center">
                    <div class="video-text">
                        <h2>Storymap</h2>
                        <h1 class="title-uppercase title-no-upper-margin">L'avventura del metano</h1>
                        <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                                class="btn btn-outline-neutral btn-round mt-5">
                            <i class="fa fa-chevron-down"></i> Inizia il percorso
                        </button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 1-->
<!--<div class="cd-section section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url('assets/img/storymaps/genova/AMGA_Gavette.jpg');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="text-white">CAPITOLO 1</h3>
                            <h2 class="title text-white"> Una strada lunga due secoli</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<div class="section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 1</h3>
                            <h2 class="title "> Una strada lunga due secoli</h2>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong> 1778:</strong> Milano inaugura il teatro alla Scala, sull’isola di Zante
                                    nasce Ugo Foscolo e <strong>Alessandro Volta scopre il metano, dandogli il nome di
                                    “gas di palude”</strong>.</p>
                                <p>
                                    Poco più di un secolo, <strong>è il 1899, e Salsomaggiore è la prima città italiana
                                    a sperimentare</strong>, grazie all’abbondanza di metano nel sottosuolo, <strong>l’illuminazione
                                    pubblica a gas</strong>.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 2-->
<div class="cd-section section-white" id="capitolo2">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-gray">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <img src="assets/img/storymaps/reggio/RE_2501.jpg" alt="Metanizzazione" class="img-fluid">
                        </div>
                        <div class="col-md-6 ml-auto mr-auto text-left title">
                            <h3 class="">CAPITOLO 2</h3>
                            <h2 class="title "> Un’energia alternativa?</h2>
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong>Ma l’uso del gas di palude non resta confinato all’illuminazione
                                        pubblica.</strong> Salsomaggiore abbonda di metano e l’abbondanza a volte aguzza
                                    l’ingegno come la necessità. </p>
                                <p><strong>È il 1933 quando un tecnico delle terme, Giuseppe Gragnani, inventa un metodo
                                    per miscelare l’aria al metano</strong>, facendo così funzionare il motore che muove
                                    i tubi dei pozzi di acqua termale.
                                </p>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>
                                    Grazie alla sua intuizione, Carlo Padovani del Politecnico di Milano trova il modo
                                    di comprimerlo in bombole.
                                    <br><strong>Da lì a pochi anni</strong>, oltre a servire gli autobus di Milano,
                                    Piacenza, Cremona e altri centri dell’Emilia, <strong>il gas naturale comincia a
                                    correre verso la Lombardia nei primi metanodotti</strong>.
                                </p>
                                <p>
                                    <small><em>- AGAC – Archivio fotografico – Metanizzazione</em></small>
                                </p>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 3-->
<div class="cd-section section-white" id="capitolo3">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url('assets/img/storymaps/reggio/RE_1638.jpg');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="text-white">CAPITOLO 3</h3>
                            <h2 class="title text-white"> In tutte le case: dai primi metanodotti al gas naturale
                                domestico
                            </h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content text-white">
                                <p class="text-white">
                                    <strong class="text-white">Negli anni Sessanta e Settanta la rete italiana di
                                        metanodotti si
                                        quadruplica.</strong>
                                    <br>Negli stessi anni i comuni della provincia di Reggio, che nel 1974 costituiranno
                                    l’AGAC, lanciano una campagna perché tutti i centri abitati siano raggiunti
                                    capillarmente:<strong class="text-white"> è la metanizzazione</strong>.

                                </p>
                                <p>
                                    <small><em>- AGAC – Archivio fotografico – Metanizzazione</em></small>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">
                    <div class="row text-center article-content">
                        <div class="col-sm-4">
                            <p class="mb-4">L’intervento è imponente:<strong> si ammodernano gli impianti</strong>...
                                <br>
                            </p>
                            <img src="assets/img/storymaps/reggio/RE_1240.jpg" class="img-fluid" alt="">
                            <p>
                                <small><em>AGAC – Archivio fotografico – Metanizzazione</em></small>
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <img src="assets/img/storymaps/reggio/RE_1493.jpg" class="img-fluid" alt="">
                            <p class="mt-4">...e <strong>si coinvolge il territorio</strong> in una campagna informativa
                                e promozionale a tutto campo.</p>
                            <p>
                                <small><em>AGAC – Archivio fotografico – Metanizzazione</em></small>
                            </p>
                        </div>
                        <div class="col-sm-4">
                            <p class="mb-4"><strong>Il metano è in tutte le case di Reggio e provincia.</strong></p>
                            <img src="assets/img/storymaps/reggio/RE_1247.jpg" class="img-fluid" alt="">
                            <p>
                                <small><em>AGAC – Archivio fotografico – Metanizzazione</em></small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 4-->


<nav id="cd-vertical-nav">
    <ul>
        <li>
            <a data-number="1" (click)="smoothScroll('headers')">
                <span class="cd-dot"></span>
                <span class="cd-label">Introduzione</span>
            </a>
        </li>
        <li>
            <a data-number="2" (click)="smoothScroll('capitolo1')">
                <span class="cd-dot"></span>
                <span class="cd-label">Una strada lunga due secoli</span>
            </a>
        </li>
        <li>
            <a data-number="3" (click)="smoothScroll('capitolo2')">
                <span class="cd-dot"></span>
                <span class="cd-label">Un’energia alternativa?</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo3')">
                <span class="cd-dot"></span>
                <span class="cd-label">In tutte le case</span>
            </a>
        </li>
    </ul>
</nav>