import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-news-list',
    templateUrl: './news-list.component.html',
    styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
    cmsEndpoint = environment.cmsEndpoint;
    imgEndpoint = environment.imgEndpoint;
    newsList;
    order: string = 'item_date';

    constructor(private http: HttpClient) {
    }

    ngOnInit() {

        this.http.get<any>(this.cmsEndpoint + '/contents/news+work-in-progress?limit=1000&offset=0').subscribe(res => {
            this.newsList = res.data;
            console.log(this.newsList);

        })
    }

}
