<div id="headers" class="header-4 cd-section">
  <div class="header-wrapper">

    <div class="page-header header-video">
      <div class="filter"></div>
      <!-- We show the video image placeholder instead of the video for small devices  -->
      <div class="video-image visible-xs visible-sm"
           style="background-image: url('assets/img/storymaps/agnel-copertina-video.jpg')"></div>

      <div class="video-container">
        <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
          <source src="assets/video/IrenStoria_Storymap06_Slideinziale_Presidenti_1920px.mp4" type="video/mp4">
          Video not supported
        </video>
      </div>
      <div class="content-center">
        <div class="container upper-container text-center">
          <div class="video-text">
            <h2>CRONOLOGIA DEI PRESIDENTI</h2>
            <h1 class="title-uppercase title-no-upper-margin">I presidenti da AEM a IREN</h1>
            <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                    class="btn btn-outline-neutral btn-round mt-5">
              <i class="fa fa-chevron-down"></i> GUARDA LA TIMELINE
            </button>
          </div>
          <br/>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- CAPITOLO 1 -->
<div class="section-white" id="capitolo1">
  <div class="wrapper">
    <div class="main">
      <div class="section section-white">
        <div class="container">
          <div class="row">
            <div class="col-md-6 ml-auto mr-auto  title">
              <h2 class="title ">I presidenti dal 1907 ad oggi</h2>
            </div>
          </div>
          <div class="row pt-5 pb-5">
            <div class="col-md-6 ml-auto mr-auto">
              <div class="article-content">
                <p class="mb-2">
                  Ci sono tanti modi per raccontare un secolo di storia.
                  Ci sono fatti, storie, persone, volti noti e meno noti.
                  Tra questi una parte certamente significativa è formata dai <strong>Presidenti</strong>,
                  quelle persone che, a partire dalla fondazione dell’Azienda Elettrica Municipale di Torino
                  sino ad arrivare alla costituzione di IRIDE prima e del Gruppo Iren poi, <strong>hanno guidato l’Azienda
                  portandola anno dopo anno là dove è arrivata oggi</strong>. Ecco i loro nomi, una cronologia che ripercorre
                  più di cento anni di storia italiana.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id='timeline-embed' style="width: 100%; height: 700px; margin-bottom: 100px;"></div>