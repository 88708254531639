<div class="wrapper" style="min-height: 90vh">
    <div class="main">
        <div class="section mb-0 pb-0">
            <div class="container-fluid">

                <div class="row related-items">
                    <div class="col-sm-12 pt-4">
                        <div class="row mb-5">
                            <div class="col-sm-12">
                                <div class="badge-danger text-center p-3" *ngIf="errorAlertMessage">Si è verificato un
                                    errore nel caricamento dei dati. Si prega di ricaricare la pagina.<br>Se l'errore
                                    persiste riprovare tra qualche minuto.
                                </div>
                                <h2 class="text-center mt-5" *ngIf="!noResults">Risultati ricerca <span *ngIf="value">per: <br> <strong> "{{value}}"</strong></span></h2>
                                <h2 class="text-center mt-5" *ngIf="noResults">Nessun risultato trovato</h2>
                                <p class="text-center mt-2 text-primary"><a [routerLink]="['/ricerca']">Effettua una nuova ricerca</a></p>
                            </div>
                        </div>
                        <div class="section pt-4">
                            <div class="row">
                                <app-card-square class="col-sm-3"
                                                 [cardPreview]="card"
                                                 *ngFor="let card of returnedTree, let i = index">
                                </app-card-square>
                            </div>
                            <div class="row" *ngIf="!noResults">
                                <div class="col-md-12">
                                    <div class="pull-left">

                                        <button *ngIf="backwardActive"
                                                (click)="setOffset(backwardOffset)"
                                                class="btn btn-link btn-default btn-move-left btn-sm">
                                            <i class="fa fa-angle-left"></i> Indietro
                                        </button>
                                    </div>
                                    <div class="pull-right">

                                        <button *ngIf="forwardActive"
                                                (click)="setOffset(forwardOffset)"
                                            class="btn btn-link btn-default btn-move-right btn-sm"> Avanti
                                            <i class="fa fa-angle-right"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <p class="text-center">Schede {{itemsShown}}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



