import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
    news;

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        this.http.get('./assets/text/news-2019507.json').subscribe(res => {
            this.news = res;
        })
    }

}
