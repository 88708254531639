<div class="page-header" style="background-image: url('./assets/img/cover.jpg')">
    <div class="filter"></div>
    <div class="content-center">
        <div class="motto">
            <h1 class="text-center">Paper Kit 2 Pro</h1>
            <h3 class="text-center">Components</h3>
        </div>
    </div>
</div>
<div class="main">
    <app-basicelements></app-basicelements>
    <app-navigation></app-navigation>
    <app-prefooter-areas></app-prefooter-areas>
    <app-footers-areas></app-footers-areas>
    <app-description-areas></app-description-areas>
    <app-info-areas></app-info-areas>
    <div class="container">
        <div class="title">
            <h3>Navigation Pills</h3>
        </div>
        <div class="col-md-6">
            <ngb-tabset type="pills" class="nav-pills-danger">
                <ngb-tab title="Info">
                    <ng-template ngbTabContent>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Menu">
                    <ng-template ngbTabContent>
                    </ng-template>
                </ngb-tab>
                <ngb-tab title="Concept">
                    <ng-template ngbTabContent>
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
        <div class="title">
            <h3>Progress Bar</h3>
        </div>
        <div class="col-md-8">
            <ngb-progressbar type="success" [value]="25">25</ngb-progressbar><br>
            <ngb-progressbar type="info" [value]="50">Copying file <b>2 of 4</b>...</ngb-progressbar><br>
            <ngb-progressbar type="warning" [value]="75" [striped]="true" [animated]="true"><i>75%</i></ngb-progressbar><br>
            <ngb-progressbar type="danger" [value]="100" [striped]="true">Completed!</ngb-progressbar>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="title">
                    <h3>Pagination</h3>
                    <br/>
                </div>
                <ngb-pagination [collectionSize]="70" [(page)]="page" aria-label="Default pagination"></ngb-pagination>
                <br/>
                <ngb-pagination [collectionSize]="100" [(page)]="page1" [maxSize]="5" [rotate]="true"></ngb-pagination>
            </div>
        </div>
    </div>
    <app-typography></app-typography>
    <div class="section section-gray" id="notifications">
        <div class="container tim-container">
            <div class="title">
                <h3>Notifications</h3>
            </div>
        </div>
        <app-notification></app-notification>
    </div>
    <app-tables-areas></app-tables-areas>
    <app-comments-areas></app-comments-areas>
    <div class="section javascript-components">
        <div class="container">
            <div class="title">
                <h2>Angular Native Components</h2>
            </div>
            <div class="row" id="modals">
                <div class="col-md-12">
                    <div class="title">
                        <h3>Modals</h3>
                    </div>
                    <ngbd-modal-component></ngbd-modal-component>
                </div>
            </div>
            <div class="title">
                <h3>Collapse</h3>
            </div>
            <div class="row">
                <div class="col-md-8">
                    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
                        <ngb-panel id="config-panel-one">
                            <ng-template ngbPanelTitle>
                                <h5 class="mb-0 panel-title">
                                    <a class="collapsed">
                                        Default Collapsible Item 1
                                        <i class="nc-icon nc-minimal-down"></i>
                                    </a>
                                </h5>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                  Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                  aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                  sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                                  craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                                  occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                                  labore sustainable VHS.
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <h5 class="mb-0 panel-title">
                                    <a class="collapsed">
                                        Default Collapsible Item 2
                                        <i class="nc-icon nc-minimal-down"></i>
                                    </a>
                                </h5>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                                labore sustainable VHS.
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel>
                            <ng-template ngbPanelTitle>
                                <h5 class="mb-0 panel-title">
                                    <a class="collapsed">
                                        Default Collapsible Item 3
                                        <i class="nc-icon nc-minimal-down"></i>
                                    </a>
                                </h5>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia
                                aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica,
                                craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings
                                occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus
                                labore sustainable VHS.
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="title">
                <h3>Gallery</h3>
            </div>
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>

            <div class="clearfix"></div>

            <div class="col-md-12">
                <div class="title">
                    <h3>Tooltips</h3>
                </div>

                <button type="button" class="btn btn-warning btn-round" placement="right" ngbTooltip="Tooltip on right">
                   on right
               </button>
               <button type="button" class="btn btn-warning btn-round" placement="left" ngbTooltip="Tooltip on left">
                  on left
               </button>
                <button type="button" class="btn btn-warning btn-round" placement="top" ngbTooltip="Tooltip on top">
                   on top
                </button>
                <button type="button" class="btn btn-warning btn-round" placement="bottom" ngbTooltip="Tooltip on bottom">
                   on bottom
                </button>
            </div>
            <div class="col-md-12">
				<div class="title">
					<h3>Popovers</h3>
				</div>
                <button type="button" class="btn btn-round" placement="right"
                    ngbPopover="Here will be some very useful information about this popover." popoverTitle="Nude on right">
                    Nude on right
                </button>

                <button type="button" class="btn btn-round" placement="top"
                    ngbPopover="Here will be some very useful information about this popover." popoverTitle="Nude on top">
                    Nude on top
                </button>

                <button type="button" class="btn btn-round" placement="left"
                    ngbPopover="Here will be some very useful information about this popover." popoverTitle="Nude on left">
                    Nude on left
                </button>

                <button type="button" class="btn btn-round" placement="bottom"
                    ngbPopover="Here will be some very useful information about this popover." popoverTitle="Nude on bottom">
                    Nude on bottom
                </button>
			</div>

            <div class="col-md-12">
                <div class="title">
                    <h3>Dropup</h3>
                </div>
                <div id="special-drowdown">
                    <div class="row">
                        <div class="col-md-3 col-sm-6">
                            <h4 class="title"><small>Dropup with sharing</small></h4>
                            <div ngbDropdown placement="top-right" class="dropup dropup-no-caret">
                                <button class="btn btn-block btn-round" id="dropdownBasic1" ngbDropdownToggle>Sharing</button>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-sharing">
                                    <li class="dropdown-header text-center">Sharing is caring!</li>
                                    <a class="dropup-item">
                                        <div class="social-line">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <span class="icon-twitter"><i class="fa fa-twitter"></i></span>
                                                </div>
                                                <div class="col-sm-6">Twitter</div>
                                                <div class="col-sm-4">
                                                    <label>
                                                    <bSwitch
                                                        [switch-on-color]="'primary'"
                                                        [switch-off-color]="'primary'"
                                                        [(ngModel)]="state_icon_primary"
                                                        switch-on-text="<i class='nc-icon nc-check-2'></i>"
                                                        switch-off-text="<i class='nc-icon nc-simple-remove'></i>">
                                                    </bSwitch>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropup-item">
                                        <div class="social-line">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <span class="icon-facebook"><i class="fa fa-facebook"></i></span>
                                                </div>
                                                <div class="col-sm-6">Facebook</div>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropup-item">
                                        <div class="social-line">
                                            <div class="row">
                                                <div class="col-sm-2">
                                                    <span class="icon-pinterest"><i class="fa fa-pinterest"></i></span>
                                                </div>
                                                <div class="col-sm-6">Pinterest</div>
                                            </div>
                                        </div>
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropup-item text-center" href="#paper-kit">
                                        <button class="btn btn-info btn-round ml-auto mr-auto">Share</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-6">
                            <h4 class="title"><small>Dropup with actions</small></h4>

                            <div class="dropup">

                              <div ngbDropdown placement="top-right" class="dropup dropup-no-caret">
                                  <button class="btn btn-block btn-round" ngbDropdownToggle>
                                    Actions
                                  </button>
                                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                      <a class="dropdown-item">
                                          <div href="#paper-kit" class="action-line">
                                              <div class="row">
                                                  <div class="col-sm-2">
                                                      <span class="icon-simple"><i class="nc-icon nc-single-copy-04"></i></span>
                                                  </div>
                                                  <div class="col-sm-9">Duplicate</div>
                                              </div>
                                          </div>
                                      </a>
                                      <div class="dropdown-divider"></div>
                                      <a class="dropdown-item">
                                          <div href="#paper-kit" class="action-line">
                                              <div class="row">
                                                  <div class="col-sm-2">
                                                      <span class="icon-simple"><i class="nc-icon nc-user-run"></i></span>
                                                  </div>
                                                  <div class="col-sm-9">Move from here</div>
                                              </div>
                                          </div>
                                      </a>
                                      <div class="dropdown-divider"></div>
                                      <a class="dropdown-item">
                                          <div href="#paper-kit" class="action-line link-danger">
                                              <div class="row">
                                                  <div class="col-sm-2">
                                                      <span class="icon-simple"><i class="nc-icon nc-box-2"></i></span>
                                                  </div>
                                                  <div class="col-sm-9">Archive</div>
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
				<div class="title">
					<h3>Datepicker</h3>
				</div>
			    <div class="row">
			        <div class='col-sm-6'>
			            <div class="form-group">
			                <div class='input-group date'>
                                <input class="form-control" placeholder="06/07/2017"
                                     name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">
                                <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-calendar"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                                </span>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
            <div class="tim-tile">
                <h3>File Upload</h3>
            </div>

            <div class="title">
				<h3>Carousel</h3>
			</div>
            <div class="row">
				<div class="col-md-8 ml-auto mr-auto">
					<div class="card card-raised page-carousel">
                        <ngb-carousel>
                            <ng-template ngbSlide>
                                <img src="http://www.hudsonmgmt.com/hms/images/slider-img-1.jpg" alt="Random first slide">
                                <div class="carousel-caption">
                                    <p>Somewhere</p>
                                </div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <img src="http://96bda424cfcc34d9dd1a-0a7f10f87519dba22d2dbc6233a731e5.r41.cf2.rackcdn.com/ermu/sliders-1/fall-residential-faders/Fall_Fader2.jpg" alt="Random second slide">
                                <div class="carousel-caption">
                                    <p>Somewhere else</p>
                                </div>
                            </ng-template>
                            <ng-template ngbSlide>
                                <img src="http://www.jveroassociates.com/images/slider/slider-02.jpg" alt="Random third slide">
                                <div class="carousel-caption">
                                    <p>Here it is</p>
                                </div>
                            </ng-template>
                        </ngb-carousel>
					</div>
				</div>
			</div>
        </div>
    </div>
    <app-cards-areas></app-cards-areas>
</div>
<footer class="footer section-dark">
    <div class="container">
        <div class="row">
            <nav class="footer-nav">
                <ul>
                    <li><a href="https://www.creative-tim.com">Creative Tim</a></li>
                    <li><a href="http://blog.creative-tim.com">Blog</a></li>
                    <li><a href="https://www.creative-tim.com/license">Licenses</a></li>
                </ul>
            </nav>
            <div class="credits ml-auto">
                <span class="copyright">
                    © {{data | date: 'yyyy'}}, made with <i class="fa fa-heart heart"></i> by Creative Tim
                </span>
            </div>
        </div>
    </div>
</footer>
