

    <div class="card">
        <!-- <div [class.card-image]="cardPreview.preciseDate || cardPreview.DateElement.Label">-->
        <div class="card-image" [routerLink]="[link + cardPreview.Id]">
            <div class="card-links">
                <span [routerLink]="['/esplora', cardPreview.Id]">
                    <i class="fas fa-folders" *ngIf="cardPreview.Type === 'SL'"></i>
                </span>
                <span [routerLink]="['/scheda', cardPreview.Id]">
                    <i class="fas fa-eye" *ngIf="cardPreview.Type === 'SL' && cardPreview.ReturnSchedaIsMandatory"></i>
                </span>
            </div>
            <img *ngIf="cardPreview.MediumUrl; else showPlaceholder"
                 [defaultImage]="'assets/img/staticImages/imgLoading.gif'"
                 [lazyLoad]="cardPreview.MediumUrl"
                 [offset]="100" class="img">

            <ng-template #showPlaceholder>
                <img class="img"
                     src="assets/img/staticImages/placeholder.jpg">
            </ng-template>

        </div>
        <div class="card-body">
            <!--<label class="label label-warning">Travel</label>-->
            <h5 class="card-title">
                {{cardPreview.AssignedTitle}}
            </h5>
            <hr *ngIf="cardPreview.preciseDate || cardPreview.DateElement.Label"/>
            <div class="card-footer" *ngIf="cardPreview.preciseDate || cardPreview.DateElement.Label">
                <div class="stats">
                    <i class="fal fa-calendar-alt mr-2"></i> {{cardPreview.PreciseDate}}
                    {{cardPreview.DateElement.FromYear}}<span *ngIf="cardPreview.DateElement.Label && cardPreview.DateElement.ToYear != '0'"> -
                    {{cardPreview.DateElement.ToYear}}</span>
                </div>
            </div>
        </div>
    </div>

