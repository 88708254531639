import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ApiService} from '../../../../services/api.service';
import {map} from 'rxjs/operators';


let easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};


@Component({
    selector: 'app-genova',
    templateUrl: './genova.component.html',
    styleUrls: ['./genova.component.scss']
})
export class GenovaComponent implements OnInit, AfterViewInit {
    icon_video_button_class: string = 'fa-play';
    text_video_button: string = 'Play Video';

    returnedTree;


    s14501;
    s14496;
    s14500;
    s14502;

    constructor(router: Router, public _api: ApiService) {
        router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = router.parseUrl(router.url);
                if (tree.fragment) {
                    const element = document.querySelector('#' + tree.fragment);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
            }
        });
    }


    ngOnInit() {

        let id_video = document.getElementById('video-source') as HTMLVideoElement;
        id_video.play();
        this.text_video_button = 'Pause Video';
        this.icon_video_button_class = 'fa-pause';

        this._api.ReturnKeyWordsSearchResults('Storymap', 'Guerra_Genova', '', 0, 100)
            .pipe(map((res: any) => res.ReturnedTree)).subscribe(data => {
            this.returnedTree = data;

            this.s14501 = this.returnedTree[5];
            this.s14496 = this.returnedTree[0];
            this.s14500 = this.returnedTree[4];
            this.s14502 = this.returnedTree[6];
            console.log(this.returnedTree);

        });
    }


    @HostListener('window:scroll', ['$event'])
    updateNavigation() {
        let contentSections = document.getElementsByClassName('cd-section') as HTMLCollectionOf<any>;
        let navigationItems = document.getElementById('cd-vertical-nav').getElementsByTagName('a');

        for (let i = 0; i < contentSections.length; i++) {
            let activeSection: any = parseInt(navigationItems[i].getAttribute('data-number')) - 1;

            if ((contentSections[i].offsetTop - window.innerHeight / 2 < window.pageYOffset)
                && (contentSections[i].offsetTop + contentSections[i].scrollHeight - window.innerHeight / 2 > window.pageYOffset)) {
                navigationItems[activeSection].classList.add('is-selected');
            } else {
                navigationItems[activeSection].classList.remove('is-selected');
            }
        }
    }

    smoothScroll(target) {
        let targetScroll = document.getElementById(target);
        this.scrollTo(document.documentElement, targetScroll.offsetTop, 1250);
    }

    scrollTo(element, to, duration) {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            let val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    ngAfterViewInit() {
        this.updateNavigation();
    }


}
