import {AfterViewInit, Component, OnInit} from '@angular/core';

let easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};


@Component({
    selector: 'app-presidenti',
    templateUrl: './presidenti.component.html',
    styleUrls: ['./presidenti.component.scss']
})

export class PresidentiComponent implements OnInit, AfterViewInit {
    icon_video_button_class: string = 'fa-play';
    text_video_button: string = 'Play Video';

    timeline;
    TL;

    constructor() {
    }

    ngOnInit() {
        let id_video = document.getElementById('video-source') as HTMLVideoElement;
        id_video.play();
        this.text_video_button = 'Pause Video';
        this.icon_video_button_class = 'fa-pause';
    }

    ngAfterViewInit() {
        // @ts-ignore
        timeline = new TL.Timeline('timeline-embed',
            'https://docs.google.com/spreadsheets/d/1-mnGbNVTBU5Sx8xWGanW6amBJGb0PBxpeNMcFnv5PWA');
    }

    smoothScroll(target) {
        let targetScroll = document.getElementById(target);
        this.scrollTo(document.documentElement, targetScroll.offsetTop, 1250);
    }

    scrollTo(element, to, duration) {
        let start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            let val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

}
