<div class="section section-gray">
    <div class="container tim-container">
        <div class="title">
            <h3>Tables</h3>
        </div>
        <h4>Simple Table</h4>
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
                <h4 class="title">
                    <small>Simple With Actions</small>
                </h4>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                              <th class="text-center">{{ tableData1.headerRow[0] }}</th>
                              <th>{{ tableData1.headerRow[1] }}</th>
                              <th>{{ tableData1.headerRow[2] }}</th>
                              <th class="text-right">{{ tableData1.headerRow[4] }}</th>
                              <th class="text-right">{{ tableData1.headerRow[5] }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of tableData1.dataRows">
                                <td class="text-center">{{row[0]}}</td>
                                <td>{{row[1]}}</td>
                                <td>{{row[2]}}</td>
                                <td class="text-right">&euro; {{row[4]}}</td>
                                <td class="td-actions text-right">
                                    <button type="button" ngbTooltip="View Profile" class="btn btn-info btn-link btn-sm">
                                        <i class="fa fa-user"></i>
                                    </button>
                                    <button type="button" ngbTooltip="Edit Profile" class="btn btn-success btn-link btn-sm">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                    <button type="button" ngbTooltip="Remove" class="btn btn-danger btn-link btn-sm">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h4 class="title">
                    <small>Striped With Checkboxes</small>
                </h4>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">{{ tableData2.headerRow[0] }}</th>
                                <th></th>
                                <th>{{ tableData2.headerRow[1] }}</th>
                                <th>{{ tableData2.headerRow[2] }}</th>
                                <th>{{ tableData2.headerRow[3] }}</th>
                                <th class="text-right">{{ tableData2.headerRow[4] }}</th>
                                <th class="text-right">{{ tableData2.headerRow[5] }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of tableData2.dataRows">
                                <td class="text-center">{{row.id}}</td>
                                <td>
                                    <div [ngSwitch]="row.check">
                                        <div *ngSwitchCase="true">
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" value="" checked>
                                                    <span class="form-check-sign"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div *ngSwitchDefault>
                                            <div class="form-check">
                                                <label class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" value="" >
                                                    <span class="form-check-sign"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{row.product_name}}</td>
                                <td>{{row.type}}</td>
                                <td>{{row.qty}}</td>
                                <td class="text-right">&euro; {{row.price}}</td>
                                <td class="text-right">&euro; {{row.amount}}</td>
                            </tr>
                            <tr>
                                <td colspan="5"></td>
                                <td class="td-total">
                                    Total
                                </td>
                                <td class="td-price">
                                    {{getTotal1()| currency:'EUR':true:'1.2-2'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h4 class="title">Shopping Cart Table</h4>
            </div>
            <div class="col-md-10 ml-auto mr-auto">
                <div class="table-responsive">
                    <table class="table table-shopping">
                        <thead>
                            <tr>
                                <th class="text-center"></th>
                                <th></th>
                                <th class="text-right">{{ tableData3.headerRow[2] }}</th>
                                <th class="text-right">{{ tableData3.headerRow[3] }}</th>
                                <th class="text-right">{{ tableData3.headerRow[4] }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of tableData3.dataRows">
                                <td>
                                    <div class="img-container">
                                        <img src="./assets/img/{{row[0]}}" alt="...">
                                    </div>
                                </td>
                                <td class="td-product">
                                    <strong>{{row[1]}}</strong>
                                    <p>{{row[2]}}</p>
                                </td>
                                <td class="td-price">
                                    <small>&euro;</small>{{row[3]}}
                                </td>
                                <td class="td-number td-quantity">
                                    {{row[4]}}
                                    <div class="btn-group">
                                        <button class="btn btn-sm btn-border btn-round"> - </button>
                                        <button class="btn btn-sm btn-border btn-round"> + </button>
                                    </div>
                                </td>
                                <td class="td-number">
                                    <small>&euro;</small>{{row[5]}}
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2"></td>
                                <td></td>
                                  <td class="td-total">
                                    Total
                                </td>
                                <td class="td-total">
                                    {{getTotal2()| currency:'EUR':true:'1.2-2'}}
                                </td>
                            </tr>
                            <tr class="tr-actions">
                               <td colspan="3"></td>
                               <td colspan="2" class="text-right">
                                   <button type="button" class="btn btn-danger btn-lg" (click)="showElements()">Complete Purchase <i class="fa fa-chevron-right"></i></button>
                               </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
