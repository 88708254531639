<div class="wrapper">
    <div class="main">
        <div class="section mb-0 pb-0 pt-5">
            <div class="container-fluid">
                <div class="row related-items">
                    
                    <!--Filtri-->
                    <div class="col-sm-3 filter-block pt-5 sidebar">
                        <h4 class="card-title"><i class="fas fa-sitemap text-warning pl-1 pr-2"></i> Alberatura</h4>

                        <ul id="sidebarTree">
                            <ng-template #recursiveList let-list>
                                <li *ngFor="let item of list">
<!--
                                    <i class="fas fa-folder" (click)="onNavigateOnTree(item)" *ngIf="item.type==='SL' && item.children.length===0"></i>
                                    <i class="fas fa-folder-open" (click)="onNavigateOnTree(item)" *ngIf="item.type==='SL' && item.children.length>0"></i>
                                    <i class="fas fa-file-alt" (click)="onNavigateOnTree(item)" *ngIf="item.type==='UA'"></i>
-->
                                    <i class="far fa-plus-square" (click)="onNavigateOnTree(item)" *ngIf="item.type==='SL' && item.children.length===0"></i>
                                    <i class="far fa-minus-square" (click)="onNavigateOnTree(item)" *ngIf="item.type==='SL' && item.children.length>0"></i>
                                    <i class="fas fa-file-alt" (click)="onNavigateOnTree(item)" *ngIf="item.type==='UA'"></i>
                                    <span [routerLink]="['/scheda',item.id]" class="sl-with-content" *ngIf="item.type==='SL' && item.returnSchedaIsMandatory ">
                                       <!-- <i class="fas fa-eye" (click)="console.log('')"></i>-->
                                         <i class="fas fa-file-import" (click)="console.log('')"></i>
                                    </span>
                                    <span [routerLink]="['/esplora',item.id]" routerLinkActive="router-link-active" *ngIf="item.type==='SL'" [class.bold]="item.children.length>0"> {{item.title}}</span>
                                    <span [routerLink]="['/scheda',item.id]" routerLinkActive="router-link-active" *ngIf="item.type==='UA'"> {{item.title}}</span>
                                    <ul *ngIf="item.children.length > 0">
                                        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
                                    </ul>
                                </li>
                            </ng-template>
                            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: list }"></ng-container>
                        </ul>



                        <!-- <h4 class="card-title"><i class="far fa-filter text-warning pl-1"></i> FILTRI</h4>
                        <hr>
                        <ngb-accordion #acc="ngbAccordion">
                            <ngb-panel id="config-panel-one">
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Struttura Archivistica
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    
                                        <ul id="sidebarTree">
                                            <ng-template #recursiveList let-list>
                                                <li *ngFor="let item of list">
                                                    <i class="fas fa-folder" (click)="onNavigateOnTree(item)" *ngIf="item.type==='SL' && item.children.length===0"></i>
                                                    <i class="fas fa-folder-open" (click)="onNavigateOnTree(item)" *ngIf="item.type==='SL' && item.children.length>0"></i>
                                                    <i class="fas fa-file-alt" (click)="onNavigateOnTree(item)" *ngIf="item.type==='UA'"></i>
                                                    <span [routerLink]="['/esplora',item.id]" routerLinkActive="router-link-active" *ngIf="item.type==='SL'"> {{item.title}}</span>
                                                    <span [routerLink]="['/scheda',item.id]" routerLinkActive="router-link-active" *ngIf="item.type==='UA'"> {{item.title}}</span>
                                                    <ul *ngIf="item.children.length > 0">
                                                        <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
                                                    </ul>
                                                </li>
                                            </ng-template>
                                            <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: list }"></ng-container>
                                        </ul>

                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Cronologia
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <nouislider #slider [config]="dateFilter"
                                                [(ngModel)]="sliderRange"></nouislider>
                                    <button class="btn btn-round btn-success btn-sm mt-3" (click)="dates(sliderRange)">
                                        Filtra
                                    </button>
                                </ng-template>
                            </ngb-panel>
                            <ngb-panel>
                                <ng-template ngbPanelTitle>
                                    <h5 class="mb-0 panel-title">
                                        <a class="collapsed">
                                            Tipologie documentarie
                                            <i class="nc-icon nc-minimal-down"></i>
                                        </a>
                                    </h5>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <form [formGroup]="filterByDocumentTypeData" (ngSubmit)="filterByDocumentType()">
                                        <select class="custom-select" formControlName="DocumentTypeValue">
                                            <option [value]="null" disabled selected> Seleziona una Tipologia</option>
                                            <option [value]="item.DocumentTypeValue"
                                                    *ngFor="let item of documentTypeList">{{item.DocumentTypeValue}}
                                            </option>
                                        </select>
                                        <button type="submit" class="btn btn-round btn-success btn-sm mt-2 ml-2">Filtra
                                        </button>
                                    </form>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion> -->


                    </div>
                    <!--Fine filtri-->
                    <div class="col-sm-9 pt-4">
                        <div class="row mb-1">
                            <div class="col-sm-12">
                                <h3 class="mt-5 pb-0 mb-0">Esplora l'archivio</h3>
                                <div class="badge-danger text-center p-3" *ngIf="errorAlertMessage">Si è verificato un
                                    errore nel caricamento dei dati. Si prega di ricaricare la pagina.<br>Se l'errore
                                    persiste riprovare tra qualche minuto.
                                </div>
                                <h5>
                                    <em>
                                        <ul class="serie">
                                            <li><a class="linkSerie"
                                                   [routerLink]="['/esplora/']">Archivio</a></li>
                                            <li *ngFor="let leaf of parentTree, let last = last">
                                                <a class="linkSerie"
                                                   [routerLink]="['/esplora/' + leaf.Id]">
                                                    {{leaf.AssignedTitle}}
                                                </a>
                                            </li>
                                        </ul>
                                    </em>
                                </h5>
                            </div>
                        </div>
                        <div class="section pt-4">
                            <div class="row">
                                <app-card-square class="col-sm-6 col-md-4 col-lg-3" [cardPreview]="card"
                                                 *ngFor="let card of returnedTree, let i = index">
                                </app-card-square>


                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
