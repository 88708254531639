import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import {registerLocaleData} from '@angular/common';
import localeIT from '@angular/common/locales/it'
import { AppComponent } from './app.component';
import { PresentationComponent } from './pages/home/presentation.component';
import { NavbarComponent } from './shared/navbar/navbar.component';

import { PagesModule } from './pages/pages.module';
import {AuthService} from './auth/auth.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './auth/auth-interceptor';
import {ApiService} from './services/api.service';
import {CommonService} from './services/common.service';
import {ArchiveModule} from './archive/archive.module';
import { TimelineComponent } from './timeline/timeline.component';
import {OrderModule} from 'ngx-order-pipe';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { FooterComponent } from './shared/footer/footer.component';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {MomentModule} from 'ngx-moment';
import { NewsComponent } from './news/news.component';
import { WipComponent } from './wip/wip.component';
import {Ng2OdometerModule} from 'ng2-odometer';
import {CardSquareComponent} from './archive/components/card-square/card-square.component';
import {MonthPipe} from './services/monthPipe';
import {CookieLawModule} from 'angular2-cookie-law';
import { EmbedVideo } from 'ngx-embed-video';

export function loginServiceFactory(loginService: AuthService): Function {
    return () => loginService.login();
}

registerLocaleData(localeIT);

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        TimelineComponent,
        FooterComponent,
        NewsComponent,
        WipComponent,
        MonthPipe
    ],
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule.forRoot(),
        Ng2OdometerModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        AppRoutingModule,
        PagesModule,
        ComponentsModule,
        ArchiveModule,
        OrderModule,
        LazyLoadImageModule,
        NgxPageScrollModule,
        MomentModule,
        CookieLawModule
    ],
    providers: [ AuthService,
        {
            // Provider for APP_INITIALIZER
            provide: APP_INITIALIZER,
            useFactory: loginServiceFactory,
            deps: [AuthService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {provide: LOCALE_ID, useValue: 'it'},
    ApiService,
    CommonService,
        MonthPipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
