<div id="headers" class="header-4 cd-section">
    <div class="header-wrapper">

        <div class="page-header header-video">
            <div class="filter"></div>
            <!-- We show the video image placeholder instead of the video for small devices  -->
            <div class="video-image visible-xs visible-sm"
                 style="background-image: url('assets/img/storymaps/agnel-copertina-video.jpg')"></div>
            <div class="video-container">
                <video id="video-source" preload="auto" loop="loop" muted="muted" muted autoplay volume="0">
                    <source src="assets/video/genova.mp4" type="video/mp4">
                    Video not supported
                </video>
            </div>
            <div class="content-center">
                <div class="container upper-container text-center">
                    <div class="video-text">
                        <h2>Storymap</h2>
                        <h1 class="title-uppercase title-no-upper-margin">Sopravvivere alla guerra</h1>
                        <button type="button" data-number="2" (click)="smoothScroll('capitolo1')"
                                class="btn btn-outline-neutral btn-round mt-5">
                            <i class="fa fa-chevron-down"></i> Inizia il percorso
                        </button>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 1-->
<div class="cd-section section-white" id="capitolo1">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url('assets/img/storymaps/genova/AMGA_Gavette.jpg');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="text-white">CAPITOLO 1</h3>
                            <h2 class="title text-white"> Il fuoco che cade dal cielo
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    <strong> Non conta chi comincia. La guerra è dura e dolorosa</strong>, e a pagare il
                                    conto è sempre chi sta sotto.</p>
                                <p>È una notte come tante, quella del <strong>22 ottobre 1942</strong>, su Genova come
                                    sul resto di un’Europa in fiamme.
                                    <br>
                                    In quella notte, <strong>un violento bombardamento colpisce la Superba</strong> con
                                    centinaia di ordigni e tizzoni incendiari. Nella distruzione cupa, <strong>anche la
                                        sede dell’AMGA è colpita a morte</strong>: una bomba la colpisce, provocando un
                                    incendio devastante che in poche ore porta via una vita di lavoro.

                                </p>
                                <p>
                                    <small><em>- Verbale Commissione Amministratrice 11 dicembre 1942</em>
                                    </small>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s14501?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14501?.AssignedTitle}}</h5>
                                    <p>{{s14501?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 2-->
<div class="cd-section section-white" id="capitolo2">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url('assets/img/storymaps/genova/GE_FOTO_guerra.jpg');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="text-white">CAPITOLO 2</h3>
                            <h2 class="title text-white"> Attaccati al lavoro, attaccati alla vita
                            </h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content text-white">
                                <p>
                                    <strong class="text-white">Colpire le infrastrutture per piegare il nemico: è
                                        l’amara legge della
                                        guerra.</strong> E già prima di quella notte, le bombe avevano distrutto due
                                    gasometri a Sampierdarena, danneggiati altri due a Cornigliano, reso inservibili
                                    numerosissime tubature e colonne portanti. </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Ma quella notte… <strong> Quella notte a essere colpito è il cuore</strong> il
                                    centro decisionale, il luogo dove – tra tante difficoltà – può in qualche modo
                                    essere pensato e messo in opera il servizio ai cittadini.
                                </p>
                                <p>
                                    Ed è in quella notte che emerge il coraggio, l’indole, la corsa a salvare ciò che si
                                    ama di chi l’azienda l’ha costruita con la propria fatica quotidiana.<strong> Sono
                                    tantissime le storie personali che in quei giorni intrecciano il disastro, tentando
                                    di salvare l’insalvabile.</strong> “Il giorno 24, nonostante l’imminente pericolo di
                                    crollo del soffitto e del pavimento della sala, il Direttore Generale Cav. Dott.
                                    Ing. Giovanni Ongaro, il Cassiere Signor Enrico Grimaldi ed il Vice Cassiere Sig.
                                    Rag. Guido Caproni, assistiti dai fattorini di Cassa sono riusciti ad aprire la
                                    cassaforte ed a raccoglierne il contenuto in una valigia e in diversi sacchi”.
                                </p>
                                <p>
                                    <small><em>- Verbale Commissione Amministratrice 11 dicembre 1942</em>
                                    </small>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s14501?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14501?.AssignedTitle}}</h5>
                                    <p>{{s14501?.Content}}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Ma non solo loro: “al mattino del 23 il personale, prodigandosi con chiara prova di
                                    attaccamento all’Azienda” si pone “al ricupero di quanto si era potuto salvare dal
                                    disastro, mentre ancora il fuoco continuava la sua opera di distruzione”.

                                </p>
                                <p>
                                    <small><em>- Verbale Commissione Amministratrice 11 dicembre 1942</em>
                                    </small>
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 3-->
<div class="cd-section section-white" id="capitolo3">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 style="background: url('assets/img/storymaps/genova/AMGA_Gasometro.jpg');">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="text-white">CAPITOLO 3</h3>
                            <h2 class="title text-white"> Un’azienda senza opera?
                            </h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content text-white">
                                <p>
                                    Ma la guerra incrudelisce ancora e non bastano volontà e dedizione. Il 3 aprile del
                                    1945, “a seguito della mancanza di fossile e di coke”, cessa “la produzione del gas
                                    e la sua distribuzione a quelle scarse utenze cui veniva ancora fornito”. </p>
                                <p>
                                    <small><em>- Verbale Commissione Amministratrice 17 dicembre 1945</em>
                                    </small>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Pochi giorni più tardi, il 30 aprile, “considerato che la Commissione Amministratrice dell’Azienda Municipalizzata Gas e Acqua non è più in grado di funzionare regolarmente” si decide di “addivenire allo scioglimento della stessa”
                                </p>

                                <p>
                                    <small><em>- Estratto dai verbali dei decreti del sindaco 30 aprile 1945 n. 12</em>
                                    </small>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s14496?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14496?.AssignedTitle}}</h5>
                                    <p>{{s14496?.Content}}</p>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--CAPITOLO 4-->

<div class="cd-section section-white" id="capitolo4">
    <div class="wrapper">
        <div class="main filter">
            <div class="section section-white section-background"
                 >
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">
                            <h3 class="">CAPITOLO 4</h3>
                            <h2 class="title"> Una lenta ripresa
                            </h2>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p>
                                    La guerra è finita. Giorno dopo giorno, si cerca la strada per tornare alla normalità. Lo fanno le persone, lo fa AMGA, che nell’ottobre del 1945 può finalmente prevedere di riprendere le forniture.
                                </p>
                                <p>
                                    <small><em>- Deliberazione del Commissario Delegato del 23 ottobre 1945 n. 55</em>
                                    </small>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section-white bg-image-storymap"
     style="background: url('assets/img/storymaps/genova/AMGA_Lettera-utenti-1945.jpg'); background-size: cover"></div>
<div class="section-white">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white">
                <div class="container">

                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    Durante tutto il mese di ottobre vengono fatti ingenti acquisti di carbon fossile e di coke.
                                </p>

                                <p>
                                    <small><em>- Deliberazione del Commissario Delegato del 19 dicembre 1945 n. 96</em>
                                    </small>
                                </p>

                            </div>
                            <div class="article-content">
                                <p class="mb-2">
                                    E il 3 novembre, finalmente, gli uffici possono scrivere agli utenti annunciando l’imminente ripresa del servizio.
                                </p>

                                <p>
                                    <small><em>- Lettera agli utenti, 3 novembre 1945</em>
                                    </small>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s14496?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14496?.AssignedTitle}}</h5>
                                    <p>{{s14496?.Content}}</p>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s14502?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14502?.AssignedTitle}}</h5>
                                    <p>{{s14502?.Content}}</p>
                                </div>
                            </a>
                        </div>

                    </div>
                    <div class="row pt-5 pb-5">
                        <div class="col-md-6 ml-auto mr-auto">
                            <div class="article-content">
                                <p class="mb-2">
                                    È la normalità che torna a poco a poco, nei gesti quotidiani e semplici. Anche un pranzo offerto dall’azienda a tutti i dipendenti per festeggiare la ripresa del servizio può così significare molto di più: il ritorno a un ritmo umano, a un ritmo di pace.

                                </p>

                                <p>
                                    <small><em>- Deliberazione del Commissario Delegato del 12 febbraio 1946 n. 119</em>
                                    </small>
                                </p>

                            </div>

                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-sm-6 ">
                            <a [routerLink]="['/scheda/' + s14500?.Id]">
                                <div class="border">

                                    <h5 class="mt-2 font-weight-bold">{{s14500?.AssignedTitle}}</h5>
                                    <p>{{s14500?.Content}}</p>
                                </div>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="section-white mb-0">
    <div class="wrapper">
        <div class="main">
            <div class="section section-white section-background"
                 style="background: url(assets/img/storymaps/genova/GE_finale.jpg);">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 ml-auto mr-auto text-center title">

                            <h2 class="title font-weight-bold text-white"> Scopri tutti i documenti relativi a questa storymap</h2>
                            <a class="btn btn-neutral btn-round videoBtn mt-4"
                               href="ricerca/risultati?searchType=Keyword&type=Storymap&value=Guerra_Genova">Visualizza tutte le
                                schede</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<nav id="cd-vertical-nav">
    <ul>
        <li>
            <a data-number="1" (click)="smoothScroll('headers')">
                <span class="cd-dot"></span>
                <span class="cd-label">Introduzione</span>
            </a>
        </li>
        <li>
            <a data-number="2" (click)="smoothScroll('capitolo1')">
                <span class="cd-dot"></span>
                <span class="cd-label">Il fuoco che cade dal cielo</span>
            </a>
        </li>
        <li>
            <a data-number="3" (click)="smoothScroll('capitolo2')">
                <span class="cd-dot"></span>
                <span class="cd-label">Attaccati al lavoro, attaccati alla vita</span>
            </a>
        </li>
        <li>
            <a data-number="4" (click)="smoothScroll('capitolo3')">
                <span class="cd-dot"></span>
                <span class="cd-label">Un’azienda senza opera?
</span>
            </a>
        </li>
        <li>
            <a data-number="5" (click)="smoothScroll('capitolo4')">
                <span class="cd-dot"></span>
                <span class="cd-label">Una lenta ripresa</span>
            </a>
        </li>
    </ul>
</nav>