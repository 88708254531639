<div class="wrapper">
    <div class="main">

        <div class="projects-2 section ">

            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">

                        <h1 class="title">Le novità di IrenStoria</h1>

                    </div>
                </div>
                <div class="space-top"></div>
                <div class="row">
                    <div class="col-md-4 text-center" *ngFor="let news of newsList | orderBy: order:'reverse'">
                        <div class="card card-plain">
                            <div class="card-img-top">
                                <a [routerLink]="['/news/' + news?.type.alias +'/' +  news?.alias]">
                                    <img class="img" [src]="imgEndpoint + '/' + news.featured_image"/>
                                </a>
                            </div>
                            <div class="card-body">
                                <h6 class="card-category text-muted">{{news?.type.alias | replaceDash}}</h6>
                                <a [routerLink]="['/news/'+ news?.type.alias +'/' + news?.alias]"><h4 class="card-title">{{news?.title}}</h4></a>

                                <p class="card-description">
                                    {{news?.abstract}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>