import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExploreComponent} from './explore/explore.component';
import {CardPreviewComponent} from './components/card-preview/card-preview.component';
import {RouterModule} from '@angular/router';
import { ComponentsComponent } from './components/components.component';
import { SingleCardComponent } from './components/single-card/single-card.component';
import {NgxGalleryModule} from 'ngx-gallery';
import { SearchComponent } from './search/search.component';
import { SearchformComponent } from './searchform/searchform.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OrderModule} from 'ngx-order-pipe';
import {TreeModule} from 'angular-tree-component';
import { CardSquareComponent } from './components/card-square/card-square.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NouisliderModule} from 'ng2-nouislider';
import {EmbedVideo} from 'ngx-embed-video/dist';
import {MonthPipe} from '../services/monthPipe';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxGalleryModule,
        NgbModule,
        TreeModule.forRoot(),
        LazyLoadImageModule,
        FormsModule,
        ReactiveFormsModule,
        NouisliderModule,
        EmbedVideo.forRoot(),
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        ExploreComponent,
        CardPreviewComponent,
        ComponentsComponent,
        SingleCardComponent,
        SearchComponent,
        SearchformComponent,
        CardSquareComponent
    ],
    exports: []
})
export class ArchiveModule {
}
