import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbDateCustomParserFormatter} from '../../filters/dateformat'
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '../../services/api.service';
import * as _ from 'lodash';
import {map} from 'rxjs/operators';
import {CommonService} from '../../services/common.service';

@Component({
    selector: 'app-searchform',
    templateUrl: './searchform.component.html',
    styleUrls: ['./searchform.component.scss'],
    providers: [
        {provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter}
    ]
})
export class SearchformComponent implements OnInit {

    minDate = {year: 1900, month: 1, day: 1};
    maxDate;
    minDate2 = {year: 1900, month: 1, day: 2};
    now = new Date();

    returnedTags;
    tags;

    indexes;
    errorAlertMessage: boolean = false;

    hideIndexChildren = true;
    indexType;
    enti;
    luoghi;
    persone;

    documentTypeList;
    searchCriteria;
    returnedTree;
    showAdvancedSearch = true;

    filterByDocumentTypeData = new FormGroup({
        DocumentTypeValue: new FormControl(null)
    });



    ricercaSemplice = new FormGroup({
        elementoRicercato: new FormControl('')

    });


    ricercaAvanzata = new FormGroup({
        titolo: new FormControl(''),
        contenuto: new FormControl(''),
        dataInizio: new FormControl(''),
        dataFine: new FormControl('')
    });

    ricercaTag = new FormGroup({
        tagValue: new FormControl(null)
    });

    ricercaIndex = new FormGroup({
        indexValue: new FormControl(null),
        indexType: new FormControl(null)
    });


    constructor(private router: Router, private _api: ApiService,
                private _common: CommonService) {
    }

    ngOnInit() {
        this.maxDate = {
            year: this.now.getFullYear(),
            month: this.now.getMonth(),
            day: this.now.getDate()
        };

        this._api.ReturnKeyWordsList().subscribe(res => {
            this.returnedTags = _.filter(res.WsObjects, {KeyWordType: 'Tag'});
            this.tags = _.uniqBy(this.returnedTags, 'KeyWordValue');
        });

        this._api.ReturnIndexList().subscribe(res => {
            this.indexes = _.uniqBy(res.WsObjects, 'IndexType');
            this.enti = _.filter(res.WsObjects, {IndexType: 'Ente'});
            this.luoghi = _.filter(res.WsObjects, {IndexType: 'Luogo'});
            this.persone = _.filter(res.WsObjects, {IndexType: 'Persona'});
        });

        this._api
            .ReturnDocumentTypeList()
            .pipe(map((res: any) => res.WsObjects))
            .subscribe(
                res => {
                    this.documentTypeList = res;
                    // console.log(this.documentTypeList);
                },
                error => {
                    this._common.apiErrorAlert(error);
                    this.errorAlertMessage = true;
                }
            );


    }

    onSubmitRicercaSemplice() {

        this.router.navigate(['/ricerca/risultati'], {
            queryParams: {
                searchType: 'SS',
                TC: this.ricercaSemplice.value.elementoRicercato
            }
        })
    }


    onRicercaIndex() {
        console.log(this.ricercaIndex.value.indexValue)

        if (this.ricercaIndex.value.indexValue) {

            this.router.navigate(['/ricerca/risultati'], {
                queryParams: {
                    searchType: 'Index',
                    type: this.ricercaIndex.value.indexType,
                    value: this.ricercaIndex.value.indexValue
                }
            })
        }
    }

    optionchanged(value) {
        switch (value) {
            case 'Ente':
                this.indexType = this.enti;
                break;
            case 'Luogo':
                this.indexType = this.luoghi;
                break;
            case 'Persona':
                this.indexType = this.persone;
                break;

        }
        this.hideIndexChildren = false;
        console.log(this.indexType);
    }


    onRicercaTag() {
        console.log(this.ricercaTag.value);
        if (this.ricercaTag.value.tagValue) {

            this.router.navigate(['/ricerca/risultati'], {
                queryParams: {
                    searchType: 'Keyword',
                    type: 'Tag',
                    value: this.ricercaTag.value.tagValue
                }
            })
        }

    }


    onSubmitRicercaAvanzata() {
        console.log(this.ricercaAvanzata.value);

        let queryparams = <any>{searchType: 'AS'};

        if (this.ricercaAvanzata.value.titolo !== '') {
            queryparams.T = this.ricercaAvanzata.value.titolo;
        }
        if (this.ricercaAvanzata.value.contenuto !== '') {
            queryparams.C = this.ricercaAvanzata.value.contenuto;
        }
        if (this.ricercaAvanzata.value.dataInizio !== '' && this.ricercaAvanzata.value.dataInizio !== null) {
            if (typeof this.ricercaAvanzata.value.dataInizio === 'object') {
                if (this.ricercaAvanzata.value.dataInizio.month) {
                    console.log('ha il mese')
                }
                queryparams.DI = this.ricercaAvanzata.value.dataInizio.day + '/'
                    + this.ricercaAvanzata.value.dataInizio.month + '/' + this.ricercaAvanzata.value.dataInizio.year;
            } else {
                queryparams.DI = this.ricercaAvanzata.value.dataInizio;
            }


        }
        if (this.ricercaAvanzata.value.dataFine !== '' && this.ricercaAvanzata.value.dataFine !== null) {
            if (typeof this.ricercaAvanzata.value.dataFine === 'object') {
                if (this.ricercaAvanzata.value.dataFine.month) {
                    console.log('ha il mese')
                }
                queryparams.DF = this.ricercaAvanzata.value.dataFine.day + '/'
                    + this.ricercaAvanzata.value.dataFine.month + '/' + this.ricercaAvanzata.value.dataFine.year;
            } else {
                queryparams.DF = this.ricercaAvanzata.value.dataFine;
            }
        }

        console.log(queryparams);

         this.router.navigate(['/ricerca/risultati'], {
             queryParams: queryparams
         })
    }

    // imposto la data di partenza del secondo datepicker > di quella del primo
    setStartDate2(e) {
        this.minDate2 = e;
    }


    filterByDocumentType() {
        let docType = this.filterByDocumentTypeData.value.DocumentTypeValue;
        if (docType) {
            console.log('invio');
            this.router.navigate(['/ricerca/risultati'], {
                queryParams: { searchType: 'DocType', value: docType }
            });
        }
    }

}
