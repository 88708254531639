// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
  /*  user: 'testWS',
    psw: 'wsHeri9999',
    /!*    endpoint: 'http://heritage.srl/irenStoria',*!/
    endpoint: 'http://localhost:4200',
    cmsEndpoint: 'https://cmsirenstoria.heritage.srl:2500',
    imgEndpoint: 'https://cmsirenstoria.heritage.srl/uploads/',
    mosaikoEndpoint: 'https://mosaiko.heritage.srl/irenStoria/webservices/ws.asmx'*/
    user: 'testWS',
    psw: 'wsHeri9999',
    endpoint: 'https://irenstoria.heritage.srl',
    cmsEndpoint: 'https://cmsirenstoria.heritage.srl:2500',
    imgEndpoint: 'https://cmsirenstoria.heritage.srl/uploads/',
    mosaikoEndpoint: 'https://mosaiko.heritage.srl/irenStoria/webservices/ws.asmx'
};
