import {Timeline3dComponent} from './timeline3d.component';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {OrderModule} from 'ngx-order-pipe';
import {NgModule} from '@angular/core';


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
        LazyLoadImageModule,
        OrderModule
    ],
    declarations: [Timeline3dComponent],
    exports: [Timeline3dComponent],
    providers: []
})
export class Timeline3dModule {
}
